import React, { useState, useEffect } from "react";
import "../../../../styles/dashboards/hrdashboard/hrprofile.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  fetchHrProfileData,
  updateHrProfileData,
} from "../../../../redux/actions/hr_profile_action";
import { toast } from "react-toastify";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import editrevertIcon from "../../../../utils/dashboards/edit-revert.svg";

import {
  SetIsLoading,
  setIsEditModelOpen,
  setIsSubmitted,
} from "../../../../redux/reducers/hrProfileReducer";

function CompanyDetails() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.hrProfile);
  const reduxProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );

  const [profileCreationData, setProfileCreationData] = useState(() => ({
    company_name: reduxProfileData?.company_name || "",
    total_employee: reduxProfileData?.total_employee || "",
    revenue: reduxProfileData?.revenue || "",
    recruiter_name: reduxProfileData?.recruiter_name || "",
    recruiter_email: reduxProfileData?.recruiter_email || "",
    recruiter_contact: reduxProfileData?.recruiter_contact || "",
  }));

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileCreationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchHrProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (profileData?.getHrProfileData) {
      const profile = profileData.getHrProfileData;
      setProfileCreationData((prevData) => ({
        ...prevData,
        total_employee: profile.total_employee,
        revenue: profile.revenue,
        recruiter_name: profile.recruiter_name,
        recruiter_email: profile.recruiter_email,
        recruiter_contact: profile.recruiter_contact,
      }));
    }
  }, [profileData]);

  const handleEditClick = () => {
    dispatch(setIsEditModelOpen(!profileData.isEditModelOpen));
  };

  const handleSubmit = () => {
    const validationErrors = validateForm(profileCreationData);
    if (Object.keys(validationErrors).length === 0) {
      dispatch(updateHrProfileData(profileCreationData, dispatch));
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.total_employee)
      errors.total_employee = "Total employees are required.";
    if (!data.revenue) errors.revenue = "Revenue is required.";
    if (!data.recruiter_name)
      errors.recruiter_name = "Recruiter name is required.";
    if (!data.recruiter_contact)
      errors.recruiter_contact = "Recruiter contact is required.";
    if (!data.recruiter_email || !/\S+@\S+\.\S+/.test(data.recruiter_email)) {
      errors.recruiter_email = "Valid recruiter email is required.";
    }
    return errors;
  };

  return (
    <>
      <div className="hr_Profile_creation_container">
        {profileData.isSubmitted && (
          <>
            <div>
              <span
                onClick={handleEditClick}
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginRight: "13px",
                }}
              >
                {profileData.isEditModelOpen ? "Edit Details     " : ""}
                <img
                  src={profileData.isEditModelOpen ? editIcon : editrevertIcon}
                  alt="Edit"
                />
              </span>
            </div>
          </>
        )}
        {profileData.isEditModelOpen ? (
          <>
            <div className="hr_profile_creation_details_container">
              <div className="hr_profile_creation_details_first_section">
                <div className="details_row">
                  <label>Number of Employees</label>
                  <p>
                    {profileCreationData?.total_employee || "Not Mentioned"}
                  </p>
                </div>
                <div className="details_row">
                  <label>Revenue</label>
                  <p>{profileCreationData?.revenue || "Not Mentioned"}</p>
                </div>
                <div className="details_row">
                  <label>Recruitment Contact Person Name</label>
                  <p>
                    {profileCreationData?.recruiter_name || "Not Mentioned"}
                  </p>
                </div>
                <div className="details_row">
                  <label>Contact Number</label>
                  <p>
                    {profileCreationData?.recruiter_contact || "Not Mentioned"}
                  </p>
                </div>
              </div>
              <div className="details_gst">
                <label>Email Address</label>
                <p>{profileCreationData?.recruiter_email || "Not Mentioned"}</p>
              </div>
            </div>
          </>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="hr_profile_creation_details_container">
              <h6 className="additional_details_header">Company Details</h6>
              <div className="hr_profile_creation_details_first_section">
                <div className="details_row">
                  <label>Number of Employees</label>
                  <input
                    type="text"
                    value={profileCreationData?.total_employee || ""}
                    name="total_employee"
                    onChange={handleInputChange}
                  />
                  {errors.total_employee && (
                    <span className="hrProfile_error">
                      {errors.total_employee}
                    </span>
                  )}
                </div>
                <div className="details_row">
                  <label>Revenue</label>
                  <input
                    type="text"
                    value={profileCreationData?.revenue || ""}
                    name="revenue"
                    onChange={handleInputChange}
                  />
                  {errors.revenue && (
                    <span className="hrProfile_error">{errors.revenue}</span>
                  )}
                </div>
              </div>
              <h6 className="additional_details_header">Recruitment Details</h6>
              <div className="hr_profile_creation_details_first_section">
                <div className="details_row">
                  <label>Recruitment Contact Person Name</label>
                  <input
                    type="text"
                    value={profileCreationData?.recruiter_name || ""}
                    name="recruiter_name"
                    onChange={handleInputChange}
                  />
                  {errors.recruiter_name && (
                    <span className="hrProfile_error">
                      {errors.recruiter_name}
                    </span>
                  )}
                </div>
                <div className="details_row">
                  <label>Contact Number</label>
                  <input
                    type="text"
                    value={profileCreationData?.recruiter_contact || ""}
                    name="recruiter_contact"
                    onChange={handleInputChange}
                  />
                  {errors.recruiter_contact && (
                    <span className="hrProfile_error">
                      {errors.recruiter_contact}
                    </span>
                  )}
                </div>
                <div className="details_row">
                  <label>Recruitment Email Address</label>
                  <input
                    type="text"
                    value={profileCreationData?.recruiter_email || ""}
                    name="recruiter_email"
                    onChange={handleInputChange}
                  />
                  {errors.recruiter_email && (
                    <span className="hrProfile_error">
                      {errors.recruiter_email}
                    </span>
                  )}
                </div>
              </div>
              <div className="details_row">
                <button type="submit">
                  {profileData?.isLoading ? (
                    <div className="loader"></div>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default CompanyDetails;
