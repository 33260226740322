import { createSlice } from "@reduxjs/toolkit";

const hrProfileSlice = createSlice({
  name: "hrProfile",
  initialState: {
    getHrProfileData: [],
    isEditModelOpen: false,
    isSubmitted: false,
    profileCompletion: "",
    isLoading: false,
  },
  reducers: {
    setGetHrProfileData: (state, action) => {
      state.getHrProfileData = action.payload;
    },
    setIsEditModelOpen: (state, action) => {
      state.isEditModelOpen = action.payload;
    },
    setIsSubmitted: (state, action) => {
      state.isSubmitted = action.payload;
    },
    setProfileCompletion: (state, action) => {
      state.profileCompletion = action.payload;
    },
    SetIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setGetHrProfileData,
  setIsEditModelOpen,
  setIsSubmitted,
  setProfileCompletion,
  SetIsLoading,
} = hrProfileSlice.actions;

export default hrProfileSlice.reducer;
