// src/actions/studentProfileActions.js

import axios from "axios";
import { setGetStudentProfileData } from "../../reducers/student_profile_reducer";
import { setStudentProfilePercentageValue } from "../../reducers/student_profile_percentage";

export const fetchStudentProfileCompletion = () => async (dispatch) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/profile_completion/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const completionValue = parseInt(
      response.data.profile_completion_percentage.replace("%", "")
    );

    dispatch(setStudentProfilePercentageValue(completionValue));
  } catch (error) {
    console.error("Error fetching profile completion:", error);
  }
};