import React, { useEffect, useState } from "react";
import "../../../styles/dashboards/clgdashboard/campusmanagerprofile.css";
import addImageIcon from "../../../utils/dashboards/add-image-icon-new.png"
import { Formik, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Pie } from "react-chartjs-2";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import Instagram from "../../../utils/landingpage/images/blackinstgram.svg"
import Twitter from "../../../utils/landingpage/images/blacktwiter.svg";
import LinkedIn from "../../../utils/landingpage/images/blacklinkedin.svg";
import editIcon from "../../../utils/dashboards/edit-icon.svg";
import { setGetStudentProfileData } from '../../../redux/reducers/student_profile_reducer';
import { fetchStudentProfileData } from '../../../redux/actions/student_profile_action';
import { setIsAlreadySocialDataAvailable } from '../../../redux/reducers/student_profile_reducer';
import { setShowSocialMediaRedux } from "../../../redux/reducers/student_profile_reducer";
import { fetchStudentProfileCompletion } from '../../../redux/actions/student_profilePercentage';

function CampusManagerProfile() {
  const [activeForm, setActiveForm] = useState("campusManager");
  const [profileImage, setProfileImage] = useState(addImageIcon);
  const [myProfieData, setMyProfileData] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [collageId, setCollageId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAlreadyProfile, setIsAlreadyProfile] = useState(false);
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [isAlreadyAdminDetails, setIsAlreadyAdminDetails] = useState(false);
  const [isAdminEdit, setIsAdminEdit] = useState(false);
  const [isAlreadyPlacementDetails, setIsAlreadyPlacementDetails] = useState(false);
  const [isPlacementEdit, setIsPlacementEdit] = useState(false);
  const [isAlreadyPlacementStudentDetails, setIsAlreadyPlacementStudentDetails] = useState(false);
  const [isPlacementStudentEdit, setIsPlacementStudentEdit] = useState(false);
  const [isSocialMediaOpenModel, setIsSocialMediaOpenModel] = useState(false)
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [editingValue, setEditingValue] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  })

  const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
  const editSocialMediaDataReduxs = useSelector((state) => state.studentProfile.editSocialMediaDataRedux);
  const showSocialMediaReduxs = useSelector((state) => state.studentProfile.showSocialMediaRedux);
  const isAlreadySocialDataAvailables = useSelector((state) => state.studentProfile.isAlreadySocialDataAvailable);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const userDetails = sessionStorage.getItem('userDetails');

    const parsedDetails = JSON.parse(userDetails);
    const collegeProfileId = parsedDetails?.college_profile?.id;

    setAccessToken(token)
    setCollageId(collegeProfileId)
  }, [accessToken])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleFormSwitch = (formName) => {
    setActiveForm(formName);
  };

  const dispatch = useDispatch()

  const campusManagerValidationSchema = Yup.object().shape({
    establishedYear: Yup.number()
      .required("Established Year is required")
      .min(1800, "Year must be after 1800")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
    Type: Yup.string().required("Type is required"),
    affiliatedUniversity: Yup.string().required(
      "Affiliated University is required"
    ),
    location: Yup.string().required("location is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("state is required"),
    country: Yup.string().required("Country is required"),
    // contactNumber: Yup.string()
    //   .required("Contact Number is required")
    //   .matches(/^[0-9]+$/, "Contact Number must be digits")
    //   .min(10, "Contact Number must be at least 10 digits"),
    // emailAddress: Yup.string()
    //   .required("Email Address is required")
    //   .email("Invalid Email Address")
    //   .matches(
    //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //     "Email Address must end with a valid domain like .com, .in"
    //   ),
    websiteUrl: Yup.string().url("Invalid URL"),
  });

  const administrativeDetailsValidationSchema = Yup.object().shape({
    principalDeanName: Yup.string().required("Principal/Dean Name is required"),
    designation: Yup.string().required("Designation is required"),
    adminContactName: Yup.string().required(
      "Administrative Contact Person is required"
    ),
    admincontactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]+$/, "Contact Number must be digits")
      .min(10, "Contact Number must be at least 10 digits"),
    adminEmail: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email Address must end with a valid domain like .com, .in"
      ),
  });

  const placementCellUpValidationSchema = Yup.object().shape({
    placementOfficerName: Yup.string().required(
      "Placement Officer Name is required"
    ),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[0-9]+$/, "Contact Number must be digits only")
      .min(10, "Contact Number must be at least 10 digits")
      .max(10, "Contact Number cannot be more than 10 digits"),
    emailAddress: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email Address must end with a valid domain like .com, .in"
      ),
  });

  const placementCellDownValidationSchema = Yup.object({
    numberofStudent: Yup.string()
      .required("Number of students is required")
      .matches(/^[0-9]+$/, "Total students must be a number"),
    placementRecord: Yup.string().required("Placement record is required"),
    internshipRecord: Yup.string().required("Internship record is required"),
    male: Yup.string()
      .required("Male record is required")
      .matches(/^[0-9]+$/, "Male record must be a number"),
    female: Yup.string()
      .required("Female record is required")
      .matches(/^[0-9]+$/, "Female record must be a number"),
    others: Yup.string()
      .required("Others record is required")
      .matches(/^[0-9]+$/, "Others record must be a number"),
  }).test(
    "gender-sum-validation",
    "The sum of Male, Female, and Others must equal Total Students",
    function (values) {
      const { numberofStudent, male, female, others } = values;

      // Parse values
      const totalStudents = parseInt(numberofStudent || "0", 10);
      const maleCount = parseInt(male || "0", 10);
      const femaleCount = parseInt(female || "0", 10);
      const othersCount = parseInt(others || "0", 10);

      const totalGender = maleCount + femaleCount + othersCount;

      if (totalStudents !== totalGender) {
        return this.createError({
          path: "male", // Error will show under 'male' field
          message: "The sum of Male, Female, and Others must equal Total Students",
        });
      }

      return true; // Pass validation
    }
  );


  const campusManagerFormik = useFormik({
    initialValues: {
      collegeName: "",
      establishedYear: "",
      Type: "",
      affiliatedUniversity: "",
      location: "",
      city: "",
      state: "",
      country: "",
      contactNumber: "",
      emailAddress: "",
      websiteUrl: "",
    },
    validationSchema: campusManagerValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        college_name: values.collegeName,
        established_year: values.establishedYear,
        college_type: values.Type.toLowerCase(),
        affiliated_by: values.affiliatedUniversity,
        college_website: values.websiteUrl,
        location: values.location,
        address: values.location,
        city: values.city,
        state: values.state,
        country: values.country,
        college_contact: values.contactNumber,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile created successfully!");
          setIsAlreadyProfile(true)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const handleEditClick = () => {
    setIsAlreadyProfile(false);
    setIsProfileEdit(true)
  }

  const handleEditAdminClick = () => {
    setIsAlreadyAdminDetails(false);
    setIsAdminEdit(true);
  }

  const handleEditPlacementOfficerClick = () => {
    setIsAlreadyPlacementDetails(false);
    setIsPlacementEdit(true);
  }

  const handleEditPlacementStudentClick = () => {
    setIsAlreadyPlacementStudentDetails(false);
    setIsPlacementStudentEdit(true)
  }

  const handleEditAdminCancel = () => {
    setIsAlreadyAdminDetails(true);
    setIsAdminEdit(false);
  }

  const handleEditPlacementOfficerCancel = () => {
    setIsAlreadyPlacementDetails(true);
    setIsPlacementEdit(false);
  }

  const handleEditPlacementStudentCancel = () => {
    setIsAlreadyPlacementStudentDetails(true);
    setIsPlacementStudentEdit(false)
  }

  const administrativeEditFormik = useFormik({
    initialValues: {
      principalDeanName: myProfieData?.principal_name || "",
      designation: myProfieData?.administrative_designation || "",
      adminContactName: myProfieData?.administrative_name || "",
      admincontactNumber: myProfieData?.administrative_contact || "",
      adminEmail: myProfieData?.administrative_email || "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        principal_name: values.principalDeanName || myProfieData?.principal_name,
        administrative_name: values.adminContactName || myProfieData?.administrative_name,
        administrative_designation: values.designation || myProfieData?.administrative_designation,
        administrative_email: values.adminEmail || myProfieData?.administrative_email,
        administrative_contact: values.admincontactNumber || myProfieData?.administrative_contact,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyAdminDetails(true);
          setIsAdminEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    },
  });


  const administrativeDetailsFormik = useFormik({
    initialValues: {
      principalDeanName: "",
      designation: "",
      adminContactName: "",
      admincontactNumber: "",
      adminEmail: "",
    },
    validationSchema: administrativeDetailsValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        principal_name: values.principalDeanName,
        administrative_name: values.adminContactName,
        administrative_designation: values.designation,
        administrative_email: values.adminEmail,
        administrative_contact: values.admincontactNumber,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile created successfully!");
          setIsAlreadyAdminDetails(true)
          fetchCollegeProfile()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });


  const placementCellUpFormik = useFormik({
    initialValues: {
      placementOfficerName: "",
      contactNumber: "",
      emailAddress: "",
    },
    validationSchema: placementCellUpValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        placement_officer: values.placementOfficerName,
        officer_contact: values.contactNumber,
        officer_email: values.emailAddress
      }

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile created successfully!");
          setIsAlreadyPlacementDetails(true)
          fetchCollegeProfile()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const placementOfficerEditFormik = useFormik({
    initialValues: {
      placementOfficerName: myProfieData?.placement_officer || "",
      contactNumber: myProfieData?.officer_contact || "",
      emailAddress: myProfieData?.officer_email || "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        placement_officer: values.placementOfficerName || myProfieData?.placement_officer,
        officer_contact: values.contactNumber || myProfieData?.officer_contact,
        officer_email: values.emailAddress || myProfieData?.officer_email,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyPlacementDetails(true);
          setIsPlacementEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    },
  });

  const placementCellDownFormik = useFormik({
    initialValues: {
      numberofStudent: "",
      genderRatio: "",
      placementRecord: "",
      internshipRecord: "",
      male: "",
      female: "",
      others: "",
    },
    validationSchema: placementCellDownValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        total_student: values.numberofStudent || myProfieData?.total_student,
        gender_ratio: `male${values.male} female${values.female} others${values.others}` || myProfieData?.gender_ratio,
        placement_record: values.placementRecord || myProfieData?.placement_record,
        internship_record: values.internshipRecord || myProfieData?.internship_record,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyPlacementStudentDetails(true);
          setIsPlacementStudentEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  const parseGenderRatio = (genderRatio) => {
    const maleMatch = genderRatio?.match(/male(\d+)/);
    const femaleMatch = genderRatio?.match(/female(\d+)/);
    const othersMatch = genderRatio?.match(/others(\d+)/);

    return {
      male: maleMatch ? maleMatch[1] : "",
      female: femaleMatch ? femaleMatch[1] : "",
      others: othersMatch ? othersMatch[1] : "",
    };
  };

  const genderValues = parseGenderRatio(myProfieData?.gender_ratio);

  const placementCellDownEditFormik = useFormik({
    initialValues: {
      numberofStudent: "",
      genderRatio: "",
      placementRecord: "",
      internshipRecord: "",
      male: "",
      female: "",
      others: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        gender_ratio: `male${values.male || genderValues?.male} female${values.female || genderValues?.female} others${values.others || genderValues?.others}` || myProfieData?.gender_ratio,
        internship_record: values.internshipRecord || myProfieData?.internship_record,
        placement_record: values.placementRecord || myProfieData?.placement_record,
        total_student: values.numberofStudent || myProfieData?.total_student,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyPlacementStudentDetails(true);
          setIsPlacementStudentEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    },
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchCollegeProfile = async () => {
    const userDetails = sessionStorage.getItem('userDetails');
    if (!userDetails) {
      console.error('User details not found in sessionStorage.');
      return;
    }

    const parsedDetails = JSON.parse(userDetails);
    const accessToken = sessionStorage.getItem('accessToken');

    const collegeProfileId = parsedDetails?.college_profile?.id;

    if (!collegeProfileId) {
      console.error('College profile ID not found in userDetails.');
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collegeProfileId}/`;

    if (accessToken) {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setMyProfileData(response.data);

        if (
          response?.data?.established_year &&
          response?.data?.location &&
          response?.data?.state
        ) {
          setIsAlreadyProfile(true);
        }
        if (
          response?.data?.principal_name &&
          response?.data?.administrative_designation &&
          response?.data?.administrative_contact &&
          response?.data?.administrative_email &&
          response?.data?.administrative_name
        ) {
          setIsAlreadyAdminDetails(true);
        }
        if (response.data?.placement_officer && response.data?.officer_contact && response.data?.officer_email) {
          setIsAlreadyPlacementDetails(true)
        }
        if (response.data?.total_student && response.data?.gender_ratio && response.data?.placement_record && response.data?.internship_record) {
          setIsAlreadyPlacementStudentDetails(true)
        }
      } catch (error) {
        toast.error(error.message || 'Something Went Wrong.');
      }
    } else {
      toast.error('Access token not found.');
    }
  };

  useEffect(() => {
    fetchCollegeProfile();
  }, []);

  const handleEditSocialMedia = (link) => {
    setIsSocialMediaOpenModel(true);
    // setIsAlreadySocialData(true);
    dispatch(setIsAlreadySocialDataAvailable(true))
    setEditingValue(link)
    // setShowSocialMedia(false);
    dispatch(setShowSocialMediaRedux(false))
  }

  const handleAddSocialMedia = async (e) => {
    e.preventDefault();

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.college_profile?.id;

    if (!formData.instagram && !formData.twitter && !formData.linkedin) {
      toast.error("Please add at least one social media link");
      return;
    }

    const payload = {
      social_media_links: [
        { platform: "instagram", url: formData.instagram || '' },
        { platform: "twitter", url: formData.twitter || '' },
        { platform: "linkedin", url: formData.linkedin || '' },
      ].filter((link) => link.url)
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/all_profile/social-media-links/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Added Successfully");
        setUpdatedData(response.data)
        dispatch(setGetStudentProfileData(response.data))
        // setIsAlreadySocialData(true);
        dispatch(setIsAlreadySocialDataAvailable(true))
        setIsSocialMediaOpenModel(false);
        // setShowSocialMedia(true);
        dispatch(setShowSocialMediaRedux(true))
      }
    } catch (error) {
      console.error("Error in API:", error);
    }
  };

  const handleEditSocialMediaCancel = () => {
    dispatch(setIsAlreadySocialDataAvailable(true));
    setIsSocialMediaOpenModel(false);
    dispatch(setShowSocialMediaRedux(true))
  }


  const handleEditSocialMediaSubmit = async () => {

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const socialMediaLinks = [
      {
        platform: "instagram",
        url: formData.instagram || NewupdatedData.social_media_links?.find(link => link.platform === "instagram")?.url || "",
      },
      {
        platform: "twitter",
        url: formData.twitter || NewupdatedData.social_media_links?.find(link => link.platform === "twitter")?.url || "",
      },
      {
        platform: "linkedin",
        url: formData.linkedin || NewupdatedData.social_media_links?.find(link => link.platform === "linkedin")?.url || "",
      }
    ].filter(link => link.url);

    const payload = {
      social_media_links: socialMediaLinks
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Social media links updated successfully");
        // fetchData();
        dispatch(fetchStudentProfileData())
        setIsSocialMediaOpenModel(false);
        // setIsAlreadySocialData(true);
        dispatch(setIsAlreadySocialDataAvailable(true))
        // showSocialMedia(true);
        dispatch(setShowSocialMediaRedux(false))
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleDeleteSocialMedia = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/socialmedialinks/${id}/`,
        config
      );

      if (response.status) {
        toast.success("Deleted Successfully");
        // fetchData();
        dispatch(fetchStudentProfileData())
        // setIsAlreadySocialData(true);
        // setIsSocialMediaOpenModel(false)
      }
    } catch (error) {
      console.error("Error in API:", error);
      toast.error(error.response?.data?.message || "Failed to delete social media link");
    }
  };

  useEffect(() => {
    dispatch(fetchStudentProfileCompletion());
  }, [dispatch])

  const UserPercenatageValue = useSelector((state) => state.studentProfilePercentage.studentProfilePercentageValue)

  const remainingCompletion = 100 - (UserPercenatageValue || 0);

  const pieChartData = {
    datasets: [
      {
        data: [UserPercenatageValue || 0, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  // useEffect(() => {
  //   const userDetails = sessionStorage.getItem('userDetails');
  //   if (!userDetails) {
  //     console.error('User details not found in sessionStorage.');
  //     return;
  //   }

  //   const parsedDetails = JSON.parse(userDetails);
  //   const accessToken = sessionStorage.getItem('accessToken');

  //   const collegeProfileId = parsedDetails?.college_profile?.id;

  //   if (!collegeProfileId) {
  //     console.error('College profile ID not found in userDetails.');
  //     return;
  //   }

  //   const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collegeProfileId}/`;

  //   if (accessToken) {
  //     axios
  //       .get(
  //         apiUrl,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         setMyProfileData(response.data)
  //         if (response?.data?.established_year && response?.data?.location && response?.data?.state) {
  //           setIsAlreadyProfile(true);
  //         }
  //         if (response?.data?.principal_name && response?.data?.administrative_designation && response?.data?.administrative_contact && response?.data?.administrative_email && response?.data?.administrative_name) {
  //           setIsAlreadyAdminDetails(true)
  //         }
  //       })
  //       .catch((error) => {
  //         toast.error(error || 'Something Went Wrong.');
  //       });
  //   } else {
  //     toast.error('Something Went Wrong.');
  //   }
  // }, []);


  // Campus Manager Form
  const renderCampusManagerForm = () => (
    <div className="clg-profile-creation-left-section">
      <form onSubmit={campusManagerFormik.handleSubmit}>
        <div className="clg-profile-creation-profile-section">
          <img
            src={profileImage}
            alt="Profile"
            className="clg-profile-creation-profile-picture"
          />
          <div className="profile-section-sometext">
            <h6>Upload Your College Logo</h6>
            <p>Your photo should be in PNG or JPG format</p>
            <input
              type="file"
              id="fileInput"
              className="clg-profile-creation-edit-profile-button"
              accept="image/png, image/jpeg"
              onChange={handleImageUpload}
            />
            <label htmlFor="fileInput" className="clg-custom-file-upload">
              Choose Image
            </label>
          </div>
        </div>

        {isAlreadyProfile === false && isProfileEdit === false && (<div>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="collegeName"
                placeholder="College Name"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.collegeName || myProfieData?.college_name}
                onChange={campusManagerFormik.handleChange}
              />

              <div className="epf-input-wrapper pt-2">
                <select
                  name="Type"
                  value={campusManagerFormik.values.Type}
                  onChange={campusManagerFormik.handleChange}
                  className={`epf-input-field ${campusManagerFormik.errors.Type ? "input-error" : ""
                    }`}
                  style={{ border: '1px solid rgb(173 168 168)' }}
                >
                  <option value="">Type</option>
                  <option value="male">Public</option>
                  <option value="female">Private</option>
                </select>
              </div>
              {campusManagerFormik.touched.Type &&
                campusManagerFormik.errors.Type ? (
                <div className="errorprofile">{campusManagerFormik.errors.Type} </div>
              ) : null}
              <input
                type="text"
                name="location"
                placeholder="location"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.location}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.location &&
                campusManagerFormik.errors.location ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.location}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="state"
                placeholder="State"
                className="clg-profile-input"
                value={campusManagerFormik.values.state}
                onBlur={campusManagerFormik.handleBlur}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.state &&
                campusManagerFormik.errors.state ? (
                <div className="errorprofile">{campusManagerFormik.errors.state} </div>
              ) : null}
              <input
                type="text"
                name="contactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.contactNumber || myProfieData?.college_contact}
                onChange={campusManagerFormik.handleChange}
              />

            </div>
            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="establishedYear"
                placeholder="Established Year"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.establishedYear}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.establishedYear &&
                campusManagerFormik.errors.establishedYear ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.establishedYear}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="affiliatedUniversity"
                placeholder="Affiliated University"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.affiliatedUniversity}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.affiliatedUniversity &&
                campusManagerFormik.errors.affiliatedUniversity ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.affiliatedUniversity}
                </div>
              ) : null}
              <input
                type="text"
                name="city"
                placeholder="City"
                className="clg-profile-input"
                value={campusManagerFormik.values.city}
                onBlur={campusManagerFormik.handleBlur}
                onChange={campusManagerFormik.handleChange}
              />{" "}
              {campusManagerFormik.touched.city &&
                campusManagerFormik.errors.city ? (
                <div className="errorprofile">{campusManagerFormik.errors.city} </div>
              ) : null}
              <input
                type="text"
                name="country"
                placeholder="Country"
                className="clg-profile-input"
                value={campusManagerFormik.values.country}
                onChange={campusManagerFormik.handleChange}
                onBlur={campusManagerFormik.handleBlur}
              />{" "}
              {campusManagerFormik.touched.country &&
                campusManagerFormik.errors.country ? (
                <div className="errorprofile">{campusManagerFormik.errors.country} </div>
              ) : null}
              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="clg-profile-input"
                value={myProfieData?.college_email}
                style={{ cursor: "not-allowed" }}
              />{" "}
            </div>
          </div>
          <div className="pt-2">
            <input
              type="text"
              name="websiteUrl"
              placeholder="Website Url"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.websiteUrl}
              onChange={campusManagerFormik.handleChange}
            />{" "}
            {campusManagerFormik.touched.websiteUrl &&
              campusManagerFormik.errors.websiteUrl ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.websiteUrl}{" "}
              </div>
            ) : null}
          </div>

          {loading ?
            (<button
              className="profile-creation-save-continue-button"
            >
              <div className="spinner"></div>
            </button>) :
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
              onClick={() => Formik.handleSubmit}
            >
              Save
            </button>)}
        </div>)}

        {isAlreadyProfile === true && isProfileEdit === false && (<div style={{ position: 'relative' }}>
          <div className="epf_edit_Details_button student_epf_details_button pt-4" onClick={handleEditClick}>
            <b>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                  fill="#F38D00"
                />
              </svg>
              <span className="pl-4"> Edit Details</span>{" "}
            </b>
          </div>
          <div className="pt-4">
            <div className="submitted-data-container student_submitted_data_contianer">

              <div className="submitted-data-column">
                <p>
                  <b>Collage Name:</b> {myProfieData?.college_name || "N/A"}
                </p>
                <p>
                  <b>Affiliated by:</b> {myProfieData?.affiliated_by || "N/A"}
                </p>
                <p>
                  <b>Established Year:</b> {myProfieData?.established_year || "N/A"}
                </p>
                <p>
                  <b>Type:</b> {myProfieData?.college_type || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Location:</b> {myProfieData?.location || "N/A"}
                </p>
                <p>
                  <b>Address:</b> {myProfieData?.address || "N/A"}
                </p>
                <p>
                  <b>city:</b> {myProfieData?.city || "N/A"}
                </p>
                <p>
                  <b>state:</b>{" "}
                  {myProfieData?.state || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Email:</b> {myProfieData?.college_email || "N/A"}
                </p>
                <p>
                  <b>Contact:</b> {myProfieData?.college_contact || "N/A"}
                </p>
                <p>
                  <b>College UID:</b>{" "}
                  {`${myProfieData?.college_uid || "N/A"} LPA`}
                </p>
              </div>
            </div>
          </div>
        </div>)}

        {isProfileEdit === true && (<div>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="collegeName"
                placeholder="College Name"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.collegeName || myProfieData?.college_name}
                onChange={campusManagerFormik.handleChange}
              />

              <div className="epf-input-wrapper pt-2">
                <select
                  name="Type"
                  value={campusManagerFormik.values.Type || myProfieData?.college_type}
                  onChange={campusManagerFormik.handleChange}
                  className={`epf-input-field ${campusManagerFormik.errors.Type ? "input-error" : ""
                    }`}
                  style={{ border: '1px solid rgb(173 168 168)' }}
                >
                  <option value="">Type</option>
                  <option value="male">Public</option>
                  <option value="female">Private</option>
                </select>
              </div>

              <input
                type="text"
                name="location"
                placeholder="location"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.location || myProfieData?.location}
                onChange={campusManagerFormik.handleChange}
              />

              <input
                type="text"
                name="state"
                placeholder="State"
                className="clg-profile-input"
                value={campusManagerFormik.values.state || myProfieData?.state}
                onBlur={campusManagerFormik.handleBlur}
                onChange={campusManagerFormik.handleChange}
              />

              <input
                type="text"
                name="contactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.contactNumber || myProfieData?.college_contact}
                onChange={campusManagerFormik.handleChange}
              />

            </div>
            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="establishedYear"
                placeholder="Established Year"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.establishedYear || myProfieData?.established_year}
                onChange={campusManagerFormik.handleChange}
              />

              <input
                type="text"
                name="affiliatedUniversity"
                placeholder="Affiliated University"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.affiliatedUniversity || myProfieData?.affiliated_by}
                onChange={campusManagerFormik.handleChange}
              />

              <input
                type="text"
                name="city"
                placeholder="City"
                className="clg-profile-input"
                value={campusManagerFormik.values.city || myProfieData?.city}
                onBlur={campusManagerFormik.handleBlur}
                onChange={campusManagerFormik.handleChange}
              />{" "}


              <input
                type="text"
                name="country"
                placeholder="Country"
                className="clg-profile-input"
                value={campusManagerFormik.values.country || myProfieData?.country}
                onChange={campusManagerFormik.handleChange}
                onBlur={campusManagerFormik.handleBlur}
              />{" "}

              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="clg-profile-input"
                value={myProfieData?.college_email}
                style={{ cursor: "not-allowed" }}
              />{" "}
            </div>
          </div>
          <div className="pt-2">
            <input
              type="text"
              name="websiteUrl"
              placeholder="Website Url"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.websiteUrl || myProfieData?.college_website}
              onChange={campusManagerFormik.handleChange}
            />{" "}

          </div>

          {loading ?
            (<button
              className="profile-creation-save-continue-button"
            >
              <div className="spinner"></div>
            </button>) :
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
              onClick={() => Formik.handleSubmit}
            >
              Save
            </button>)}
        </div>)}

      </form>
    </div>
  );

  // Administrative Details Form
  const renderAdministrativeDetailsForm = () => (
    <div className="clg-profile-creation-left-section">
      {isAlreadyAdminDetails === false && isAdminEdit === false && (
        <form onSubmit={administrativeDetailsFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="principalDeanName"
                placeholder="Principal/Dean Name"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.principalDeanName}
                onChange={administrativeDetailsFormik.handleChange}
              />
              {administrativeDetailsFormik.touched.principalDeanName &&
                administrativeDetailsFormik.errors.principalDeanName ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.principalDeanName}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="designation"
                placeholder="Designation"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.designation}
                onChange={administrativeDetailsFormik.handleChange}
              />{" "}
              {administrativeDetailsFormik.touched.designation &&
                administrativeDetailsFormik.errors.designation ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.designation}{" "}
                </div>
              ) : null}
              <input
                type="email"
                name="adminEmail"
                placeholder="Email Address"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.adminEmail}
                onChange={administrativeDetailsFormik.handleChange}
              />{" "}
              {administrativeDetailsFormik.touched.adminEmail &&
                administrativeDetailsFormik.errors.adminEmail ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.adminEmail}{" "}
                </div>
              ) : null}
            </div>
            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="adminContactName"
                placeholder="Administrative Contact Person"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.adminContactName}
                onChange={administrativeDetailsFormik.handleChange}
              />{" "}
              {administrativeDetailsFormik.touched.adminContactName &&
                administrativeDetailsFormik.errors.adminContactName ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.adminContactName}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="admincontactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                value={administrativeDetailsFormik.values.admincontactNumber}
                onBlur={administrativeDetailsFormik.handleBlur}
                onChange={administrativeDetailsFormik.handleChange}
              />
              {administrativeDetailsFormik.touched.admincontactNumber &&
                administrativeDetailsFormik.errors.adminContactName ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.admincontactNumber}{" "}
                </div>
              ) : null}
            </div>
          </div>
          {loading ?
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
            >
              <div className="spinner"></div>
            </button>)
            :
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
              onClick={() => Formik.handleSubmit}
            >
              Save
            </button>)}
        </form>
      )}
      {isAdminEdit === true && (
        <form onSubmit={administrativeEditFormik.handleSubmit}>
          <b>Edit Administrative Details</b>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="principalDeanName"
                placeholder="Principal/Dean Name"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={administrativeEditFormik.values.principalDeanName || myProfieData?.principal_name}
                onChange={administrativeEditFormik.handleChange}
              />

              <input
                type="text"
                name="designation"
                placeholder="Designation"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={administrativeEditFormik.values.designation || myProfieData?.administrative_designation}
                onChange={administrativeEditFormik.handleChange}
              />

              <input
                type="email"
                name="adminEmail"
                placeholder="Email Address"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={administrativeEditFormik.values.adminEmail || myProfieData?.administrative_email}
                onChange={administrativeEditFormik.handleChange}
              />
            </div>

            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="adminContactName"
                placeholder="Administrative Contact Person"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={administrativeEditFormik.values.adminContactName || myProfieData?.administrative_name}
                onChange={administrativeEditFormik.handleChange}
              />

              <input
                type="text"
                name="admincontactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                value={administrativeEditFormik.values.admincontactNumber || myProfieData?.administrative_contact}
                onBlur={administrativeEditFormik.handleBlur}
                onChange={administrativeEditFormik.handleChange}
              />
            </div>
          </div>

          <div className="d-flex gap-4 align-items-center">
            {loading ? (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                <div className="spinner"></div>
              </button>
            ) : (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                Save
              </button>
            )}
            <button
              onClick={handleEditAdminCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>
        </form>

      )}
      {isAlreadyAdminDetails === true && isAdminEdit === false && (
        <div style={{ position: 'relative' }}>
          <div className="epf_edit_Details_button student_epf_details_button pt-4" onClick={handleEditAdminClick}>
            <b>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                  fill="#F38D00"
                />
              </svg>
              <span className="pl-4"> Edit Details</span>{" "}
            </b>
          </div>
          <div className="pt-4">
            <div className="submitted-data-container student_submitted_data_contianer">

              <div className="submitted-data-column">
                <p>
                  <b>Principal Name:</b> {myProfieData?.principal_name || "N/A"}
                </p>
                <p>
                  <b>Designation:</b> {myProfieData?.administrative_designation || "N/A"}
                </p>
                <p>
                  <b>Email Address:</b> {myProfieData?.administrative_email || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Administrative Contact Person:</b> {myProfieData?.administrative_name || "N/A"}
                </p>
                <p>
                  <b>Contact Number:</b> {myProfieData?.administrative_contact || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  // Placement Cell Details Form
  const renderPlacementCellDetailsForm = () => (
    <div style={{ width: "75%" }}>
      {/* First Form section */}
      {isAlreadyPlacementDetails === false && isPlacementEdit === false && (<div className="placement-cell-student-information-section">
        <h5> Placement Cell Detail</h5>
        <form onSubmit={placementCellUpFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="placementOfficerName"
                  placeholder="Placement Officer Name"
                  className="clg-profile-input"
                  value={placementCellUpFormik.values.placementOfficerName}
                  onChange={placementCellUpFormik.handleChange}
                  onBlur={placementCellUpFormik.handleBlur}
                />
                {placementCellUpFormik.touched.placementOfficerName &&
                  placementCellUpFormik.errors.placementOfficerName && (
                    <span className="errorprofile">
                      {placementCellUpFormik.errors.placementOfficerName}
                    </span>
                  )}
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="clg-profile-input"
                  value={placementCellUpFormik.values.contactNumber}
                  onChange={placementCellUpFormik.handleChange}
                  onBlur={placementCellUpFormik.handleBlur}
                />
                {placementCellUpFormik.touched.contactNumber &&
                  placementCellUpFormik.errors.contactNumber && (
                    <span className="errorprofile">
                      {placementCellUpFormik.errors.contactNumber}
                    </span>
                  )}
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="email"
                  name="emailAddress"
                  placeholder="Email Address"
                  className="clg-profile-input"
                  value={placementCellUpFormik.values.emailAddress}
                  onChange={placementCellUpFormik.handleChange}
                  onBlur={placementCellUpFormik.handleBlur}
                />
                {placementCellUpFormik.touched.emailAddress &&
                  placementCellUpFormik.errors.emailAddress && (
                    <span className="errorprofile">
                      {placementCellUpFormik.errors.emailAddress}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="placement-cell-profile-creation-save-button"
          >
            Save
          </button>
        </form>
      </div>)}

      {isAlreadyPlacementDetails === true && isPlacementEdit === false && (
        <div className="placement-cell-student-information-section">
          <div style={{ position: 'relative' }}>
            <div className="epf_edit_Details_button student_epf_details_button pt-4" onClick={handleEditPlacementOfficerClick}>
              <b>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                    fill="#F38D00"
                  />
                </svg>
                <span className="pl-4"> Edit Details </span>{" "}
              </b>
            </div>
            <div className="pt-4">
              <div className="submitted-data-container student_submitted_data_contianer">
                <div className="submitted-data-column">
                  <p>
                    <b>Placement Officer:</b> {myProfieData?.placement_officer || "N/A"}
                  </p>
                  <p>
                    <b>Placement Officer Contact:</b> {myProfieData?.officer_contact || "N/A"}
                  </p>
                  <p>
                    <b>Placement Email Address:</b> {myProfieData?.officer_email || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPlacementEdit === true && (<div className="placement-cell-student-information-section">
        <h5>Edit Placement Cell Detail</h5>
        <form onSubmit={placementOfficerEditFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">

              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="placementOfficerName"
                  placeholder="Placement Officer Name"
                  className="clg-profile-input"
                  value={placementOfficerEditFormik.values.placementOfficerName || myProfieData?.placement_officer}
                  onChange={placementOfficerEditFormik.handleChange}
                  onBlur={placementOfficerEditFormik.handleBlur}
                />
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="clg-profile-input"
                  value={placementOfficerEditFormik.values.contactNumber || myProfieData?.officer_contact}
                  onChange={placementOfficerEditFormik.handleChange}
                  onBlur={placementOfficerEditFormik.handleBlur}
                />
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="email"
                  name="emailAddress"
                  placeholder="Email Address"
                  className="clg-profile-input"
                  value={placementOfficerEditFormik.values.emailAddress || myProfieData?.officer_email}
                  onChange={placementOfficerEditFormik.handleChange}
                  onBlur={placementOfficerEditFormik.handleBlur}
                />
              </div>

            </div>
          </div>
          <div className="d-flex gap-4 align-items-center">
            {loading ? (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                <div className="spinner"></div>
              </button>
            ) : (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                Save
              </button>
            )}
            <button
              onClick={handleEditPlacementOfficerCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>)}

      {/* Second Form section */}
      {isAlreadyPlacementStudentDetails === false && isPlacementStudentEdit === false && (<div className="placement-cell-student-information-section">
        <h5>Student Information</h5>
        <form onSubmit={placementCellDownFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">

              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="placementRecord"
                  placeholder="Placement Record (Past year data)"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.placementRecord}
                  onChange={placementCellDownFormik.handleChange}
                />
                {placementCellDownFormik.touched.placementRecord &&
                  placementCellDownFormik.errors.placementRecord ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.placementRecord}
                  </div>
                ) : null}
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="internshipRecord"
                  placeholder="Internship Record"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.internshipRecord}
                  onChange={placementCellDownFormik.handleChange}
                />
                {placementCellDownFormik.touched.internshipRecord &&
                  placementCellDownFormik.errors.internshipRecord ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.internshipRecord}
                  </div>
                ) : null}
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="numberofStudent"
                  placeholder="Total Number of Students"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.numberofStudent}
                  onChange={placementCellDownFormik.handleChange}
                  onBlur={placementCellDownFormik.handleBlur}
                />
                {placementCellDownFormik.touched.numberofStudent &&
                  placementCellDownFormik.errors.numberofStudent ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.numberofStudent}
                  </div>
                ) : null}
              </div>

              <div>
                <p>Gender Ratio</p>
                <div className="placement-cell-inputs">
                  <div className="campus_manager_input_div">
                    <input
                      type="text"
                      name="male"
                      placeholder="Male Record"
                      className="clg-profile-input"
                      value={placementCellDownFormik.values.male}
                      onChange={placementCellDownFormik.handleChange}
                      onBlur={placementCellDownFormik.handleBlur}
                    />
                    {placementCellDownFormik.touched.male &&
                      placementCellDownFormik.errors.male ? (
                      <div className="errorprofile">
                        {placementCellDownFormik.errors.male}
                      </div>
                    ) : null}
                  </div>
                  <div className="campus_manager_input_div">
                    <input
                      type="text"
                      name="female"
                      placeholder="Female Record"
                      className="clg-profile-input"
                      value={placementCellDownFormik.values.female}
                      onChange={placementCellDownFormik.handleChange}
                      onBlur={placementCellDownFormik.handleBlur}
                    />
                    {placementCellDownFormik.touched.female &&
                      placementCellDownFormik.errors.female ? (
                      <div className="errorprofile">
                        {placementCellDownFormik.errors.female}
                      </div>
                    ) : null}
                  </div>
                  <div className="campus_manager_input_div">
                    <input
                      type="text"
                      name="others"
                      placeholder="Others Record"
                      className="clg-profile-input"
                      value={placementCellDownFormik.values.others}
                      onChange={placementCellDownFormik.handleChange}
                      onBlur={placementCellDownFormik.handleBlur}
                    />
                    {placementCellDownFormik.touched.others &&
                      placementCellDownFormik.errors.others ? (
                      <div className="errorprofile">
                        {placementCellDownFormik.errors.others}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <button
            type="submit"
            className="placement-cell-profile-creation-save-button"
          >
            Save
          </button>
        </form>
      </div>)}

      {isAlreadyPlacementStudentDetails === true && isPlacementStudentEdit === false && (
        <div className="placement-cell-student-information-section">
          <div style={{ position: 'relative' }}>
            <div className="epf_edit_Details_button student_epf_details_button pt-4" onClick={handleEditPlacementStudentClick}>
              <b>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                    fill="#F38D00"
                  />
                </svg>
                <span className="pl-4"> Edit Details </span>{" "}
              </b>
            </div>
            <div className="pt-4">
              <div className="submitted-data-container student_submitted_data_contianer">
                <div className="submitted-data-column">
                  <p>
                    <b>{`Placement Record(Past year data):`}</b> {myProfieData?.placement_record || "N/A"}
                  </p>
                  <p>
                    <b>Internship Record:</b> {myProfieData?.internship_record || "N/A"}
                  </p>
                  <p>
                    <b>Total Students:</b> {myProfieData?.total_student || "N/A"}
                  </p>
                  <p>
                    <b>Males Students:</b> {genderValues?.male || "N/A"}
                  </p>
                  <p>
                    <b>Female Students:</b> {genderValues?.female || "N/A"}
                  </p>
                  <p>
                    <b>Others:</b> {genderValues?.others || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPlacementStudentEdit === true && (<div className="placement-cell-student-information-section">
        <h5>Edit Student Information</h5>
        <form onSubmit={placementCellDownEditFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="placementRecord"
                  placeholder="Placement Record (Past year data)"
                  className="clg-profile-input"
                  value={placementCellDownEditFormik.values.placementRecord || myProfieData?.placement_record}
                  onChange={placementCellDownEditFormik.handleChange}
                />
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="internshipRecord"
                  placeholder="Internship Record"
                  className="clg-profile-input"
                  value={placementCellDownEditFormik.values.internshipRecord || myProfieData?.internship_record}
                  onChange={placementCellDownEditFormik.handleChange}
                />
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="numberofStudent"
                  placeholder="Total Number of Students"
                  className="clg-profile-input"
                  value={placementCellDownEditFormik.values.numberofStudent || myProfieData?.total_student}
                  onChange={placementCellDownEditFormik.handleChange}
                  onBlur={placementCellDownEditFormik.handleBlur}
                />
              </div>

              <div>
                <p>Gender Ratio</p>
                <div className="placement-cell-inputs">
                  <div className="campus_manager_input_div">
                    <input
                      type="text"
                      name="male"
                      placeholder="Male Record"
                      className="clg-profile-input"
                      value={placementCellDownEditFormik.values.male || genderValues?.male}
                      onChange={placementCellDownEditFormik.handleChange}
                      onBlur={placementCellDownEditFormik.handleBlur}
                    />
                  </div>

                  <div className="campus_manager_input_div">
                    <input
                      type="text"
                      name="female"
                      placeholder="Female Record"
                      className="clg-profile-input"
                      value={placementCellDownEditFormik.values.female || genderValues?.female}
                      onChange={placementCellDownEditFormik.handleChange}
                      onBlur={placementCellDownEditFormik.handleBlur}
                    />
                  </div>

                  <div className="campus_manager_input_div">
                    <input
                      type="text"
                      name="others"
                      placeholder="Others Record"
                      className="clg-profile-input"
                      value={placementCellDownEditFormik.values.others || genderValues?.others}
                      onChange={placementCellDownEditFormik.handleChange}
                      onBlur={placementCellDownEditFormik.handleBlur}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="d-flex gap-4 align-items-center">
            {loading ? (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                <div className="spinner"></div>
              </button>
            ) : (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                Save
              </button>
            )}
            <button
              onClick={handleEditPlacementStudentCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>)}

    </div>
  );

  // Shared right section in both pages
  const renderRightSection = () => (
    <div className="clg-profile-creation-right-section">
      <div className="clg-profile-creation-top-right">
        <p>Complete Your Profile</p>
        <div className="mr-4 epf-chart-wrapper">
          <div className="epf-chart-container student_campus_canvas_container">
            <Pie data={pieChartData} options={pieChartOptions} />
            <div className='student_campus_canvas_percentage_value'>{`${UserPercenatageValue}%` || "0%"}</div>
          </div>
        </div>
      </div>
      <div className="clg-profile-creation-bottom-right">
        <div className="student_campus_profile_social new_student_campus_profile_media">
          <div className="">
            <div className="social_Sites_Innerdiv">
              <div className="sites_topView pb-4">
                <p>{isSocialMediaOpenModel ? `Edit Social Media Links` : `Social Media Links`}</p>
              </div>
              <div className="sites_downview">
                <div className="sites_down_innerview">

                  {isSocialMediaOpenModel === true && isAlreadySocialDataAvailables === true && showSocialMediaReduxs === false && (<>

                    <div className="sites_icon campus_student_sites_icon">
                      <div className="images">
                        <img src={Instagram} alt="Icon" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>Instagram</span>
                        <br />
                        <input
                          type="text"
                          name="instagram"
                          value={formData.instagram || editSocialMediaDataReduxs?.instagram}
                          onChange={handleInputChange}
                          className="epf-input-field student_epf_input_fields_new"
                        />
                      </div>
                    </div>
                    <div className="sites_icon campus_student_sites_icon mt-2">
                      <div className="images">
                        <img src={Twitter} alt="Twitter" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>Twitter</span>
                        <br />
                        <input
                          type="text"
                          name="twitter"
                          value={formData.twitter || editSocialMediaDataReduxs?.twitter}
                          onChange={handleInputChange}
                          className="epf-input-field student_epf_input_fields_new"
                        />
                      </div>
                    </div>
                    <div className="sites_icon campus_student_sites_icon mt-2">
                      <div className="images">
                        <img src={LinkedIn} alt="LinkedIn" />
                      </div>
                      <div style={{ width: "100%" }}>
                        <span>LinkedIn</span>
                        <br />
                        <input
                          type="text"
                          name="linkedin"
                          value={formData.linkedin || editSocialMediaDataReduxs?.linkedin}
                          onChange={handleInputChange}
                          className="epf-input-field student_epf_input_fields_new"
                        />
                      </div>
                    </div>

                    <div className="pt-4 d-flex gap-4" style={{ paddingLeft: "2.5rem" }}>
                      <button onClick={handleEditSocialMediaSubmit} className="student_epf_submit_bttn mt-0">
                        Save
                      </button>
                      <button
                        onClick={handleEditSocialMediaCancel}
                        className="student_epf_submit_bttn mt-0"
                        style={{
                          background: "transparent",
                          border: "1px solid rgba(243, 141, 0, 1)",
                          color: "rgba(243, 141, 0, 1)",
                        }}
                      >
                        Cancel
                      </button>
                    </div>

                  </>)}

                  {(isSocialMediaOpenModel === false && isAlreadySocialDataAvailables === false) && (
                    <>
                      <div className="sites_icon campus_student_sites_icon">
                        <div className="images">
                          <img src={Instagram} alt="icon" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>Instagram</span>
                          <br />
                          <input
                            type="text"
                            name="instagram"
                            value={formData.instagram}
                            onChange={handleInputChange}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                        </div>
                      </div>
                      <div className="sites_icon campus_student_sites_icon mt-2">
                        <div className="images">
                          <img src={Twitter} alt="icon" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>Twitter</span>
                          <br />
                          <input
                            type="text"
                            name="twitter"
                            value={formData.twitter}
                            onChange={handleInputChange}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                        </div>
                      </div>
                      <div className="sites_icon campus_student_sites_icon mt-2">
                        <div className="images">
                          <img src={LinkedIn} alt="icon" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>LinkedIn</span>
                          <br />
                          <input
                            type="text"
                            name="linkedin"
                            value={formData.linkedin}
                            onChange={handleInputChange}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                        </div>
                      </div>
                      <div className="pt-4" style={{ paddingLeft: "33px" }}>
                        <button onClick={handleAddSocialMedia} className=" student_epf_submit_bttn mt-0">
                          Save
                        </button>
                      </div>
                    </>
                  )}

                  {(showSocialMediaReduxs === true && isSocialMediaOpenModel === false && isAlreadySocialDataAvailables === true) && (
                    <>
                      {NewupdatedData?.social_media_links
                        ?.filter((link) => link.url)
                        .map((link) => (
                          <div key={link.id} className="sites_icon student_sites_icon">
                            <div className="d-flex align-items-start">
                              <div className="images">
                                <img
                                  src={
                                    link.platform === "instagram"
                                      ? Instagram
                                      : link.platform === "twitter"
                                        ? Twitter
                                        : link.platform === "linkedin"
                                          ? LinkedIn
                                          : ""
                                  }
                                  alt='icon'
                                />
                              </div>
                              <div>
                                <span>{link.platform.charAt(0).toUpperCase() + link.platform.slice(1)}</span>
                                <br />
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                  {link.url}
                                </a>
                              </div>
                            </div>
                            <div style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "10px", paddingLeft: "3rem" }}>
                              <img onClick={() => handleEditSocialMedia(link)} src={editIcon} alt="Edit" style={{ width: "16px" }} />
                              <div onClick={() => handleDeleteSocialMedia(link.id)}>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                                  <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                                  <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                                  <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                                  <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                                </svg>
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="clg-profile-navigation">
          <div className="d-flex clg-profile-new-navigate">
            <button
              className={
                activeForm === "campusManager" ? "clg_active_button" : ""
              }
              onClick={() => handleFormSwitch("campusManager")}
            >
              <h5>Profile Creation</h5>
            </button>
            <button
              className={
                activeForm === "administrativeDetails"
                  ? "clg_active_button"
                  : ""
              }
              onClick={() => handleFormSwitch("administrativeDetails")}
            >
              <h5>Administrative Details</h5>
            </button>
            <button
              className={
                activeForm === "placementCell" ? "clg_active_button" : ""
              }
              onClick={() => handleFormSwitch("placementCell")}
            >
              <h5>Placement Cell Details</h5>
            </button>
          </div>
        </div>

        <div className="clg-profile-creation">
          {activeForm === "campusManager" && renderCampusManagerForm()}
          {activeForm === "administrativeDetails" &&
            renderAdministrativeDetailsForm()}
          {activeForm === "placementCell" && renderPlacementCellDetailsForm()}

          {renderRightSection()}
        </div>
      </div>
    </div>
  );
}

export default CampusManagerProfile;
