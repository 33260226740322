import { createSlice } from "@reduxjs/toolkit";

const StudentProfileSlice = createSlice({
    name: "studentProfile",
    initialState: {
        getStudentProfileData: [],
        getStudentApplicationData: [],
        isEditModelOpen: false,
        isSubmitted: false,
        isSubmittedSkill: false,
        isAlreadySocialDataAvailable: false,
        showSocialMediaRedux: false,
        editSocialMediaDataRedux: {
            instagram: "",
            twitter: "",
            linkedin: "",
        },
        myInterviewDetails: false,
    },
    reducers: {
        setGetStudentProfileData: (state, action) => {
            state.getStudentProfileData = action.payload;
        },
        setIsSubmittedCertification: (state, action) => {
            state.isEditModelOpen = action.payload;
        },
        setIsSubmitted: (state, action) => {
            state.isSubmitted = action.payload;
        },
        setIsSubmittedSkill: (state, action) => {
            state.isSubmittedSkill = action.payload;
        },
        setIsAlreadySocialDataAvailable: (state, action) => {
            state.isAlreadySocialDataAvailable = action.payload
        },
        setShowSocialMediaRedux: (state, action) => {
            state.showSocialMediaRedux = action.payload
        },
        setEditSocialMediaDataRedux: (state, action) => {
            state.editSocialMediaDataRedux = action.payload;
        },
        setMyInterviewDetails: (state, action) => {
            state.myInterviewDetails = action.payload;
        },
        setGetStudentApplicationData: (state, action) => {
            state.getStudentApplicationData = action.payload;
        }
    }
});

export const { setGetStudentProfileData, setIsEditModelOpen, setIsSubmitted, setIsSubmittedSkill, isSubmittedSkill, setIsAlreadySocialDataAvailable, isAlreadySocialDataAvailable, setShowSocialMediaRedux, showSocialMediaRedux, setEditSocialMediaDataRedux, myInterviewDetails, setMyInterviewDetails, getStudentApplicationData, setGetStudentApplicationData } = StudentProfileSlice.actions;

export default StudentProfileSlice.reducer;