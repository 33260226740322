import React, { useState } from "react";
import "../../../styles/dashboards/clgdashboard/subcription.css";
import premiumcorrect from "../../../utils/dashboards/premiumcorrect.svg";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

const plans = [
  {
    name: "Basic Plan",
    monthlyPrice: 36,
    yearlyPrice: 360,
    monthlyFeatures: [
      "50000 Visitors",
      "Create Unlimited Widgets",
      "CMS Integrations",
      "All Widget Types",
      "Integrations",
    ],
    yearlyFeatures: [
      "100000 Visitors",
      "Create Unlimited Widgets",
      "CMS Integrations",
      "All Widget Types",
      "Priority Support",
    ],
  },
  {
    name: "Standard Plan",
    monthlyPrice: 36,
    yearlyPrice: 360,
    monthlyFeatures: [
      "50000 Visitors",
      "Create Unlimited Widgets",
      "CMS Integrations",
      "All Widget Types",
      "Integrations",
    ],
    yearlyFeatures: [
      "100000 Visitors",
      "Create Unlimited Widgets",
      "CMS Integrations",
      "All Widget Types",
      "Priority Support",
    ],
  },
  {
    name: "Premium Plan",
    monthlyPrice: 36,
    yearlyPrice: 360,
    monthlyFeatures: [
      "50000 Visitors",
      "Create Unlimited Widgets",
      "CMS Integrations",
      "All Widget Types",
      "Integrations",
    ],
    yearlyFeatures: [
      "100000 Visitors",
      "Create Unlimited Widgets",
      "All Widget Types",
      "Priority Support",
      "Custom Integrations",
    ],
  },
];

export default function CampuseManagerSubscription() {
  const [isYearly, setIsYearly] = useState(false);
  const [activePlan, setActivePlan] = useState(plans[1].name);
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(plans[1]);

  const handleToggle = () => {
    setIsYearly((prev) => !prev);
  };

  const handlePlanSelect = (plan) => {
    setActivePlan(plan.name);
    setSelectedPlan(plan);
  };

  const handleGetStartedClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="premium-plan-page">
            <div className="premium-plan-header">
              <h2>Ready To Get Started?</h2>
              <p>
                14 days unlimited free trial. No contract or credit card
                required.
              </p>
              <div className="monthlyyearly">
                <p className="monthlyyearly">
                  Monthly{" "}
                  <Switch
                    {...label}
                    checked={isYearly}
                    onChange={handleToggle}
                  />
                  Yearly
                </p>
              </div>
            </div>
            <div className="plan-options">
              {plans.map((plan) => (
                <div
                  className={`plan-card ${
                    activePlan === plan.name ? "active" : ""
                  }`}
                  key={plan.name}
                  onClick={() => handlePlanSelect(plan)}
                >
                  <h4>{plan.name}</h4>
                  <h3>
                    ${isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                    <sub style={{ fontWeight: "400", fontSize: "medium" }}>
                      {isYearly ? "/year" : "/month"}
                    </sub>
                  </h3>
                  {(isYearly ? plan.yearlyFeatures : plan.monthlyFeatures).map(
                    (feature, index) => (
                      <p key={index} className="correctimg">
                        <img src={premiumcorrect} alt="Correct" />
                        {feature}
                      </p>
                    )
                  )}
                  <div className="get-started-div">
                    <button onClick={handleGetStartedClick}>Get Started</button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {showModal && (
            <div className="payment-modal">
              <div className="payment-modal-content">
                <div className="payment-modal-header">
                  {" "}
                  <h4> Plan 1 </h4>
                </div>
                <h3>Amoha Group</h3>
                <p>Total Amount</p>
                <h5>
                  $
                  {isYearly
                    ? selectedPlan.yearlyPrice
                    : selectedPlan.monthlyPrice}
                </h5>
                <button onClick={handleCloseModal}>Proceed</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
