import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import "../../../styles/dashboards/clgdashboard/campusmanagerinvitation.css";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import filterIcon from "../../../utils/dashboards/filterIcon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CampusManagerJobs() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const token = sessionStorage.getItem("accessToken");
  const [jobs, setJobs] = useState([]);
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const jobsPerPage = 6;
  const navigate = useNavigate();

  const handleAccept = async (jobID) => {
    setAccepting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/accept_invitation/`,
        { job_id: jobID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchinvitationjobs();
      toast.success("Successfully accepted the invitation!"); // Display toast on success
    } catch (error) {
      console.error("Error accepting invitation:", error);
      toast.error("Failed to accept the invitation."); // Display toast on error
    } finally {
      setAccepting(false);
    }
  };

  const handleReject = async (jobID) => {
    setRejecting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/reject_invitation/`,
        { job_id: jobID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchinvitationjobs();
      toast.success("Successfully rejected the invitation!");
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      toast.error("Failed to reject the invitation.");
    } finally {
      setRejecting(false);
    }
  };

  const fetchinvitationjobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/my_invitations/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setJobs(response.data);
      console.log("---jobs data--", response.data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchinvitationjobs();
  }, []);

  // Filter jobs based on search input
  const filteredJobs = jobs?.filter(
    (job) =>
      job.company.toLowerCase().includes(search.toLowerCase()) ||
      job.profile.toLowerCase().includes(search.toLowerCase())
  );

  // Calculate indexes for pagination
  const indexOfLastJob = page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs?.slice(indexOfFirstJob, indexOfLastJob);

  // Handle search input changes
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1); // Reset to first page on search
  };

  // Handle page changes
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle job selection
  const handleJobClick = (job) => {
    navigate(`/dashboard/sidebar-page/:roleName/job-details${job.id}`);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="all-job-header">
          <h5>All Invitations</h5>
          <div className="all-job-header-right">
            <div className="all-jobs-search-input-div">
              <img src={searchIconDash} alt="Search Icon" />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                className="all-job-search-input"
              />
            </div>
            <button className="allbutton_invitations">
              <img src={filterIcon} alt="Filter Icon" />
              All ▾
            </button>
          </div>
        </div>
        <div className="job-list">
          {currentJobs?.length === 0 ? (
            <h3 className="nojobsfound"> No Invitations Found </h3>
          ) : (
            currentJobs?.map((job, index) => (
              <div key={index} className="job-item">
                <div className="job-left">
                  <img
                    src={
                      job.company_logo_url || "https://via.placeholder.com/150"
                    }
                    alt={job.company}
                    className="company-logo"
                    onClick={() => handleJobClick(job)}
                  />
                  <div className="job-info">
                    <h6 onClick={() => handleJobClick(job)}>{job.company}</h6>
                    <p>
                      {job.recruiter_name ? job.recruiter_name : "Not Mention"}
                    </p>
                  </div>
                </div>
                <div className="job-right_invitations">
                  <button
                    onClick={() => handleAccept(job.job_id)}
                    className="accept_accept_invitations"
                    disabled={accepting || rejecting} // Disable while loading
                  >
                    {accepting ? <div className="spinner"></div> : "Accept"}
                  </button>
                  <button
                    onClick={() => handleReject(job.job_id)}
                    className="accept_reject_invitations"
                    disabled={accepting || rejecting} // Disable while loading
                  >
                    {rejecting ? <div className="spinner"></div> : "Reject"}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="pagination-container pagination_custom">
          <Pagination
            count={Math.ceil(filteredJobs?.length / jobsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            className="mt-3"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: () => <img src={leftArrowIcon} alt="prev" />,
                  next: () => <img src={rightIconArrow} alt="next" />,
                }}
                {...item}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
