import axios from "axios";
import {
  setLetralHiringJobsData,
  setLetralHiringUserData,
} from "../../reducers/letralHiringReducer";

export const fetchLetralHiringJobs = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/jobs/lateral-jobs/`,
      {
        headers: {
          Authorization: accessToken && `Bearer ${accessToken}`,
        },
      }
    );
    dispatch(setLetralHiringJobsData(response.data));
  } catch (error) {
    console.log("Failed to fetch lateral hiring jobs data:", error);
  }
};

export const fetchLoginUserDetailas = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userSessionData = sessionStorage.getItem("userDetails");

  const parsedData = JSON.parse(userSessionData);
  const userId = parsedData?.id;

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${userId}/`,
      {
        headers: {
          Authorization: accessToken && `Bearer ${accessToken}`,
        },
      }
    );
    dispatch(setLetralHiringUserData(response.data));
  } catch (error) {
    console.log("Failed to fetch User details:", error);
  }
};
