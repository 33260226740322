import React from "react";

import "../../../../styles/admin/contentmanagement.css";

function TestimonialCard() {
  return (
    <div className="client_testimonial_card_containers">
      <div></div>
    </div>
  );
}

export default TestimonialCard;
