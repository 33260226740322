import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../styles/auth/auth.css";
import logoimg from "../../utils/landingpage/images/logo_img.svg";
import starnaukri from "../../utils/auth/images/start-nuakry.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { Oval } from "react-loader-spinner";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "First Name must be at least 2 characters")
        .matches(/^[A-Za-z]+$/, "Only alphabetic characters are allowed")
        .required("Required"),
    student_last_name: Yup.string()
        .min(2, "Last Name must be at least 2 characters")
        .matches(/^[A-Za-z]+$/, "Only alphabetic characters are allowed")
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string()
        .min(5, "Password must be at least 5 characters")
        .required("Required"),
    password2: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    all_mobno: Yup.string()
        .required("Required")
        .test("valid-contact", "Invalid phone number format", (value) => {
            if (!value) return false;
            if (!value.startsWith("+")) {
                value = "+" + value;
            }
            return value.startsWith("+91")
                ? /^\+91[6-9]\d{9}$/.test(value)
                : /^\+\d{1,4}\d{10,}$/.test(value);
        }),
    message: Yup.string().required("Required"),
});

const initialValues = {
    name: "",
    student_last_name: "",
    email: "",
    password: "",
    password2: "",
    all_mobno: "",
    message: "",
};

const LetralRegister = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [otpActive, setOtpActive] = useState(false);
    const [otpValue, setOtpValue] = useState()

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true);

            if (values.all_mobno && !values.all_mobno.startsWith("+")) {
                values.all_mobno = "+" + values.all_mobno;
            }

            const formData = new FormData();

            for (const key in values) {
                if (Object.prototype.hasOwnProperty.call(values, key)) {
                    formData.append(key, values[key]);
                }
            }

            const apiURL = `${process.env.REACT_APP_BASE_URL}/api/studentregister/`;

            axios
                .post(apiURL, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    console.log("Data submitted successfully", response.data);
                    setLoading(false)
                    setOtpActive(true)
                    toast.success(response?.data?.msg || 'submitted successfully');  // Display success message
                    // resetForm();
                    // setTimeout(() => {
                    //     navigate('/login')
                    // }, 2000);
                })
                .catch((error) => {
                    console.error("Error submitting data", error);
                    setLoading(false)
                    if (error.response && error.response.data && error.response.data) {
                        toast.error(error.response?.data?.msg); // Show the specific error message

                    }
                    if (error.response && error.response.data && error.response.data?.email) {
                        toast.error(error.response?.data?.email[0]); // Show the specific error message
                    }
                    else {
                        toast.error("There was an error submitting the form"); // Show a general error message
                    }
                    resetForm();
                });
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleOTPChange = (e) => {
        setOtpValue(e.target.value)
    }

    const handleClick = async () => {
        if (otpValue === "") {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "OTP cannot be empty!",
            });
            return;
        }

        setLoading(true)

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/reg-verify-otp/`, {
                email: formik.values.email,
                otp: otpValue
            });

            setLoading(false)

            Swal.fire({
                icon: "success",
                title: "Success",
                text: response.data.msg || "OTP verified successfully!",
            });
            setTimeout(() => {
                navigate('/login')
            }, 1500);
        } catch (error) {
            setLoading(false)
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.msg || "OTP verification failed!",
            });
        }
    };

    return (
        <div className="register_user_container">
            {/* <ToastContainer /> */}
            <div className="register_main_parent">
                <div className="register_user_main">
                    <div className="register_background_first">
                        <div className="register_background_first_inner" style={{ placeItems: "center" }}>
                            <img src={logoimg} alt="Logo" />
                            <div className="register_background_heading">
                                <h1>Get Started With Start Naukri!</h1>
                                <p>To keep connected with us please enter your personal info</p>
                            </div>
                        </div>
                    </div>
                    {otpActive ? (
                        <div className="register_content_second">
                            <div className="register_content_second_container" >
                                <div className="register-mobile_logo">
                                    <img src={starnaukri} alt="start-naukri" />
                                </div>
                                <h2 className="register_heading">Enter Your OTP</h2>
                                <div className="register_header_line"></div>
                                <div className="candidate_input_group" style={{ display: "grid", placeItems: "center", flex: "0" }}>
                                    <input
                                        type="number"
                                        name="otp"
                                        placeholder="Enter OTP"
                                        onChange={handleOTPChange}
                                        value={otpValue}
                                    />
                                    <button onClick={handleClick} className="register_send_btn" style={{ marginTop: "2rem", width: "fit-content" }}>
                                        {loading ? <div className="spinner"></div> : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="register_content_second">
                            <div className="register_content_second_container">
                                <div className="register-mobile_logo">
                                    <img src={starnaukri} alt="start-naukri" />
                                </div>
                                <h2 className="register_heading">Create a new account</h2>
                                <div className="register_header_line"></div>
                                <form onSubmit={formik.handleSubmit} className="register_form_container register_form_container_new">
                                    <div className="register_form_section_candidate">
                                        <div className="candidate_input_group_container">
                                            <div className="candidate_input_group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="First Name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className="register_error_msg">{formik.errors.name}</div>
                                                )}
                                            </div>
                                            <div className="candidate_input_group">
                                                <input
                                                    type="text"
                                                    name="student_last_name"
                                                    placeholder="Last Name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.student_last_name}
                                                />
                                                {formik.touched.student_last_name && formik.errors.student_last_name && (
                                                    <div className="register_error_msg">{formik.errors.student_last_name}</div>
                                                )}
                                            </div>
                                            <div className="candidate_input_group">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="register_error_msg">{formik.errors.email}</div>
                                                )}
                                            </div>
                                            <div className="candidate_input_group">
                                                <PhoneInput
                                                    country={"in"}
                                                    name="all_mobno"
                                                    placeholder="Phone Number"
                                                    onChange={(value) => formik.setFieldValue("all_mobno", value)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.all_mobno}
                                                />
                                                {formik.touched.all_mobno && formik.errors.all_mobno && (
                                                    <div className="register_error_msg">{formik.errors.all_mobno}</div>
                                                )}
                                            </div>
                                            <div className="candidate_input_group">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                {formik.touched.password && formik.errors.password && (
                                                    <div className="register_error_msg">{formik.errors.password}</div>
                                                )}
                                            </div>
                                            <div className="candidate_input_group">
                                                <input
                                                    type="password"
                                                    name="password2"
                                                    placeholder="Confirm Password"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password2}
                                                />
                                                {formik.touched.password2 && formik.errors.password2 && (
                                                    <div className="register_error_msg">{formik.errors.password2}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-12 candidate_msg" style={{ paddingLeft: "0" }}>
                                            <textarea
                                                rows={2}
                                                name="message"
                                                placeholder="Type your message"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.message}
                                            />
                                            {formik.touched.message && formik.errors.message && (
                                                <div className="register_error_msg">{formik.errors.message}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="register_send_btn_container pt-4">
                                        <button
                                            type="submit"
                                            className="register_send_btn"
                                            disabled={formik.isSubmitting}
                                        >
                                            {loading ? <div className="spinner"></div> : "Send"}
                                        </button>
                                        <div className="pt-3">
                                            Already have an account?{" "}
                                            <Link to="/login" style={{ textDecoration: "none", color: "#F38D00" }}>
                                                Login
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LetralRegister;