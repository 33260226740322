import React, { useState, useEffect, useRef } from "react";
import Png_img_stud from "../../../utils/landingpage/images/Png_img_stud.png";
import Star_png_stud from "../../../utils/landingpage/images/Star_png_stud.png";
import Vector_logo_stud from "../../../utils/landingpage/images/Vector_logo_stud.png";
import Accenture_png_stud from "../../../utils/landingpage/images/Accenture_png_stud.png";
import Group_png_stud from "../../../utils/landingpage/images/Group_png_stud.png";
import MPHASIS_png_stud from "../../../utils/landingpage/images/MPHASIS_png_stud.png";
import Rectangle_unsave_stud from "../../../utils/landingpage/images/Rectangle_unsave_stud.png";
import Map_png_stud from "../../../utils/landingpage/images/Map_png_stud.png";
import Rectangle_save_stud from "../../../utils/landingpage/images/Rectangle_save_stud.png";
import Search_png_stud from "../../../utils/landingpage/images/Search_png_stud.png";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../styles/dashboards/clgstudent/studentsavedjobs.css";
import axios from "axios";
const data = [
  {
    id: 1,
    jobtitile: "Front-end Developer",
    companyname: "Infogrians",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: Png_img_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_unsave_stud,
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
    unsavedata: "save",
    jobdetail: "Job Details",
  },
  {
    id: 2,
    jobtitile: "Front-end Developer",
    companyname: "Delopirr",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: Accenture_png_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_save_stud,
    jobdetail: "Job Details",
    unsavedata: "Saved",
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
  },
  {
    id: 3,
    jobtitile: "Front-end Developer",
    companyname: "Mphsis Pvt.Ltd.",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: MPHASIS_png_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_unsave_stud,
    unsavedata: "save",
    jobdetail: "Job Details",
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
  },
  {
    id: 4,
    jobtitile: "Front-end Developer",
    companyname: "Mphsis Pvt.Ltd.",
    review: "4.5 | 200 Reviews",
    image: Star_png_stud,
    logo: Png_img_stud,
    dollar: Vector_logo_stud,
    explogo: Group_png_stud,
    map: Map_png_stud,
    unsave: Rectangle_save_stud,
    jobdetail: "Job Details",
    unsavedata: "Saved",
    experience: "0-1 yrs",
    sallery: "3-5 LPA",
    location: "Pune Maharastra, India ",
    duration: "30+ days Ago",
  },
];

const StudentSavedJobs = () => {
  const [experience, setExperience] = useState(0);
  const [savedJobs, setSavedJobs] = useState(data.map(() => false));
  const [studentsaveData, setStudentsaveData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const sliderRef = useRef(null);
  const progressRef = useRef(null);
  const dropdownRef = useRef(null);
  const fetchSavedJobdata = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/job/saved/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setStudentsaveData(response.data); // Set the fetched job data
      })
      .catch((error) => {
        toast.error("Something Went Wrong");
      });
  };

  useEffect(() => {
    fetchSavedJobdata();
  }, []);

  const getPostedDate = (date) => {
    const postDate = new Date(date);
    const today = new Date();
    const diffTime = Math.abs(today - postDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} days ago`;
  };

  function handleUnSave(index) {
    const accessToken = sessionStorage.getItem("accessToken");
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/job/unsave/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          job_id: index,
        },
      })
      .then((response) => {
        console.log("UnSave successful", response);
        toast.success(response.data.detail);
        fetchSavedJobdata();
      })
      .catch((error) => {
        console.log("Error occurred", error);
        toast.success("Something Getting Wrong");
      });
  }
  
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const currentItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (sliderRef.current && progressRef.current) {
      const percentage = (experience / 30) * 100;
      progressRef.current.style.width = `${percentage}%`;
    }
  }, [experience]);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="student_campus_parrent_save_data">
          {studentsaveData &&
            studentsaveData?.map((job, index) => (
              <div key={index} className="student_campus_all_jobs">
                <div className="student_campus_container_save">
                  <div style={{ display: "flex" }}>
                    <div>
                      <Link
                        to={`/dashboard/sidebar-page/student/job-details/${job.job.id}`}
                      >
                        <img
                          src={
                            job.job.company_profile?.company_logo_url ||
                            dummy_logo
                          }
                          alt="Job Logo"
                          width={50}
                          height={50}
                          className="student_campus_job_logo"
                        />
                      </Link>
                    </div>
                    <div>
                      <div>
                        <h3 className="student_campus_Frontend_developer">
                          {job.job?.title || "Not mentioned"}
                        </h3>
                      </div>
                      <div className="student_campus_company_name">
                        <p className="student_campus_job_companyname">
                          {job.job?.company}
                        </p>
                        <p className="student_campus_company_rating">
                          <img
                            src={Star_png_stud}
                            alt="Job Logo"
                            className="student_campus_star_img"
                          />{" "}
                          <span>
                            {job.job.company_profile?.company_rating || "0"} |
                            {job.job.company_profile?.company_review || "0"}{" "}
                            Reviews
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="save-button student_campus_save_button"
                    style={{
                      background: "transparent",
                      color: "#000000de",
                      fontWeight: "400",
                    }}
                  >
                    {job.job.is_already_saved ? (
                      <>
                        <img src={savedIcon} alt="saved" />
                        <span onClick={() => handleUnSave(job.job.id)}>
                          Saved
                        </span>
                      </>
                    ) : (
                      <>
                        <img src={saveIcon} alt="save" />
                        <span>Save</span>
                      </>
                    )}
                  </button>
                </div>

                <div className="student_campus_Contain_div">
                  <p className="student_campus_company_experience">
                    <img
                      src={Group_png_stud}
                      alt="Job Logo"
                      className="student_campus_job_exppro"
                    />
                    <span className="student_campus_job_containt">
                      {job.job?.min_experience || "Not mentioned"}-{" "}
                      {job.job?.max_experience} yrs{" "}
                    </span>
                  </p>
                  <p className="student_campus_company_dollar">
                    <img
                      src={Vector_logo_stud}
                      alt="Job Logo"
                      className="student_campus_job_dollar"
                    />{" "}
                    <span className="student_campus_job_containsalery">
                      {job.job?.min_salary || "Not mentioned"} -
                      {job.job?.max_salary || "Not mentioned"} LPA
                    </span>
                  </p>
                  <p className="student_campus_company_map">
                    <img
                      src={Map_png_stud}
                      alt="Job Logo"
                      className="student_campus_job_map"
                    />{" "}
                    <span className="student_campus_job_containtlocation">
                      {job.job?.job_location || "Not mentioned"}
                    </span>
                  </p>
                </div>
                <p className="student_campus_paragraf_saved">
                  {job.job.company_profile?.company_name || "Not mentioned"} is
                  looking for Junior Front End Developer to join our dynamic
                  team and embark on a rewarding career journey.
                </p>
                <div className="student_campus_job_details">
                  <p className="student_campus_job_duration">
                    {getPostedDate(job.job?.created_at || "Not mentioned")}
                  </p>
                  <p className="student_campus_detail_job">
                    <a href="#" className="student_campus_job_link">
                      {job.jobdetail}
                    </a>
                  </p>
                </div>
              </div>
            ))}

          {studentsaveData?.length > 0 && (
            <div className="pagination-controls">
              <Stack spacing={2} alignItems="center">
                <Pagination
                  className="custom-pagination"
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: () => (
                          <img src={leftArrowIcon} alt="Previous" />
                        ),
                        next: () => <img src={rightIconArrow} alt="Next" />,
                      }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </div>
          )}

          {studentsaveData?.length < 1 && (
            <div>
              <h1 style={{ textAlign: "center", fontSize: "16px" }}>
                No Saved Jobs
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentSavedJobs;