export default function HRManagerMessages() {
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          HRManagerMessages
        </div>
        s
      </div>
    </>
  );
}
