import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/dashboards/clgdashboard/campusemanagercandidate.css";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightIconArrow.svg";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function CampuseManagerCandiate() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const itemsPerPage = 9;
  const data = [
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Jane Smith",
      college: "XYZ College",
      email: "jane.smith@gmail.com",
      phone: "9876543210",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Jane Smith",
      college: "XYZ College",
      email: "jane.smith@gmail.com",
      phone: "9876543210",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Jane Smith",
      college: "XYZ College",
      email: "jane.smith@gmail.com",
      phone: "9876543210",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Jane Smith",
      college: "XYZ College",
      email: "jane.smith@gmail.com",
      phone: "9876543210",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Jane Smith",
      college: "XYZ College",
      email: "jane.smith@gmail.com",
      phone: "9876543210",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Vishal Mishra",
      college: "Prestige Institute",
      email: "youremail@gmail.com",
      phone: "7687654323",
    },
    {
      name: "John Doe",
      college: "ABC University",
      email: "john.doe@gmail.com",
      phone: "1234567890",
    },
    {
      name: "Jane Smith",
      college: "XYZ College",
      email: "jane.smith@gmail.com",
      phone: "9876543210",
    },
  ];

  const filteredData = data.filter(
    (candidate) =>
      candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.college.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      candidate.phone.includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    formik.resetForm(); // Reset form values
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      college: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      college: Yup.string().required("College is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      console.log("Form Values:", values);
      closeModal();
    },
  });

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="candidate-page">
          <div className="candidate-page-header">
            <h5>All Candidates</h5>
            <div className="candidate-header-right">
              <div className="candidate-search-input">
                <img src={searchIconDash} alt="Search Icon" />
                <input
                  type="text"
                  placeholder="Search"
                  className="candidate-search-input-field"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <button className="add-candidate-button" onClick={openModal}>
                Add Candidate
              </button>
            </div>
          </div>

          <div className="candidate-container">
            <table className="candidate-table">
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>College Name</th>
                  <th>College Email</th>
                  <th>Phone Number</th>
                  <th>Activate/Deactivate</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((candidate, index) => (
                    <tr key={index}>
                      <td>{candidate.name}</td>
                      <td>{candidate.college}</td>
                      <td>{candidate.email}</td>
                      <td>{candidate.phone}</td>
                      <td>
                        <Switch {...label} defaultChecked />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data">
                      No candidates found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="pagination-controls">
            <Stack spacing={2} alignItems="center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: () => <img src={leftArrowIcon} alt="prev" />,
                      next: () => <img src={rightIconArrow} alt="next" />,
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-form">
                <div className="form-row">
                  <div className="form-column">
                    <input
                      type="text"
                      id="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="First Name"
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="error">{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="form-column">
                    <input type="text" id="last-name" placeholder="Last Name" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <input
                      type="text"
                      id="college"
                      value={formik.values.college}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="College Name"
                    />
                    {formik.touched.college && formik.errors.college && (
                      <div className="error">{formik.errors.college}</div>
                    )}
                  </div>
                  <div className="form-column">
                    <input
                      type="email"
                      id="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Email Address"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error">{formik.errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <input
                      type="password"
                      id="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Password"
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className="error">{formik.errors.password}</div>
                    )}
                  </div>
                  <div className="form-column">
                    <input
                      type="password"
                      id="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Confirm Password"
                    />
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="error">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-column">
                    <input
                      type="text"
                      id="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Phone Number"
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="error">{formik.errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="form-actions-add-candidate">
                  <button onClick={formik.handleSubmit}>Add</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
