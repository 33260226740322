function CampusManagerMySchedule() {
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          CampusManagerMySchedule
        </div>
      </div>
    </>
  );
}

export default CampusManagerMySchedule;
