import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "../../../styles/dashboards/hrdashboard/hrhome.css";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import iim from "../../../utils/dashboards/iim.png";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CampusWiseBarChart = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("accessToken");

  const fetchCollegeData = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/jobs/applications_by_college/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const collegeData = response.data;

      const labels = collegeData.map((college) =>
        college.college_name && college.college_name.length > 9
          ? college.college_name.substring(0, 9) + "..."
          : college.college_name || ""
      );

      const data = collegeData.map(
        (college) => college.total_applied_students_percentage
      );

      setChartData({
        labels,
        datasets: [
          {
            data,
            backgroundColor: "#FBB03B",
            borderRadius: 10,
            barThickness: 15,
            maxBarThickness: 16,
          },
        ],
      });
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch college data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollegeData();
  }, []);

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        grid: { display: false },
        ticks: { display: false },
        border: { display: false },
      },
      y: {
        grid: { display: false },
        ticks: { display: false },
        border: { display: false },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    layout: {
      padding: { top: 0, bottom: 0, right: 0, left: 20 },
    },
  };

  return (
    <div className="campus_bar_char">
      <div className="campus_bar_char_container">
        <ul className="campus_bar_char_chart_counter">
          <li>00</li>
          <li>10</li>
          <li>20</li>
          <li>30</li>
          <li>40</li>
          <li>50</li>
          <li>60</li>
          <li>70</li>
          <li>80</li>
          <li>90</li>
          <li>100</li>
        </ul>
      </div>
      <div className="campus_bar_char_clg_container">
        <div className="campus_bar_char_clg_container_sub small text-muted">
          {chartData.labels.length === 1 ? (
            <div className="text-center"></div>
          ) : (
            chartData.labels.map((label, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <div
                  className="border p-2 rounded-2"
                  style={{ marginRight: "15px" }}
                >
                  <img
                    src={iim}
                    alt={label}
                    className="rounded"
                    style={{ width: "24px", height: "24px" }}
                  />
                </div>
                <span>{label}</span>
              </div>
            ))
          )}
        </div>
        {chartData.labels.length === 1 ? (
          <div className="d-flex mt-5 font-weight-bold justify-content-center align-items-center  w-100 ">
            No Campus Application Found
          </div>
        ) : (
          <div className="flex-grow-1  position-relative">
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>{error}</div>
            ) : chartData.labels.length === 0 ? (
              <div className="">No College</div>
            ) : (
              <Bar data={chartData} options={options} />
            )}
            {/* <div
            className="position-absolute top-0 start-0 end-0 bottom-0 pointer-events-none"
            style={{
              background:
                "linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px), linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px)",
            }}
          ></div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default CampusWiseBarChart;
