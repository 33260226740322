import React, { useState, useEffect } from "react";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/myApplication/myapplication.css";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";

const MyApplication = () => {
  const [isOpen, setIsOpen] = useState(null);
  const [activeFilter, setActiveFilter] = useState("InReview");
  const [currentPage, setCurrentPage] = useState(1);
  const [scheduled, setScheduled] = useState([]);
  const [applications, setApplications] = useState({
    Shortlisted: [],
    InReview: [],
    Rejected: [],
    Interviewed: [],
    Offered: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 5;

  const [userToken, setUserToken] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0);
    const SessionData = sessionStorage.getItem("accessToken");
    setUserToken(SessionData);
  }, []);

  const fetchApplications = async () => {
    if (!userToken) return;
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/student_applied_jobs/`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log("API Response:", response.data);

      setApplications((prevState) => ({
        ...prevState,
        Shortlisted: response.data.shortlisted?.applications || [],
        InReview: response.data.in_review?.applications || [],
        Rejected: response.data.discarded?.applications || [],
        Offered: response.data.offered?.applications || [],
      }));

      setLoading(false);
    } catch (error) {
      console.error("API Error:", error);
      setError("Error fetching application data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      fetchApplications();
    }
  }, [userToken]);

  const totalPages = Math.ceil(
    applications[activeFilter]?.length / itemsPerPage
  );

  const currentItems =
    applications[activeFilter]?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ) || [];

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropDown = (id) => {
    setIsOpen(isOpen === id ? null : id);
  };

  const handleWithdraw = async (jobId) => {
    const SessionData = sessionStorage.getItem("accessToken");

    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to withdraw your application?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, withdraw it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/jobs/withdraw_application/`,
            { application_ids: [jobId] },
            {
              headers: {
                Authorization: `Bearer ${SessionData}`,
              },
            }
          );
          toast.success("Application withdrawn successfully.");
          if (response) {
            fetchApplications();
          }
        } catch (error) {
          toast.error("Failed to withdraw application.");
        }
      }
    });
  };

  const handleDownloadOfferLetter = (url) => {
    if (!url) {
      console.error("No URL provided for download");
      return;
    }

    try {
      saveAs(url?.offer_letter_url, `${url?.job?.company?.slice(0, 10)}-Offer-letter.pdf`);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        const accessToken = sessionStorage.getItem("accessToken");

        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/student/schedule_interview/retrieve_interview_details/?student_of=lateral`,
          config
        );

        setScheduled(response.data?.interview_details)

        setApplications((prevState) => ({
          ...prevState,
          Interviewed: response?.data?.interview_details || [],
        }));

        console.log('----repsosne data', response?.data?.interview_details)
      } catch (error) {
        toast.error(error);
      }
    };

    fetchInterviewDetails();
  }, []);

  const formatDateTime = (dateTime) => {
    const dateObj = new Date(dateTime);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = dateObj.toLocaleDateString("en-US", options);

    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = dateObj.toLocaleTimeString("en-US", timeOptions);

    return { date, time };
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="allJobs_main_container">
      <div className="allJobs_container">
        <section className="contact_header alljobs_header savedJobs_header">
          <h2 className="d-grid align-items-center">
            <span>My Application</span>
            <span className="allJobs_savedJobs_numbers">{`(${applications[activeFilter]?.length})`}</span>
          </h2>
        </section>

        <section className="alljobs_jobs_section_head myApplication_section_head mb-4">
          <div className="application-history">
            <div className="d-flex justify-content-between align-items-center myApplication_top_header">
              <b>Recent Applications History</b>
              <div className="status-filters">

                <button
                  className={`filter ${activeFilter === "InReview"
                    ? "review_active"
                    : "filter_review"
                    }`}
                  onClick={() => {
                    setActiveFilter("InReview");
                    setCurrentPage(1);
                  }}
                >
                  In-Review ({applications.InReview?.length})
                </button>

                <button
                  className={`filter ${activeFilter === "Shortlisted"
                    ? "Shortlisted_active"
                    : "filter_shortlisted"
                    }`}
                  onClick={() => {
                    setActiveFilter("Shortlisted");
                    setCurrentPage(1);
                  }}
                >
                  Shortlisted ({applications.Shortlisted?.length})
                </button>

                <button
                  className={`filter ${activeFilter === "Rejected"
                    ? "rejected_active"
                    : "filter_rejected"
                    }`}
                  onClick={() => {
                    setActiveFilter("Rejected");
                    setCurrentPage(1);
                  }}
                >
                  Declined ({applications.Rejected?.length})
                </button>

                <button
                  className={`filter ${activeFilter === "Interviewed"
                    ? "review_active"
                    : "filter_review"
                    }`}
                  onClick={() => {
                    setActiveFilter("Interviewed");
                    setCurrentPage(1);
                  }}
                >
                  Interview ({applications.Interviewed?.length})
                </button>

                <button
                  className={`filter ${activeFilter === "Offered"
                    ? "Shortlisted_active"
                    : "filter_shortlisted"
                    }`}
                  onClick={() => {
                    setActiveFilter("Offered");
                    setCurrentPage(1);
                  }}
                >
                  Offered ({applications.Offered?.length})
                </button>

              </div>
            </div>

            <div className="applications-list">
              {currentItems.length === 0 ? (
                <p className="d-flex justify-content-center pt-4 pb-4">
                  No Application History
                </p>
              ) : (
                currentItems.map((app) => (
                  <div key={app.id}>
                    {activeFilter !== "Interviewed" && (<div className="application-item">
                      <div className="d-flex align-items-center">
                        <div className="application-company-logo">
                          <img
                            src={
                              app.job.company_profile?.company_logo_url || dummy_logo
                            }
                            alt="logo"
                          />
                        </div>
                        <div className="application-details">
                          <h3>
                            {app.job.title?.length > 20
                              ? `${app.job.title.slice(0, 20)}...`
                              : app.job.title || "N/A"}
                          </h3>
                          <p>
                            {`${app.job.company_profile?.company_name || "N/A"
                              }, ${app.job.job_location || "N/A"}`.length > 30
                              ? `${`${app.job.company_profile?.company_name || "N/A"
                                }, ${app.job.job_location || "N/A"}`.slice(
                                  0,
                                  30
                                )}...`
                              : `${app.job.company_profile?.company_name || "N/A"
                              }, ${app.job.job_location || "N/A"}`}
                          </p>
                        </div>
                      </div>
                      <div className="application-date">
                        <p>Date Applied</p>
                        <p>
                          {new Date(app.applied_at).toLocaleDateString() || "N/A"}
                        </p>
                      </div>

                      {activeFilter === "Offered" && app?.offer_letter_url && (<div style={{ display: "grid", gap: "2px" }}>
                        <p>Offer Letter</p>
                        <div className="new_letral_interview_details_join_now student_interview_details_join_now pt-0">
                          <button onClick={() => handleDownloadOfferLetter(app)} style={{ background: '#f38d00' }}>
                            Download
                          </button>
                        </div>
                      </div>)}

                      <div
                        className="d-flex justify-content-between align-items-center gap-2"
                        style={{ paddingRight: "25px", position: "relative" }}
                      >
                        {activeFilter !== "Interviewed" && (<span className={`status ${activeFilter.toLowerCase()}`}>
                          {activeFilter}
                        </span>)}
                        <button
                          onClick={() => handleDropDown(app.id)}
                          className="more-options"
                        >
                          ⋮
                        </button>
                        {isOpen === app.id && (
                          <div className="more-options-dropdown">
                            <ul>
                              {activeFilter == "InReview" ? (
                                <li>
                                  <a
                                    href=".."
                                    onClick={(event) => {
                                      event.preventDefault();
                                      handleWithdraw(app.id);
                                    }}
                                  >
                                    Withdraw
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <Link to={`/lateral/jobDetails/${app.job.id}`}>
                                  View
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>)}

                    {activeFilter === "Interviewed" &&
                      app?.interview_date && (() => {
                        const { date, time } = formatDateTime(app?.interview_date);

                        return (
                          <div className="application-item">
                            <div className="d-flex align-items-center">
                              <div className="application-company-logo">
                                <img
                                  src={
                                    app.job_details?.company_profile?.company_logo_url || dummy_logo
                                  }
                                  alt="logo"
                                />
                              </div>
                              <div className="application-details">
                                <h3>
                                  {app.job_details?.title?.length > 20
                                    ? `${app.job_details?.title?.slice(0, 20)}...`
                                    : app.job_details?.title || "N/A"}
                                </h3>
                                <p>
                                  {`${app.job_details?.company?.length > 20 ? `${app.job_details?.company?.slice(0, 20)}...`
                                    : app.job_details?.company || "N/A"
                                    }`}
                                </p>
                              </div>
                            </div>
                            <div className="application-date">
                              <p>Interview Date</p>
                              <p>
                                {date || "N/A"} {time || "N/A"}
                              </p>
                            </div>

                            <div className="application-date" style={{ textAlign: "center" }}>
                              <p>Interview Round</p>
                              <p>
                                {app.interview_round || "N/A"}
                              </p>
                            </div>

                            <div className="application-date" style={{ textAlign: "center" }}>
                              <p>Interview URL</p>
                              {app.zoom_meeting_link ? (
                                <div className="new_letral_interview_details_join_now student_interview_details_join_now pt-0">
                                  <a href={app.zoom_meeting_link || ''} target='..'>
                                    <button> Join Now </button>
                                  </a>
                                </div>
                              ) : 'Not Mentioned'}
                            </div>

                            <div
                              className="d-flex justify-content-between align-items-center gap-2"
                              style={{ paddingRight: "25px", position: "relative" }}
                            >
                              <button
                                onClick={() => handleDropDown(app.application_id)}
                                className="more-options"
                              >
                                ⋮
                              </button>
                              {isOpen === app.application_id && (
                                <div className="more-options-dropdown">
                                  <ul>
                                    <li>
                                      <Link to={`/lateral/jobDetails/${app.job_details?.id}`}>
                                        View
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })()}

                  </div>
                ))
              )}
            </div>
          </div>
        </section>

        {/* Pagination Controls */}
        <div className="pt-4 pagination-controls">
          <Stack spacing={2} alignItems="center">
            <Pagination
              className="custom-pagination"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              siblingCount={1}
              boundaryCount={1}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: () => (
                      <img
                        className="campus-next-btn-pagination"
                        src={leftArrowIcon}
                        alt="prev"
                      />
                    ),
                    next: () => (
                      <img
                        className="campus-prev-btn-pagination"
                        src={rightIconArrow}
                        alt="next"
                      />
                    ),
                  }}
                  {...item}
                />
              )}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default MyApplication;