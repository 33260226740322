import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../styles/dashboards/hrdashboard/campuseinterview.css";
import axios from "axios";
import { toast } from "react-toastify";

function CandidateOfSelectedCollege() {
  const { jobId, clgId, round } = useParams();
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([]);
  const [selectedRound, setSelectedRound] = useState(1);
  const [status, setStatus] = useState("interviewed");
  const [statusLoading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ScheduleLoading, setScheduleLoading] = useState(false);
  const [interviewDateTime, setInterviewDateTime] = useState("");
  const [roundsCount, setRoundsCount] = useState(parseInt(round));
  const token = sessionStorage.getItem("accessToken");

  const getJobRoundsData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}`
      );
      if (response.status === 200) {
        setRoundsCount(response.data.rounds);
      }
    } catch (error) {
      console.error("Error fetching job rounds data:", error);
    }
  };

  useEffect(() => {
    getJobRoundsData();
    getStudentProfileData();
    setRoundsCount(parseInt(round) || 1);
  }, [jobId, clgId, round]);

  const getStudentProfileData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/hr/jobs/colleges/applications/?college_id=${clgId}&job_id=${jobId}`
      );
      if (response.status === 200) {
        setCandidates(response.data.students);
      }
    } catch (error) {
      console.error("Error fetching student profiles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCandidateSelection = (id, isChecked) => {
    setSelectedCandidateIds((prev) =>
      isChecked
        ? [...prev, id]
        : prev.filter((candidateId) => candidateId !== id)
    );
  };

  const scheduleInterview = async () => {
    if (!interviewDateTime || new Date(interviewDateTime) <= new Date()) {
      toast.error("Please select a valid future date and time.");
      return;
    }

    if (selectedCandidateIds.length === 0) {
      toast.error("Please select at least one candidate.");
      return;
    }

    const formattedDateTime = interviewDateTime.replace("T", " ");

    try {
      setScheduleLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/student_campus_schedule_zoom_meeting/`,
        {
          application_ids: selectedCandidateIds,
          interview_date_time: formattedDateTime,
          interview_type: "virtual",
          interview_round: selectedRound + 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Interview scheduled successfully!");
        setSelectedCandidateIds([]);
        getStudentProfileData();
        setInterviewDateTime("");
        setSelectedRound(selectedRound + 1);
      }
    } catch (error) {
      console.error("Error scheduling interview:", error);
    } finally {
      setScheduleLoading(false);
    }
  };

  const handleStatusChange = async () => {
    try {
      setStatusLoading(true);
      if (selectedCandidateIds.length === 0) {
        toast.error("Please select at least one candidate.");
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        {
          application_ids: selectedCandidateIds,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Status updated successfully.");
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    } finally {
      setStatusLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="_main_content_inner_screen">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="capuse_interview_tabs-container">
          {/* Conditionally render date input and schedule button */}
          <h6>
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; Selected Candidate
          </h6>
          <div className="schedule_interview_campus_date_time_input">
            <div className="d-flex gap-1">
              {selectedCandidateIds.length !== 0 && (
                <>
                  <div>
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                      className="campus_interview_schedule_change_status_drop_down"
                    >
                      {[
                        { value: "interviewed", label: "Interviewed" },
                        { value: "discarded", label: "Declined" },
                        { value: "offered", label: "Offered" },
                      ].map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <button
                    className="campuse_std_job_applications-button"
                    onClick={handleStatusChange}
                  >
                    {statusLoading ? (
                      <div className="loader"></div>
                    ) : (
                      "Update Status"
                    )}
                  </button>
                </>
              )}
            </div>
            {selectedRound < roundsCount && (
              <input
                type="datetime-local"
                name="interview_date_time"
                id="interview_date_time"
                value={interviewDateTime}
                onChange={(e) => setInterviewDateTime(e.target.value)}
              />
            )}
          </div>

          <div className="capuse_interview_tabs">
            {[...Array(roundsCount).keys()].map((roundIndex) => (
              <button
                key={roundIndex + 1}
                className={`capuse_interview_tab-button ${
                  selectedRound === roundIndex + 1
                    ? "capuse_interview_active"
                    : ""
                }`}
                onClick={() => setSelectedRound(roundIndex + 1)}
              >
                {`Round ${roundIndex + 1}`}
              </button>
            ))}

            <button
              className={`capuse_interview_tab-button ${
                selectedRound === "Offered" ? "capuse_interview_active" : ""
              }`}
              onClick={() => setSelectedRound("Offered")}
            >
              Offered
            </button>
          </div>

          <div className="capuse_interview_tab-content">
            {selectedRound !== "Offered" ? (
              <RoundTable
                roundNumber={selectedRound}
                candidates={candidates}
                loading={loading}
                onCandidateSelect={handleCandidateSelection}
              />
            ) : (
              <OfferedTable jobId={jobId} clgId={clgId} />
            )}
          </div>

          {selectedRound < roundsCount && selectedCandidateIds.length !== 0 && (
            <button
              className="schedule_interview"
              onClick={scheduleInterview}
              disabled={loading || selectedCandidateIds.length === 0}
            >
              {ScheduleLoading ? (
                <div className="loader"></div>
              ) : (
                "Schedule Interview"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const RoundTable = ({
  roundNumber,
  candidates,
  loading,
  onCandidateSelect,
}) => {
  const navigate = useNavigate();
  const filteredCandidates = candidates.filter(
    (candidate) => candidate.interview_round === roundNumber.toString()
  );

  if (loading) {
    return <p>Loading candidates...</p>;
  }

  if (filteredCandidates.length === 0) {
    return (
      <div
        style={{
          width: "100%",
          height: "250px",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "500",
          borderRadius: "20px",
        }}
      >
        No candidates found for this round.
      </div>
    );
  }

  return (
    <div className="hr_job_listing_table">
      <table>
        <thead className="hr_job_listing_table_head">
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredCandidates.map((candidate) => (
            <tr key={candidate.id}>
              <td>
                <input
                  type="checkbox"
                  onChange={(e) =>
                    onCandidateSelect(candidate.id, e.target.checked)
                  }
                />
              </td>
              <td>
                {candidate.student_details.studentcampus_profile.first_name}{" "}
                {candidate.student_details.studentcampus_profile.last_name}
              </td>
              <td>{candidate.student_details.email}</td>
              <td>{candidate.status}</td>
              <td>
                {" "}
                <button
                  className="view_application_btn"
                  onClick={() => {
                    navigate(
                      `/dashboard/sidebar-page/hr-manager/campus/view-candidate-application/${candidate.student_details.id}/${candidate.id}`
                    );
                  }}
                >
                  See Application
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function OfferedTable({ jobId, clgId }) {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  console.log(candidates, "candidates");

  const getStudentProfileData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/hr/jobs/colleges/all_status_applications/?college_id=${clgId}&job_id=${jobId}`
      );
      if (response.status === 200) {
        // Filter candidates with "offered" status
        const offeredCandidates = response.data.students.filter(
          (candidate) => candidate.status.toLowerCase() === "offered"
        );
        setCandidates(offeredCandidates);
      }
    } catch (error) {
      console.error("Error fetching student profiles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudentProfileData();
  }, [jobId, clgId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="hr_job_listing_table">
      <table>
        <thead className="hr_job_listing_table_head">
          <tr>
            <th>Full Name</th>
            <th>Job Applied</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {candidates.length > 0 ? (
            candidates.map((candidate) => (
              <tr key={candidate.id}>
                <td>
                  {candidate.student_details.studentcampus_profile.first_name}{" "}
                  {candidate.student_details.studentcampus_profile.last_name}
                </td>
                <td>{new Date(candidate.applied_at).toLocaleDateString()}</td>
                <td>{candidate.status}</td>
                <td>
                  <button
                    className="view_application_btn"
                    onClick={() => {
                      navigate(
                        `/dashboard/sidebar-page/hr-manager/campus/view-candidate-application/${candidate.student_details.id}/${candidate.id}`
                      );
                    }}
                  >
                    See Application
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No candidates with "offered" status found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CandidateOfSelectedCollege;
