import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";

export default function ReceivedResumesChart() {
  const [jobData, setJobData] = useState([]);
  const [totalResumes, setTotalResumes] = useState(0);
  const [percentageIncrease, setPercentageIncrease] = useState(60); // Example percentage increase
  const [loading, setLoading] = useState(true);
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");

  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Static color array to assign to the job types
  const staticColors = ["#0082ED", "#F38D00", "#0DBC69", "#743BF0"];

  const fetchJobData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/api/resumes_received/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const apiData = response.data;

      // Calculate the total resumes count using all data from the API
      const totalResumes = apiData.total_count.reduce(
        (sum, job) => sum + job.count,
        0
      );
      setTotalResumes(totalResumes);

      // Sort the data by resume count and take only the top 4 jobs
      const topJobData = apiData.total_count
        .sort((a, b) => b.count - a.count)
        .slice(0, 4)
        .map((job, index) => {
          return {
            jobType: job.jobType,
            count: job.count,
            color: staticColors[index % staticColors.length], // Assign static color
          };
        });

      setJobData(topJobData);
    } catch (error) {
      console.error("Error fetching job data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  const chartOptions = {
    series: jobData.map((job) => job.count),
    options: {
      chart: {
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: -15,
          startAngle: 15,
          endAngle: 360,
          hollow: {
            margin: 10,
            size: "60%",
            background: "transparent",
          },
          track: {
            background: "#e8e8e8",
            strokeWidth: "60%",
          },
          stroke: {
            lineCap: "round",
            width: 0,
          },
          dataLabels: {
            name: {
              show: true,
              fontSize: "10px",
              formatter: function () {
                return "Number of Resume ";
              },
              color: "#272727",
            },
            value: {
              show: true,
              fontSize: "1rem",
              fontWeight: "700",
              color: "green",
              formatter: function () {
                return `+ ${percentageIncrease} %`;
              },
            },
          },
          offsetX: -30,
        },
      },
      colors: jobData.map((job) => job.color),
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      {/* Loading state */}
      {loading ? (
        <div>Loading...</div>
      ) : jobData.length > 0 ? (
        <div>
          {/* Render the radial bar chart */}
          <div id="chart">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="radialBar"
              height={280}
            />
          </div>
          {/* Total number of resumes and percentage increase */}
          {/* <div className="received_resume">
            <div
              style={{
                fontSize: "18px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {totalResumes}
            </div>
            <p style={{ fontSize: "8px", fontWeight: "normal" }}>
              Number of Resumes
            </p>
            <div
              style={{
                color: "green",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              +{percentageIncrease}%
            </div>
          </div> */}
          {/* Job Types and Counts */}
          <div className="received_resume_most_job_display">
            {jobData.map((job) => (
              <div
                key={job.jobType}
                className="received_resume_most_job_display_container"
                style={{ marginBottom: "10px" }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: job.color,
                        borderRadius: "50%",
                        marginRight: "8px",
                      }}
                    ></div>
                    <div style={{ minWidth: "140px", textAlign: "start" }}>
                      {job.jobType.length > 10
                        ? job.jobType.substring(0, 13) + "..."
                        : job.jobType}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50px",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                  >
                    {job.count}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center">No Resume Received Yet 😢</p>
      )}
    </div>
  );
}
