import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/savedJobs/savedJobs.css";
import searchIcon from "../../../utils/landingpage/images/search-icon.svg";
import allFilters from "../../../utils/landingpage/images/allFilters.svg";
import upArrow from "../../../utils/landingpage/images/uparrowIcon.svg";
import downArrow from "../../../utils/landingpage/images/downIcon.svg";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg";
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import dummy_logo from '../../../utils/landingpage/images/dummy_logo.png'
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Link } from "react-router-dom";

const SavedJobs = () => {
  const [experience, setExperience] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [save, setSave] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Most Relevance");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const sliderRef = useRef(null);
  const progressRef = useRef(null);
  const dropdownRef = useRef(null);

  // API call to fetch saved jobs
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the token directly from sessionStorage
        const accessToken = sessionStorage.getItem('accessToken');

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/job/saved/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Send the accessToken in the Authorization header
          },
        });

        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (sliderRef.current && progressRef.current) {
      const percentage = (experience / 30) * 100;
      progressRef.current.style.width = `${percentage}%`;
    }
  }, [experience]);

  const options = [
    "Recommended",
    "Relevance",
    "Date Posted",
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleSave(index) {
    const userDetails = {
      job_id: index,
    };

    const getToken = sessionStorage.getItem('accessToken');

    axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/job/save/`,
      userDetails,
      {
        headers: {
          Authorization: `Bearer ${getToken}`,
        }
      }
    )
      .then(response => {
        console.log('Save successful', response.data);
        setSave(response.data.detail)
        toast.success('Job successfully unsaved!');
      })
      .catch(error => {
        console.log('Error occurred', error);
        toast.error('Failed to unsave the job. Please try again.');
      });
  }

  function handleUnSave(index) {
    const getToken = sessionStorage.getItem('accessToken');

    axios.delete(
      `${process.env.REACT_APP_BASE_URL}/api/job/unsave/`,
      {
        headers: {
          Authorization: `Bearer ${getToken}`,
        },
        data: {
          job_id: index,
        }
      }
    )
      .then(response => {
        console.log('UnSave successful', response);
        toast.success(`UnSave successful`);

        const fetchData = async () => {
          try {
            // Get the token directly from sessionStorage
            const accessToken = sessionStorage.getItem('accessToken');

            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/job/saved/`, {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Send the accessToken in the Authorization header
              },
            });

            setData(response.data);
            setLoading(false);
          } catch (error) {
            setError("Failed to fetch data");
            setLoading(false);
          }
        };

        fetchData();
      })
      .catch(error => {
        console.log('Error occurred', error);
        // Show error toast notification
        toast.success('Something Getting Wrong');
      });
  }

  // Calculate total number of pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Get current items for pagination
  const currentItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const formatSalary = (salary) => {
    // Ensure the salary is a number and round it properly
    if (salary) {
      const floatSalary = parseFloat(salary);  // Convert string to float
      return floatSalary % 1 === 0 ? floatSalary.toFixed(0) : floatSalary.toFixed(2);
    }
    return null; // If salary is null or undefined, return null
  };

  const getPostedDate = (createdAt) => {
    return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
  };

  return (
    <div className="allJobs_main_container">
      <div className="allJobs_container">
        <section className="contact_header alljobs_header savedJobs_header">
          <h2 className="d-grid align-items-center">
            <span>Saved Jobs By You</span>
            <span className="allJobs_savedJobs_numbers">{`(${data.length})`}</span>
          </h2>
        </section>

        <section className="alljobs_jobs_section_head savedJobs_section_head">
          <div className="alljobs_content_head_main">
            <div className="alljobs_content_inner_second savedJobs_content_inner_second">
              <div>
                <div className="job-header">
                  <h2>{`${data.length} jobs found`}</h2>
                  <div className="sort-dropdown">
                  </div>
                </div>

                <div className="job-listings">
                  {currentItems && currentItems.map((jobData, index) => (jobData?.is_saved === true &&
                    <div key={index} className="job-listing">
                      <div className="company-info">
                        <Link to={`/lateral/jobDetails/${jobData.job.id}`}>
                          <img
                            src={jobData?.job?.company_profile?.company_logo_url || dummy_logo}
                            alt={` logo`}
                            className="company-logo"
                          />
                        </Link>
                        <div>
                          <Link to={`/lateral/jobDetails/${jobData.job.id}`}>
                            <h2>{jobData.job.title}</h2>
                          </Link>
                          <div className="d-flex gap-2 saved-jobs-company-review">
                            <div className="company-name">
                              {jobData.job.company || "Not Mentioned"}
                            </div>
                            <div className="rating">
                              <span className="star">★</span> {jobData?.job?.company_profile?.company_rating || '0'}
                              <span
                                style={{
                                  fontWeight: "200",
                                  color: "#3c3c3c69",
                                }}
                              >
                                |
                              </span>{" "}
                              {jobData?.job?.company_profile?.company_review || "0"} Reviews
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="job-details">
                        <span className="detail">
                          {" "}
                          <span className="details_job_icon">
                            <img src={experienceIcon} alt="..." />{" "}
                          </span>{" "}
                          {`${jobData.job.min_experience || "0"} - ${jobData.job.max_experience || "0"} yrs`}
                          <span className="stand_icon">|</span>{" "}
                        </span>
                        <span className="detail">
                          {" "}
                          <span className="details_job_icon">
                            <img src={dollerIcon} alt="..." />{" "}
                          </span>{" "}
                          {jobData?.job.min_salary || jobData?.job?.max_salary
                            ? `${formatSalary(jobData?.job.min_salary || '0')} - ${formatSalary(jobData?.job.max_salary)} LPA`
                            : 'Salary not specified'}
                          <span className='stand_icon'>|</span>
                        </span>
                        <span className="detail">
                          {" "}
                          <span className="details_job_icon">
                            <img src={locationIcon} alt="..." />{" "}
                          </span>{" "}
                          {jobData.job.job_location || 'Not Mentioned'}
                        </span>
                      </div>

                      <p className="job-description">{jobData?.job?.description.length > 170 ? `${jobData.job?.description.slice(0, 170)}...` : jobData.job?.description}</p>
                      <div className="listing-footer">
                        <span className="posted-date">
                          {jobData.job?.created_at ? getPostedDate(jobData.job?.created_at) : 'Date not available'}
                        </span>

                        {jobData?.is_saved === true && (<button
                          className="save-button"
                          onClick={() => handleUnSave(jobData?.job?.id)}
                        >
                          {" "}
                          <span className="details_job_icon">
                            <img src={savedIcon} alt="..." />
                          </span>{" "}
                          <span>Saved</span>{" "}
                        </button>)}

                        {jobData?.is_saved === false && (<button
                          className="save-button"
                          onClick={() => handleSave(jobData?.job?.id)}
                        >
                          {" "}
                          <span className="details_job_icon">
                            <img src={saveIcon} alt="..." />
                          </span>{" "}
                          <span>Save</span>{" "}
                        </button>)}

                      </div>
                    </div>
                  ))}
                </div>

                {/* Pagination Controls */}
                <div className="pagination-controls">
                  <Stack spacing={2} alignItems="center">
                    <Pagination
                      className="custom-pagination"
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: () => <img src={leftArrowIcon} alt="Previous" />,
                            next: () => <img src={rightIconArrow} alt="Next" />,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SavedJobs;



// import React, { useState, useRef, useEffect } from "react";
// import "../../../styles/allJobs/allJobs.css";
// import "../../../styles/savedJobs/savedJobs.css";
// import searchIcon from "../../../utils/landingpage/images/search-icon.svg";
// import allFilters from "../../../utils/landingpage/images/allFilters.svg";
// import upArrow from "../../../utils/landingpage/images/uparrowIcon.svg";
// import downArrow from "../../../utils/landingpage/images/downIcon.svg";
// import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
// import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg";
// import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg";
// import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
// import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
// import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
// import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
// import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
// import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import { PaginationItem } from "@mui/material";
// import { Link } from "react-router-dom";

// const SavedJobs = () => {
//   const [experience, setExperience] = useState(0);
//   const [isOpen, setIsOpen] = useState(false);
//   const [save, setSave] = useState([]);
//   const [selectedOption, setSelectedOption] = useState("Most Relevance");
//   const [data, setData] = useState([
//     {
//       jobTitle: "Front-end Devloper",
//       company: "Microsoft Pvt. Ltd.",
//       logo: { microsoftIMG },
//       rating: 4.5,
//       reviews: 200,
//       experience: "0-1 yrs",
//       salary: "3-5 Lacs PA",
//       location: "Pune, Maharashtra, India",
//       description:
//         "Microsoft Pvt. Ltd. is looking for Junior Front End Developer to join our dynamic team and embark on a rewarding career journey.",
//       save: false,
//     },
//     {
//       jobTitle: "back-end Devloper",
//       company: "Accenture Pvt. Ltd.",
//       logo: { microsoftIMG },
//       rating: 4.5,
//       reviews: 200,
//       experience: "0-1 yrs",
//       salary: "3-5 Lacs PA",
//       location: "Pune, Maharashtra, India",
//       description:
//         "Accenture Pvt. Ltd. is looking for Junior Front End Developer to join our dynamic team and embark on a rewarding career journey.",
//       save: false,
//     },
//     {
//       jobTitle: "Block-Chain Devloper",
//       company: "Systango Pvt. Ltd.",
//       logo: { microsoftIMG },
//       rating: 4.5,
//       reviews: 200,
//       experience: "0-1 yrs",
//       salary: "3-5 Lacs PA",
//       location: "Pune, Maharashtra, India",
//       description:
//         "Systango Pvt. Ltd. is looking for Junior Front End Developer to join our dynamic team and embark on a rewarding career journey.",
//       save: false,
//     },
//     {
//       jobTitle: "UI Devloper",
//       company: "Microsoft Pvt. Ltd.",
//       logo: { microsoftIMG },
//       rating: 4.5,
//       reviews: 200,
//       experience: "0-1 yrs",
//       salary: "3-5 Lacs PA",
//       location: "Pune, Maharashtra, India",
//       description:
//         "Microsoft Pvt. Ltd. is looking for Junior Front End Developer to join our dynamic team and embark on a rewarding career journey.",
//       save: false,
//     },
//   ]);

//   const sliderRef = useRef(null);
//   const progressRef = useRef(null);
//   const dropdownRef = useRef(null);


//   useEffect(() => {
//     if (sliderRef.current && progressRef.current) {
//       const percentage = (experience / 30) * 100;
//       progressRef.current.style.width = `${percentage}%`;
//     }
//   }, [experience]);

//   const options = [
//     "Most Relevance",
//     "Date Posted",
//     "Salary",
//     "Experience Level",
//   ];

//   const toggleDropdown = () => setIsOpen(!isOpen);

//   const handleOptionClick = (option) => {
//     setSelectedOption(option);
//     setIsOpen(false);
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   function handleSave(index) {
//     setData((prevData) =>
//       prevData.map((item, idx) =>
//         idx === index ? { ...item, save: !item.save } : item
//       )
//     );
//   }

//   // State for pagination
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5;

//   // Calculate the total number of pages
//   const totalPages = Math.ceil(data.length / itemsPerPage);

//   // Get the current items
//   const currentItems = data.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   // Function to handle page change
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   return (
//     <div className="allJobs_main_container">
//       <div className="allJobs_container">
//         <section className="contact_header alljobs_header savedJobs_header">
//           <h2 className="d-grid align-items-center">
//             <span>Saved Jobs By You</span>
//             <span className="allJobs_savedJobs_numbers">{`(12)`}</span>
//           </h2>
//         </section>

//         <section className="alljobs_jobs_section_head savedJobs_section_head">
//           <div className="alljobs_content_head_main">
//             <div className="alljobs_content_inner_second savedJobs_content_inner_second">
//               <div>
//                 <div className="job-header">
//                   <h2>140 Front end Developer jobs</h2>
//                   <div className="sort-dropdown" ref={dropdownRef}>
//                     <div className="dropdown-container">
//                       <button
//                         onClick={toggleDropdown}
//                         className="dropdown-toggle"
//                       >
//                         <span className="costom-pr-2">Sort by: </span>{" "}
//                         {selectedOption}
//                         <span className="dropdown-arrow">
//                           {isOpen ? (
//                             <img
//                               src={upArrow}
//                               alt="up arrow"
//                               style={{ width: "16px" }}
//                             />
//                           ) : (
//                             <img src={downArrow} alt="down arrow" />
//                           )}
//                         </span>
//                       </button>
//                       {isOpen && (
//                         <ul className="dropdown-menu">
//                           {options.map((option) => (
//                             <li
//                               key={option}
//                               onClick={() => handleOptionClick(option)}
//                             >
//                               {option}
//                             </li>
//                           ))}
//                         </ul>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="job-listings">
//                   {currentItems &&
//                     currentItems.map((job, index) => (
//                       <div key={index} className="job-listing">
//                         <div className="company-info">
//                           <Link to="/lateral/jobDetails">
//                             <img
//                               src={microsoftIMG}
//                               alt={`${job.company} logo`}
//                               className="company-logo"
//                             />
//                           </Link>
//                           <div>
//                             <Link to="/lateral/jobDetails">
//                               <h2>{job.jobTitle}</h2>
//                             </Link>
//                             <div className="d-flex gap-2 saved-jobs-company-review">
//                               <div className="company-name">{job.company}</div>
//                               <div className="rating">
//                                 <span className="star">★</span> {job.rating}{" "}
//                                 <span
//                                   style={{
//                                     fontWeight: "200",
//                                     color: "#3c3c3c69",
//                                   }}
//                                 >
//                                   |
//                                 </span>{" "}
//                                 {job.reviews} Reviews
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                         <div className="job-details">
//                           <span className="detail">
//                             {" "}
//                             <span className="details_job_icon">
//                               <img src={experienceIcon} alt="..." />{" "}
//                             </span>{" "}
//                             {job.experience}{" "}
//                             <span className="stand_icon">|</span>{" "}
//                           </span>
//                           <span className="detail">
//                             {" "}
//                             <span className="details_job_icon">
//                               <img src={dollerIcon} alt="..." />{" "}
//                             </span>{" "}
//                             {job.salary} <span className="stand_icon">|</span>{" "}
//                           </span>
//                           <span className="detail">
//                             {" "}
//                             <span className="details_job_icon">
//                               <img src={locationIcon} alt="..." />{" "}
//                             </span>{" "}
//                             {job.location}
//                           </span>
//                         </div>
//                         <p className="job-description">{job.description}</p>
//                         <div className="listing-footer">
//                           <span className="posted-date">30+ Days Ago</span>
//                           <button
//                             className="save-button"
//                             onClick={() => handleSave(index)}
//                           >
//                             {" "}
//                             <span className="details_job_icon">
//                               <img src={savedIcon} alt="..." />
//                             </span>{" "}
//                             <span>Saved</span>{" "}
//                           </button>
//                         </div>
//                       </div>
//                     ))}
//                 </div>
//                 {/* Pagination Controls */}
//                 <div className="pagination-controls">
//                   <Stack spacing={2} alignItems="center">
//                     <Pagination
//                       className="custom-pagination" // Add the custom class here
//                       count={totalPages}
//                       page={currentPage}
//                       onChange={handlePageChange}
//                       color="primary"
//                       siblingCount={1}
//                       boundaryCount={1}
//                       renderItem={(item) => (
//                         <PaginationItem
//                           components={{
//                             previous: () => (
//                               <img
//                                 className="campus-next-btn-pagination"
//                                 src={leftArrowIcon}
//                                 alt="prev"
//                               />
//                             ),
//                             next: () => (
//                               <img
//                                 className="campus-prev-btn-pagination"
//                                 src={rightIconArrow}
//                                 alt="next"
//                               />
//                             ),
//                           }}
//                           {...item}
//                         />
//                       )}
//                     />
//                   </Stack>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default SavedJobs;
