import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import "../../../styles/landing_page/landingpage.css";
import grouplogo from "../../../utils/landingpage/images/group-logo.svg";
import DblueArrow from "../../../utils/landingpage/images/D-blue-arrow.svg";
import LblueArrow from "../../../utils/landingpage/images/L-blue-arrow.svg";
import groupimg from "../../../utils/landingpage/images/group_img.svg";
import logoimg from "../../../utils/landingpage/images/logo_img.svg";
import logofacebook from "../../../utils/landingpage/images/logo_facebook.svg";
import logotwitter from "../../../utils/landingpage/images/logo_twitter.svg";
import logoyoutube from "../../../utils/landingpage/images/logo_youtube.svg";
import logoinsta from "../../../utils/landingpage/images/logo_insta.svg";

const Footer = () => {
  const [role, setRole] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    if (userDetails && userDetails.role !== undefined) {
      setRole(userDetails.role);
    } else {
      console.log('User details not found in sessionStorage');
    }
  }, []);

  console.log('--user role--', role);

  if (role === null) {
    setRole('')
  }


  return (
    <>
      <section className="footer_main">
        <div className="footer_container">
          <div className="footer_looking" >
            {(
              location.pathname === "/digital-campus-hiring" ||
              location.pathname === "/contact" ||
              location.pathname === "/about" ||
              location.pathname === "/campuses"
            ) && (<div className="footer_child">
              <motion.div
                className="footer_child1"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="d-flex align-items-center gap-4">
                  <div className="footer_image">
                    <a href="#">
                      <img src={grouplogo} alt="Group-logo" />
                    </a>
                  </div>

                  <div className="footer_txt">
                    <h6>For Job Seekers</h6>
                    <h3>
                      Find the best available jobs in
                      opportunities in India across corporates. ?
                    </h3>
                  </div>
                </div>
                <div className="footer_link">
                  <Link to='/register'>
                    Apply Now <img src={DblueArrow} alt="Arrow" />
                  </Link>
                </div>
              </motion.div>

              <motion.div
                className="footer_child2"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <div className="d-flex align-items-center gap-4">
                  <div className="footer_image">
                    <img src={groupimg} alt="Group-img" />
                  </div>
                  <div className="footer_txt">
                    <h6>For Recruiters</h6>
                    <h3>
                      Find your perfect candidate at your fingertips across  40K
                      colleges in India. ?
                    </h3>
                  </div>
                </div>
                <div className="footer_link2">
                  <Link to='/register'>
                    Post a Job <img src={LblueArrow} alt="Arrow" />
                  </Link>
                </div>
              </motion.div>
            </div>)}
          </div>

          <motion.div
            className="footer_detail"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="footer_menu">
              <div className="footer_logo">
                <img src={logoimg} alt="Group-img" />
              </div>
              <div className="footer_link_write">
                {(location.pathname === "/" || location.pathname.includes('lateral')) ? (<div className="footer_write">
                  <p>
                    In lateral hiring, we provide solutions for hiring right from <br />
                    entry-level to CXO-level hiring
                    across banking,
                    <br /> finance, IT, and non-IT industries with experienced
                    <br />
                    recruitment professionals in India.
                  </p>
                </div>) :
                  (<div className="footer_write">
                    <p>
                      StartNaukri.com is a latest technology enables 360 degree hiring solutions for Corporates which caters pan India across 1000 plus cities along with 30000 colleges/Campuses, which provides solutions for Apprenticeship Hiring, Internship Hiring, Fresher Hiring, Campus Hiring Right from IIT/IIMs to Cat A, B, C, D Colleges which enables and connect freshers to all the corporates pan India for pan India Hiring at your fingertips on your laptop with complete 100% online hiring process at highly competitive and discounted pricing compared to naukri.com and Linkedin and other job portal’s
                    </p>
                  </div>)
                }

                <div className="footer_links">
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logofacebook} alt="Group-img" />
                    </a>
                  </div>
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logotwitter} alt="Group-img" />
                    </a>
                  </div>
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logoyoutube} alt="Group-img" />
                    </a>
                  </div>
                  <div className="footer_facebook">
                    <a href="#">
                      <img src={logoinsta} alt="Group-img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer_line">
              <div className="footer_menu2">
                <h3>
                  <b>Quick link</b>
                </h3>
                <div className="footer_menu_anchers">
                  <a href="#">Solutions</a>
                  <a href="#">Campuses</a>
                  <a href="#">About</a>
                  <a href="#">Contact</a>
                </div>
              </div>
              <div className="footer_menu2">
                <h3>
                  <b>Legal</b>
                </h3>
                <div className="footer_menu_anchers">
                  <a href="#">Terms and Conditions</a>
                  <a href="#">Privicy Policy</a>
                </div>
              </div>
            </div>

            <div className="footer_menu2">
              <h3>
                <b>Services</b>
              </h3>
              <div className="footer_menu_anchers footer_menu_anchers_services">
                <a href="#">For Employee</a>
                <a href="#">For Employer</a>
                <a href="#">Forv Campuses</a>
              </div>
            </div>

            <div className="footer_menu4">
              <div>
                <h3>
                  <b>Contact</b>
                </h3>
                <span>+91 9687911144</span>
                <br />
                <span>abhayyksharma@amoha.biz</span>
                <br />
              </div>
              <div className="footer_menu5 pt-2">
                Corporate Office - 705 & 706 The Gateway, Wadhwa Atmosphere, Mulund Goregaon Link Road,
                Mumbai 400080
              </div>
            </div>
          </motion.div>

          <div className="footer_foot">
            <div className="footer_condition">
              <hr />
              <p>Copyright © 2024 Start Naukri, all rights reserved</p>
            </div>
            <br />
            <div className="footer_end"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
