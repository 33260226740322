import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fetchStudentProfileData } from "../../../../redux/actions/student_profile_action";

const StudentSkillInfo = () => {
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [isSubmittedSkill, setIsSubmittedSkill] = useState(false);
    const [isEditModelOpenSkill, setIsEditModelOpenSkill] = useState(false);
    const [updatedData, setUpdatedData] = useState([]);
    const [suggestedSkills, setSuggestedSkills] = useState([]);
    const [filteredSkills, setFilteredSkills] = useState([]);
    const isSubmittedSkills = useSelector((state) => state.studentProfile.isSubmittedSkill);

    const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        skills: "",
    });

    const suggestionsRef = useRef(null);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(fetchStudentProfileData())
    }, [dispatch])

    useEffect(() => {
        if (NewupdatedData) {
            setSelectedSkills(NewupdatedData?.skill_name)
        }
    }, [NewupdatedData])

    const handleSkillInputChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, skills: value });

        if (value) {
            const filtered = suggestedSkills
                ?.filter((skill) => skill.toLowerCase().startsWith(value.toLowerCase()))
                .filter((skill) => !selectedSkills.includes(skill));
            setFilteredSkills(filtered);
        } else {
            setFilteredSkills([]);
        }
    };

    const handleDeleteSkills = async (id) => {
        const token = sessionStorage.getItem("accessToken");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/job-skills/${id}/`, config)
            toast.success("Skill updated successfully");
            setFormData({ ...formData, skills: '' });
            dispatch(fetchStudentProfileData())
            setFilteredSkills([]);
            // fetchProfileCompletion();
        }
        catch (error) {
            toast.error(error)
        }

    }

    const handleSkillClick = async (skill) => {
        const isSkillSelected = selectedSkills.includes(skill);
        const updatedSkills = isSkillSelected
            ? selectedSkills.filter((s) => s !== skill)
            : [...selectedSkills, skill];

        setSelectedSkills(updatedSkills);

        setFormData({ ...formData, skills: '' });

        const payload = {
            skill_name: skill,
        };

        const token = sessionStorage.getItem("accessToken");

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/job-skills/`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Skill updated successfully");
            setFormData({ ...formData, skills: '' });
            dispatch(fetchStudentProfileData())
            setFilteredSkills([]);
            // fetchProfileCompletion();

        } catch (error) {
            toast.error("Error updating skill:", error);
        }
    };

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken");

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/api/job-skills/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                const skills = response.data.map((skill) => skill.skill_name);

                const filteredSkills = skills.filter((skill) => !selectedSkills.includes(skill));
                setSuggestedSkills(filteredSkills);
            })
            .catch((error) => {
                console.error("Error fetching skills:", error);
            });
    }, [selectedSkills]);

    return (<>
        <div className="pt-4 pb-4">
            <div
                className=""
            >
                <div className="qualification-form-container">
                    <div className="epf_form_name_heading">
                        <h2 className="epf-form-heading">
                            Skills Details
                        </h2>
                        <hr />
                    </div>

                    <div className="epf-skill-form">
                        <form>
                            <div className="epf-input-wrapper" style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    id="skills"
                                    name="skills"
                                    placeholder="Add your skills"
                                    value={formData.skills}
                                    onChange={handleSkillInputChange}
                                    className="epf-input-field"
                                />

                                {filteredSkills?.length > 0 && (
                                    <ul className="suggestions-list student_suggestions_list" ref={suggestionsRef}>
                                        {filteredSkills?.filter(skill => !selectedSkills.some(s => s.skill_name === skill))?.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSkillClick(suggestion)}>
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div className="epf-suggested-skills">
                                <div className="epf-skill-options">

                                    {suggestedSkills &&
                                        suggestedSkills
                                            .filter(skill => !selectedSkills.some(s => s.skill_name === skill))
                                            .slice(0, 10)
                                            .map((skill) => {
                                                return (
                                                    <button
                                                        type="button"
                                                        key={skill}
                                                        className="epf-skill-button"
                                                        onClick={() => handleSkillClick(skill)}
                                                    >
                                                        {skill}
                                                    </button>
                                                );
                                            })
                                    }

                                    {selectedSkills.length > 0 && (
                                        selectedSkills.map((skill, index) => (
                                            <button
                                                type="button"
                                                key={skill || index}
                                                className="epf-skill-button selected"
                                                onClick={() => handleDeleteSkills(skill.id)}
                                            >
                                                {skill.skill_name}
                                            </button>
                                        ))
                                    )}

                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </>)
};

export default StudentSkillInfo;