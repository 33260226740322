import React, { useState, useEffect } from "react";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import jobsharingw from "../../../utils/dashboards/jobsharingw.svg";
import jobsharingm from "../../../utils/dashboards/jobsharingm.svg";
import jobsharinge from "../../../utils/dashboards/jobsharinge.svg";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const jobs = [
  {
    logo: "https://i.postimg.cc/FKK599RW/pngimg-com-microsoft-PNG13-1.png",
    company: "Microsoft Pvt. Ltd.",
    profile: "Frontend Developer",
    studentsEnrolled: 40,
  },
];

const CampusJobDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [jobId, setJobId] = useState(null);
  const [isToken, setIsToken] = useState(false);
  const [token, setToken] = useState(null);
  const [isLogin, setIsLogin] = useState(null);
  const [jobDetails, setDetails] = useState([]);
  const [similarJobData, setSimilarJobData] = useState();
  const [profileCompletion, setProfileCompletion] = useState('90');
  const [loading, setLoading] = useState(false);

  const [skills, setSkills] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSend = () => {
    console.log("Email:", email);
    console.log("URL:", url);
    setEmail(""); 
    setUrl("");
    closeModal(); 
  };

  const job = jobs[0];

  useEffect(() => {
    setJobId(id);

    const newSession = sessionStorage.getItem("accessToken");
    setToken(newSession);
  }, [id]);

  useEffect(() => {
    if (jobId || token) {
      // Check if jobId is available
      const userToken = token;

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/campus-jobs/${jobId}/`,

          {
            headers: {
              Authorization: userToken && `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          console.log("Job details fetched successfully", response.data);
          setDetails(response.data);
          setSkills(response.data.job_skills);
        })
        .catch((error) => {
          console.log("Error occurred", error);
        });
    } else {
      console.log("Job ID is not available, skipping API call.");
    }
  }, [jobId, token]);

  const handleApplyJob = async (jobId) => {

    setLoading(true)

    if (profileCompletion < 90) {
      Swal.fire({
        title: 'Profile Incomplete',
        text: 'Complete your profile up to 90% before applying.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Complete Now',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/dashboard/sidebar-page/student/student-profile');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.close();
        }
      });
      return;
    }

    if (jobId) {
      try {
        const accessToken = sessionStorage.getItem('accessToken');

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        setLoading(false)
        console.log('Application successful:', response.data);
        toast.success('Application submitted successfully!');
      } catch (error) {
        setLoading(false)
        const errorMessage = error.response
          ? error.response.data.detail || 'Application failed. Please try again.'
          : error.message;

        console.error('Error applying for job:', errorMessage);
        toast.error(errorMessage);
      }
    } else {
      toast.info('Job ID is missing.');
      setLoading(false)
    }
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_container">
          <div className="job-detail-page">
            <div className="job-detail-header">
              <button
                className="job-detail-back-button"
                onClick={() => window.history.back()}
              >
                <img src={leftArrowIcon} alt="Back" />
              </button>
              <h5>Job Details</h5>
             {!location.pathname.includes('student/job-details') && (<div className="job-detail-Download-Pdf">
                <button>Download Pdf</button>
              </div>)}
            </div>
            <div className="job-detail-summary">
              <div className="job-left">
                <img
                  src={jobDetails.company_profile?.company_logo_url || dummy_logo}
                  alt='logo'
                  className="company-logo"
                  style={{ marginRight: "13px" }}
                />
                <div className="job-info">
                  <h6>{jobDetails.company_name || 'Not Mentioned'}</h6>
                  <p>{jobDetails.title || 'Not Mentioned'}</p>
                </div>
              </div>
              <div className="job-detail-right">
                <span>
                  <h6>Posted date</h6>31-07-2024
                </span>
              </div>
              {location.pathname.includes('student/job-details') && (<div>
                <div onClick={() => handleApplyJob(jobDetails.id)} className="job-detail-Download-Pdf">
                  <button> {loading ? <div className="spinner"></div> : "Apply Job"} </button>
                </div>
              </div>
              )}
            </div>
            <div className="job-detail-description">
              <h5>Job Description</h5>
              <p>{jobDetails.description || "N/A"}</p>
              <h6>
                Role: <p>{jobDetails.title || "N/A"}</p>
              </h6>
              <h6>
                Industry Type: <p>{jobDetails.job_category || "N/A"}</p>
              </h6>
              <h6>
                Employment Type: <p>{jobDetails.type || "N/A"}</p>
              </h6>
              <h6>
                Education: <p>{jobDetails.qualification || "N/A"}</p>
              </h6>
              <h6>
                Key Skills:
                {skills.map((skill) => (
                  <p key={skill.id}>{skill.skill_name || "N/A"},</p>
                ))}
              </h6>
            </div>
            <div className="job-detail-company">
              <h5>About Company</h5>
              <p>
                {jobDetails.company_name || "N/A"}, our mission is to empower
                every person and every organization on the planet to achieve
                more. Our mission is grounded in both the world in which we live
                and the future we strive to create.
              </p>
            </div>
            {!location.pathname.includes('student/job-details') && (<div className="job-detail-share-button">
              <button onClick={openModal}>Share Job</button>
            </div>)}
          </div>
          {isModalOpen && (
            <div className="job-share-modal-overlay">
              <div className="job-share-modal-content">
                <h5>Job Sharing</h5>
                <div className="job-sharing-icon">
                  <img src={jobsharingw} alt="sharing" />
                  <img src={jobsharingm} alt="sharing" />
                  <img src={jobsharinge} alt="sharing" />
                </div>
                <p>Share Job Opportunity</p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Job URL"
                />
                <div>
                  <button onClick={handleSend}>Send Email</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CampusJobDetails;