import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/dashboards/clgstudent/studenthome.css";
import DonutChart from "./studentDonutChart";
import { useDispatch, useSelector } from "react-redux";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import inReview from "../../../utils/dashboards/inReviewIcon.svg";
import rejectedIcon from "../../../utils/dashboards/rejectedIcon.svg";
import shortListed from "../../../utils/dashboards/shortlistedIcon.svg";
import Microsoft from "../../../utils/landingpage/images/microsoft-logo.svg";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import { fetchStudentApplicationData } from "../../../redux/actions/student_profile_action";
import { formatDistanceToNow, set } from "date-fns";
import { toast } from "react-toastify";
import axios from "axios";

function StudentHome() {
  const [isOpen, setIsOpen] = useState(null);
  const [experience, setExperience] = useState(0);
  const [isOpenNew, setIsOpenNew] = useState(null);
  const [withdraw, setWithdraw] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Shortlisted");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [id, setId] = useState(null);

  const dropdownRef = useRef(null);
  const studentApplicationData = useSelector((state) => state.studentProfile.getStudentApplicationData)
  const dispatch = useDispatch()

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropDown = (id) => {
    setIsOpen(isOpen === id ? null : id);
  };

  function handlewithdraw(id) {
    const userDetails = {
      // application_ids":[113]
      //  job_id: index,
      application_ids: [id],
    };
    const accessToken = sessionStorage.getItem("accessToken");

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/withdraw_application/`,
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          //  body: JSON.stringify({
          //    application_ids: [113],
          //  }),
        }
      )
      .then((response) => {
        console.log("Save successful", response.data.detail);
        setWithdraw(response.data.detail); // Set the saved message if needed
        toast.success(response.data.detail);

        // Use the API response message in Toastify
        // fetchStudentApplication();
        dispatch(fetchStudentApplicationData())
        //  fetchStudentAlljobs();
      })
      .catch((error) => {
        console.log("Error occurred", error);
        toast.error("Failed to save the job. Please try again.");
      });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(null); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getPostedDate = (createdAt) => {
    return formatDistanceToNow(new Date(createdAt), { addSuffix: true });
  };

  const recentJobs = studentApplicationData?.all_jobs?.applications

  const popularJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
  ];

  const size = 300;
  const centerX = size / 2;
  const centerY = size / 2;
  const radius = size / 2;
  const donutWidth = radius * 0.3;

  const [applications, setApplications] = useState({});

  useEffect(() => {
    setApplications({
      Shortlisted: studentApplicationData?.shortlisted?.applications,
      "In-Review": studentApplicationData?.in_review?.applications,
      Rejected: studentApplicationData?.interviewed?.applications,
    })
  }, [studentApplicationData])

  const currentItems = applications[activeFilter]?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    dispatch(fetchStudentApplicationData())
  }, [dispatch]);

  const formatDateTime = (dateTime) => {
    const dateObj = new Date(dateTime);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = dateObj.toLocaleDateString("en-US", options);

    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const time = dateObj.toLocaleTimeString("en-US", timeOptions);

    return { date, time };
  };

  const interviewPercentage = studentApplicationData?.pie_chart_details?.interviewed;
  const formattedInterviewPercentage = parseFloat(interviewPercentage?.replace('%', ''));

  const discardedPercentage = studentApplicationData?.pie_chart_details?.discarded;
  const formattedDiscardedPercentage = parseFloat(discardedPercentage?.replace('%', ''));

  const inReviewPercentage = studentApplicationData?.pie_chart_details?.in_review;
  const formattedInReviewPercentage = parseFloat(inReviewPercentage?.replace('%', ''));

  const offeredPercentage = studentApplicationData?.pie_chart_details?.offered;
  const formattedOfferedPercentage = parseFloat(offeredPercentage?.replace('%', ''));

  const shortlistedPercentage = studentApplicationData?.pie_chart_details?.shortlisted;
  const formattedShortlistedPercentage = parseFloat(shortlistedPercentage?.replace('%', ''));

  const paiChartValue = [formattedInterviewPercentage, formattedInReviewPercentage, formattedDiscardedPercentage, formattedOfferedPercentage, formattedShortlistedPercentage]

  console.log('----studentApplicationData', studentApplicationData)

  console.log('-----applications["In-Review"]', studentApplicationData?.interviewed?.applications)

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="_main_content">
            <div className="_main_first_content">
              <div className="stat-container">
                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={applicationIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Applications</h3>
                    <p>{studentApplicationData?.total_applications || 0}</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={inReview} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>In-Review</h3>
                    <p>{studentApplicationData?.in_review?.count || 0}</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={rejectedIcon} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Declined</h3>
                    <p>{studentApplicationData?.discarded?.count || 0}</p>
                  </div>
                </div>

                <div className="stat-card">
                  <div className="stat-icon">
                    <img src={shortListed} alt="..." />
                  </div>
                  <div className="stat-info">
                    <h3>Shortlisted</h3>
                    <p>{studentApplicationData?.shortlisted?.count || 0}</p>
                  </div>
                </div>
              </div>
              <div className="student_chart_section">
                <div className="_chart_section_b">
                  <b>Statistics of All Applications</b>
                </div>
                <div className="_applicationStatisticsContainer student_applicationStatisticsContainer">
                  <div className="_pieChartContainer">
                    <DonutChart data={paiChartValue} />
                  </div>
                  <div className="_legendContainer">
                    <div style={{ height: '20px' }}>
                      <svg width="110" height="2" viewBox="0 0 110 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="1" x2="110" y2="1" stroke="#F38D00" stroke-width="2" />
                      </svg>
                    </div>
                    <h3 className="_legendTitle">
                      Applications{" "}
                      <span style={{ color: "#F38D00" }}>Pie Chart</span>
                    </h3>
                    <ul className="_legendList">
                      <li className="_legendItem">
                        <span className="_legendColor _offeredColor"></span>
                        {`Offered Applications(${formattedOfferedPercentage === 0 ? formattedOfferedPercentage : `${formattedOfferedPercentage}%`})`}
                      </li>
                      <li className="_legendItem">
                        <span className="_legendColor _interviewdColor"></span>
                        {`Interview Applications(${formattedInterviewPercentage === 0 ? formattedInterviewPercentage : `${formattedInterviewPercentage}%`})`}
                      </li>
                      <li className="_legendItem">
                        <span className="_legendColor _shortlistedColor"></span>
                        {`Shortlisted Applications(${formattedShortlistedPercentage === 0 ? formattedShortlistedPercentage : `${formattedShortlistedPercentage}%`})`}
                      </li>
                      <li className="_legendItem">
                        <span className="_legendColor _inReviewColor"></span>
                        {`In-Review Applications(${formattedInReviewPercentage === 0 ? formattedInReviewPercentage : `${formattedInReviewPercentage}%`})`}
                      </li>
                      <li className="_legendItem">
                        <span className="_legendColor _rejectedColor"></span>
                        {`Declined Applications(${formattedDiscardedPercentage === 0 ? formattedDiscardedPercentage : `${formattedDiscardedPercentage}%`})`}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="_applications_history _campus_dashboard_div">
                <section className="_campus_dashboard_application_history">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                      <b>Recent Applications History</b>
                      <div className="status-filters">
                        <button
                          className={`filter ${activeFilter === "Shortlisted"
                            ? "Shortlisted_active student_shortlisted_active"
                            : "filter_shortlisted student__filter_shortlisted"
                            }`}
                          onClick={() => {
                            setActiveFilter("Shortlisted");
                            setCurrentPage(1);
                          }}
                        >
                          Shortlisted ({applications?.Shortlisted?.length})
                        </button>
                        <button
                          className={`filter ${activeFilter === "In-Review"
                            ? "review_active student__filter_review_active"
                            : "filter_review student__filter_review"
                            }`}
                          onClick={() => {
                            setActiveFilter("In-Review");
                            setCurrentPage(1);
                          }}
                        >
                          In-Review ({applications["In-Review"]?.length})
                        </button>
                        <button
                          className={`filter ${activeFilter === "Rejected"
                            ? "rejected_active student__filter_rejected_active"
                            : "filter_rejected student__filter_rejected"
                            }`}
                          onClick={() => {
                            setActiveFilter("Rejected");
                            setCurrentPage(1);
                          }}
                        >
                          Interview ({applications.Rejected?.length})
                        </button>
                      </div>
                    </div>

                    <div className="student_myApplication_list" >
                      {currentItems?.length > 0 ? currentItems?.map((app) => (
                        <div key={app.id} className="application-item student_application_item">
                          <div className="d-flex align-items-center">
                            <div className="application-company-logo student_application_company_logo">
                              <img src={app?.job?.company_profile?.company_logo_url || dummy_logo} alt="logo" style={{ width: "25px" }} />
                            </div>
                            <div className="application-details student_application_details">
                              <h3>{app?.job?.title}</h3>
                              <p>
                                {app.job?.company && app.job?.company?.length > 30 ? `${app.job?.company?.slice(0, 30)}...` : app.job?.company}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <div className="application-date student_application_date">
                              <p>Date Applied</p>
                              <p>{formatDateTime(app.applied_at).date}</p>
                            </div>
                            <div
                              className="d-flex justify-content-between align-items-center gap-2"
                              style={{
                                paddingRight: "25px",
                                position: "relative",
                              }}
                            >

                              <button
                                onClick={() => handleDropDown(app.id)}
                                className="more-options student_more_options"
                              >
                                ⋮
                              </button>
                              {isOpen === app.id && (
                                // <div className="more-options-dropdown student_more_options_dropdown">
                                //   <ul>
                                //     <li>
                                //       <a href="#">Withdraw</a>
                                //     </li>
                                //     <li>
                                //       <Link to="/lateral/jobDetails">View</Link>
                                //     </li>
                                //   </ul>
                                // </div>
                                <div className="more-options-dropdown new_student_more_options_dropdown" style={{ left: "5px" }}>
                                  <ul>
                                    {app.status.replace("_", " ") === "in review" ? (
                                      <li>
                                        <button
                                          onClick={() => {
                                            handlewithdraw(app.id);
                                            setId(app.job.id);
                                          }}
                                          className="student_widthdraw_button"
                                        >
                                          Withdraw
                                        </button>
                                      </li>
                                    ) : null}
                                    <li>
                                      <Link to={`/dashboard/sidebar-page/student/job-details/${app.job.id}`}>
                                        View
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )) :
                        (
                          <div className="no-application-history">
                            <p>No Application History</p>
                          </div>
                        )}
                    </div>
                  </div>
                </section>
              </div>
            </div>

            <div className="d-grid gap-4 _recent_jobs_head">
              <div className="_recent_jobs">
                <div className="_recentadded_container student_recent_container">
                  <div className="_popularjobs_section">
                    <div className="_popularjobs_header student_popularjobs_header">
                      <b>Recent Added Jobs</b>
                      <Link to={'/dashboard/sidebar-page/student/student-jobs'} className="_popularjobs_seeAllLink">
                        See All
                      </Link>
                    </div>
                    <div className="_popularjobs_jobList student_popularJobs_list">
                      {recentJobs && recentJobs.slice(0, 7)?.map((job, index) => (
                        <div key={index} className="_recentadded_jobCard student_recentAdded_jobCard">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <div className="_recentadded_companyLogo">
                                <img
                                  src={job.job?.company_profile?.company_logo_url || dummy_logo}
                                  alt="logo"
                                  className="campus_dashboard_icon"
                                />
                              </div>
                              <div>
                                <h3 className="_recentadded_jobTitle">
                                  {job.job?.title?.length > 25 ? `${job.job?.title.slice(0, 25)}..` : job.job?.title || 'Not Mentioned'}
                                </h3>
                                <p className="_recentadded_companyName">
                                  {job.job?.company?.length > 25 ? `${job.job?.company.slice(0, 25)}..` : job.job?.company || 'Not Mentioned'}
                                </p>
                              </div>
                            </div>
                            <Link to={`/dashboard/sidebar-page/student/job-details/${job.job?.id}`}>
                              <button className="_recentadded_viewMoreBtn">
                                View More
                              </button>
                            </Link>
                          </div>
                          <div className="_recentadded_jobInfo">
                            <p className="card-text">
                              <span>
                                <img
                                  src={locationIcon}
                                  width="9px"
                                  height="13px"
                                  alt="..."
                                />
                              </span>
                              <small className="small">
                                <i className="bi bi-geo-alt"></i> {job.job?.job_location?.length > 25 ? `${job.job?.job_location.slice(0, 25)}..` : job.job?.job_location || 'Not Mentioned'}
                              </small>
                            </p>
                            <p className="_recentadded_jobDate">{getPostedDate(job?.applied_at)}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

export default StudentHome;
