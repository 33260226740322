import React, { useState, useEffect } from "react";
import "../../../styles/dashboards/dashboard.css";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/myApplication/myapplication.css";
import "chart.js/auto";
import { motion } from "framer-motion";
import notificationIcon from "../../../utils/landingpage/images/notificationIcons.png";
import startNaukriNewLogo from "../../../utils/dashboards/startNaukriNewLogo.svg";
import myInterviewIcon from "../../../utils/dashboards/myInterview.svg";
import celanderIcon from "../../../utils/dashboards/celanderIcon.svg";
import myAssesmentIocn from "../../../utils/dashboards/assementIcon.svg";
import jobListing from "../../../utils/dashboards/jobIcon.svg";
import homeIcon from "../../../utils/dashboards/homeIcon.svg";
import mySchedule from "../../../utils/dashboards/mySchedule.svg";
import myApplication from "../../../utils/dashboards/myApplicationIcon.svg";
import msgIcon from "../../../utils/dashboards/msgIcon.svg";
import transectionIcon from "../../../utils/dashboards/tansection.svg";
import savedIcon from "../../../utils/dashboards/savedIcon.svg";
import companyIcon from "../../../utils/dashboards/companyIcon.svg";
import clgIcon from "../../../utils/dashboards/clgicon.svg";
import stdIcon from "../../../utils/dashboards/studenIcon.svg";
import settingIcon from "../../../utils/dashboards/settingIcon.svg";
import plansIcons from "../../../utils/dashboards/plans.svg";
import subUserIcon from "../../../utils/dashboards/subUserIcon.svg";
import requestIcon from "../../../utils/dashboards/requestIcons.svg";
import transistionHistory from "../../../utils/dashboards/transistionHistory.svg";
import invitation from "../../../utils/dashboards/invitation.svg";
import myprofileIocn from "../../../utils/dashboards/myProfileIcon.svg";
import transectionIocn from "../../../utils/dashboards/myTransetionsIcons.svg";
import userManageIcon from "../../../utils/dashboards/userManagIcons.svg";
import adminAssessMentIcon from "../../../utils/dashboards/adminassessment.svg";
import logoutIcon from "../../../utils/dashboards/logoutIcon.svg";
import LogOut from "../../reusablecomponents/Logout";
import HRManagerHome from "../hr-manager-dashbaord/HRManagerHome ";
import HRManagerMessages from "../hr-manager-dashbaord/HRManagerMessages ";
import HRManagerAllApplications from "../hr-manager-dashbaord/HRManagerAllApplications ";
import HRManagerMySchedule from "../hr-manager-dashbaord/HRManagerMySchedule ";
import StudentHome from "../campus-students-dashbaord/StudentHome ";
import StudentMessages from "../campus-students-dashbaord/StudentMessages ";
import StudentMyApplication from "../campus-students-dashbaord/StudentMyApplication ";
import StudentSavedJobs from "../campus-students-dashbaord/StudentSavedJobs";
import CampusManagerHome from "../collage-dashboard/CampusManagerHome ";
import CampusManagerStudent from "../collage-dashboard/CampusManagerStudent ";
import CampusManagerJobs from "../collage-dashboard/CampusManagerJobs ";
import CampusManagerCourse from "../collage-dashboard/CampusManagerCourse ";
import CampusManagerMySchedule from "../collage-dashboard/CampusManagerMySchedule ";
import CampusManagerInvitation from "../collage-dashboard/CampusManagerInvitation";
import StudentInterview from "../campus-students-dashbaord/StudentInterview";
import HRTransectionHistory from "../hr-manager-dashbaord/HRTransectionHistory";
import HRManagerProfile from "../hr-manager-dashbaord/hrprofile/HRManagerProfile";
import HRManagerSubUsers from "../hr-manager-dashbaord/HRManagerSubUsers";
import HrManagerCampuses from "../hr-manager-dashbaord/HrManagerCampuses";
import HRManagerSettings from "../hr-manager-dashbaord/HRManagerSettings";
import CampusManagerProfile from "../collage-dashboard/CampusManagerProfile";
import CampusManagerSettings from "../collage-dashboard/CampusManagerSettings";
import StudentSettings from "../campus-students-dashbaord/StudentSettings";
import StudentProfile from "../campus-students-dashbaord/student_profile/StudentProfile";
import CampuseManagerCandiate from "../collage-dashboard/CampuseManagerCandiate";
import CampuseManagerSubscription from "../collage-dashboard/CampuseManagerSubscription";
import CampuseCandidateRequest from "../collage-dashboard/CampuseCandidateRequest";
import AdminRequest from "../adminDashboard/AdminRequest";
import StudentJobs from "../campus-students-dashbaord/StudentJobs";
import { useNavigate } from "react-router-dom";
import dropDown from "../../../utils/dashboards/drop-down-arrow.svg";
import CampuseJobList from "../hr-manager-dashbaord/jobs/CampuseJobList ";
import CirculatedList from "../hr-manager-dashbaord/jobs/CirculatedList";
import HRAssessment from "../hr-manager-dashbaord/assessments/HRAssessment";
import AdminHome from "../adminDashboard/AdminHome";
import Colleges from "../adminDashboard/Colleges";
import Students from "../adminDashboard/Students";
import Companies from "../adminDashboard/Companies";
import MySchedule from "../adminDashboard/MySchedule";
import UserManagement from "../adminDashboard/UserManagement";
import AdminJobListing from "../adminDashboard/AdminJobListing";
import AdminAssessment from "../adminDashboard/AdminAssessment";
import Settings from "../adminDashboard/settings/Settings";
import AdminPlans from "../adminDashboard/subscriptions/AdminPlans";
import TransactionHistory from "../adminDashboard/transections/TransactionHistory";
import { useDispatch, useSelector } from "react-redux";
import { fetchHrProfileData } from "../../../redux/actions/hr_profile_action";
import { fetchStudentProfileData } from "../../../redux/actions/student_profile_action";
import Home from "../sub-hr-dashboard/Home";

// Define dashboards for different roles
const dashboards = {
  Admin: {
    mainMenu: [
      { name: "Home", icon: homeIcon, link: "home" },
      {
        name: "Request",
        leftIcon: dropDown,
        icon: myApplication,
        link: "",
        children: [
          { name: "Company", icon: companyIcon, link: "company-request" },
          { name: "College", icon: clgIcon, link: "collage-request" },
        ],
      },
      { name: "Colleges", icon: stdIcon, link: "colleges" },
      { name: "Students", icon: clgIcon, link: "students" },
      { name: "Companies", icon: companyIcon, link: "companies" },
      { name: "My Schedule", icon: mySchedule, link: "admin-my-schedule" },
      {
        name: "User Management",
        icon: userManageIcon,
        link: "user-management",
      },
    ],
    settings: [
      { name: "Settings", icon: settingIcon, link: "settings" },
      { name: "Job Listing", icon: jobListing, link: "job-listing" },
      {
        name: "Assessment",
        icon: adminAssessMentIcon,
        link: "admin-assessment",
      },
      { name: "Plans", icon: plansIcons, link: "plans" },
      {
        name: "Transaction History",
        icon: transectionIcon,
        link: "transaction-history",
      },
      {
        name: "Content Management",
        icon: userManageIcon,
        link: "content-management",
      },
    ],
    components: {
      home: <AdminHome />,
      "request-company": <AdminHome />,
      "company-request": <AdminRequest />,
      "collage-request": <AdminRequest />,
      colleges: <Colleges />,
      companies: <Companies />,
      students: <Students />,
      "user-management": <UserManagement />,
      "admin-my-schedule": <MySchedule />,
      settings: <Settings />,
      "job-listing": <AdminJobListing />,
      "admin-assessment": <AdminAssessment />,
      plans: <AdminPlans />,
      "transaction-history": <TransactionHistory />,
    },
  },
  HRManager: {
    mainMenu: [
      { name: "Home", icon: homeIcon, link: "home" },
      {
        name: "Job Listing",
        icon: myApplication,
        leftIcon: dropDown,
        link: "",
        children: [
          {
            name: "Campus Jobs",
            icon: jobListing,
            link: "",
            children: [
              {
                name: "Jobs List",
                icon: subUserIcon,
                link: "campus-job-list",
              },

              {
                name: "Circulated Job List",
                icon: myprofileIocn,
                link: "circulated-job-list",
              },
            ],
          },
          {
            name: "Lateral Jobs",
            icon: subUserIcon,
            link: "lateral-job-list",
          },
        ],
      },
      {
        name: "All Applications",
        icon: myInterviewIcon,
        link: "all-applications",
      },
      { name: "My Schedule", icon: mySchedule, link: "my-schedule" },
      {
        name: "Transaction History",
        icon: transectionIocn,
        link: "transaction-history",
      },
      {
        name: "Assessment",
        icon: adminAssessMentIcon,
        link: "assessment",
      },
    ],
    settings: [
      { name: "Campuses", icon: myAssesmentIocn, link: "campuses" },
      {
        name: "Sub-user Settings",
        icon: subUserIcon,
        link: "sub-user-settings",
      },
      { name: "Settings", icon: settingIcon, link: "hr-user-settings" },
      { name: "My Profile", icon: myprofileIocn, link: "my-profile" },
    ],
    components: {
      home: <HRManagerHome />,
      messages: <HRManagerMessages />,
      "all-applications": <HRManagerAllApplications />,
      "my-schedule": <HRManagerMySchedule />,
      "transaction History": <HRTransectionHistory />,
      campuses: <HrManagerCampuses />,
      "my-profile": <HRManagerProfile />,
      "sub-user-settings": <HRManagerSubUsers />,
      "hr-user-settings": <HRManagerSettings />,
      "lateral-listing": <CampuseJobList />,
      "circulated-job-list": <CirculatedList />,
      assessment: <HRAssessment />,
    },
  },
  Student: {
    mainMenu: [
      { name: "Home", icon: homeIcon, link: "student-home" },
      // { name: "Message", icon: msgIcon, link: "student-message" },
      { name: "My Application", icon: myApplication, link: "my-application" },
      { name: "Jobs", icon: jobListing, link: "student-jobs" },
      { name: "Saved Jobs", icon: savedIcon, link: "student-saved-jobs" },
      { name: "My Interview", icon: myInterviewIcon, link: "my-interview" },
    ],
    settings: [
      { name: "Settings", icon: settingIcon, link: "student-settings" },
      {
        name: "My Public Profile",
        icon: myprofileIocn,
        link: "student-profile",
      },
    ],
    components: {
      "student-home": <StudentHome />,
      "student-message": <StudentMessages />,
      "my-application": <StudentMyApplication />,
      "student-saved-jobs": <StudentSavedJobs />,
      "student-settings": <StudentSettings />,
      "student-profile": <StudentProfile />,
      "my-interview": <StudentInterview />,
      "student-jobs": <StudentJobs />,
    },
  },
  CampusManager: {
    mainMenu: [
      { name: "Home", icon: homeIcon, link: "clg-home" },
      { name: "Student", icon: myApplication, link: "clg-student" },
      { name: "Jobs", icon: myInterviewIcon, link: "clg-jobs" },
      { name: "Course", icon: savedIcon, link: "clg-course" },
      { name: "My Schedule", icon: mySchedule, link: "clg-my-schedule" },
      {
        name: "Company Invitation",
        icon: invitation,
        link: "clg-company-invitation",
      },
      {
        name: "Candidate Request",
        icon: requestIcon,
        link: "clg-candidate-request",
      },
      {
        name: "Transaction History",
        icon: transistionHistory,
        link: "transaction-history",
      },
    ],
    settings: [
      { name: "Settings", icon: settingIcon, link: "clg-settings" },
      { name: "Profile", icon: myprofileIocn, link: "clg-profile" },
    ],
    components: {
      home: <CampusManagerHome />,
      student: <CampusManagerStudent />,
      jobs: <CampusManagerJobs />,
      course: <CampusManagerCourse />,
      "my-schedule": <CampusManagerMySchedule />,
      invitation: <CampusManagerInvitation />,
      settings: <CampusManagerSettings />,
      profile: <CampusManagerProfile />,
      "campuse-manager-subscription": <CampuseManagerSubscription />,
      "clg-candidate-request": <CampuseCandidateRequest />,
      "transaction-history": <TransactionHistory />,
    },
  },

  SubHRManager: {
    mainMenu: [
      { name: "Home", icon: homeIcon, link: "home" },
      {
        name: "Job Listing",
        icon: myApplication,
        leftIcon: dropDown,
        link: "",
        children: [
          {
            name: "Campus Jobs",
            icon: jobListing,
            link: "",
            children: [
              {
                name: "Jobs List",
                icon: subUserIcon,
                link: "campus-job-list",
              },

              {
                name: "Circulated Job List",
                icon: myprofileIocn,
                link: "circulated-job-list",
              },
            ],
          },
          {
            name: "Lateral Jobs",
            icon: subUserIcon,
            link: "lateral-job-list",
          },
        ],
      },
      {
        name: "All Applications",
        icon: myInterviewIcon,
        link: "all-applications",
      },
      { name: "My Schedule", icon: mySchedule, link: "my-schedule" },
      {
        name: "Transaction History",
        icon: transectionIocn,
        link: "transaction-history",
      },
    ],
    settings: [
      { name: "Campuses", icon: myAssesmentIocn, link: "campuses" },
      { name: "Settings", icon: settingIcon, link: "hr-user-settings" },
    ],
    components: {
      home: <Home />,
    },
  },
};

const DashboardSideBar = () => {
  const dispatch = useDispatch();

  const roleNames = {
    1: "clg-manager",
    2: "admin",
    3: "hr-manager",
    4: "student",
    5: "sub-hr",
  };
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const hrProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );

  const studentProfile = useSelector(
    (state) => state.studentProfile.getStudentProfileData
  );

  // const toggleSidebar = () => {
  //   setIsSidebarOpen((prev) => !prev);
  // };

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const userRole = userData?.role;
  const getDefaultMenu = (role) => {
    switch (role) {
      case 3: // Hr Manager
        return "home";
      case 1: // Clg Manager
        return "clg-home";
      case 2: // Admin
        return "home";
      case 4: // Student
        return "student-home";
      case 5: // sub-hr
        return "home";
      default:
        return "home";
    }
  };

  // Setting default active menu based on user role
  const [activeMenu, setActiveMenu] = useState(getDefaultMenu(userRole));
  const [isOpen, setIsOpen] = useState(false);

  const [activeDashboard, setActiveDashboard] = useState(null);

  const [roleName, setRoleName] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  const [openParent, setOpenParent] = useState(null);
  const [openChild, setOpenChild] = useState(null);

  const [userProfile, setUserProfile] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    let dashboard;
    switch (userRole) {
      case 1:
        dashboard = dashboards.CampusManager;
        break;
      case 2:
        dashboard = dashboards.Admin;
        break;
      case 3:
        dashboard = dashboards.HRManager;
        break;
      case 4:
        dashboard = dashboards.Student;
        break;
      case 5:
        dashboard = dashboards.SubHRManager;
        break;
    }
    setActiveDashboard(dashboard);
    setRoleName(roleNames[userRole] || "Unknown Role");

    // Declare storedMenu once and use it
    const storedMenu = sessionStorage.getItem("activeMenu");

    // Set default for HR Manager if no menu is stored
    if (!storedMenu && userRole === 3) {
      const defaultHrMenu = "home"; // Default HR Manager tab
      sessionStorage.setItem("activeMenu", defaultHrMenu);

      setActiveMenu(defaultHrMenu);
    } else if (storedMenu) {
      setActiveMenu(storedMenu);
    }

    // Navigate to the proper dashboard based on role and menu
    if (!storedMenu) {
      navigate(`/dashboard/sidebar-page/${roleNames[userRole]}/${activeMenu}`);
    } else {
      setActiveMenu(storedMenu);
    }

    if (userRole === 3) {
      setUserProfile(hrProfileData.company_logo_url);
    }
    if (userRole === 4) {
      setUserProfile(studentProfile.student_photo_url);
    }
  }, [userRole, navigate, roleName, activeMenu, hrProfileData]);

  useEffect(() => {
    const storedMenu = sessionStorage.getItem("activeMenu");
    if (storedMenu) {
      setActiveMenu(storedMenu);
    }

    const formatDate = () => {
      const date = new Date();
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    };
    setCurrentDate(formatDate());

    if (userRole === 3) {
      dispatch(fetchHrProfileData());
    }
    if (userRole === 4) {
      dispatch(fetchStudentProfileData());
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (link) => {
    const menuLink = link;
    setActiveMenu(menuLink);
    sessionStorage.setItem("activeMenu", menuLink);
    navigate(`/dashboard/sidebar-page/${roleNames[userRole]}/${menuLink}`);
  };

  const handlePostJob = () => {
    navigate(`/dashboard/sidebar-page/${roleNames[userRole]}/post-job`);
  };

  // Handle parent dropdown toggle
  const handleParentDropdown = (parent) => {
    setOpenParent(openParent === parent ? null : parent);
    setOpenChild(null); // Close child dropdown when parent changes
  };

  // Handle child dropdown toggle within a parent
  const handleChildDropdown = (child) => {
    setOpenChild(openChild === child ? null : child);
  };

  // handle profile of user click
  const handleProfile = () => {
    console.log("hr profile click");
    if (userRole === 3) {
      navigate(`/dashboard/sidebar-page/hr-manager/my-profile`);
    }
    if (userRole === 4) {
      navigate(`/dashboard/sidebar-page/student/student-profile`);
    }
  };

  // handle settings of user
  const handleSettings = () => {
    if (userRole === 3) {
      navigate(`/dashboard/sidebar-page/hr-manager/hr-user-settings`);
    }
    if (userRole === 4) {
      navigate(`/dashboard/sidebar-page/student/student-settings`);
    }
  };

  return (
    <>
      <div className="_main_dashboard">
        <motion.aside
          className={`_side_navigation ${isSidebarOpen ? "open" : "closed"}`}
          transition={{ duration: 0.5 }}
        >
          <div className="_company_logo">
            <img src={startNaukriNewLogo} alt="..." />
            <div className="_sidbar_close">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="white"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
              </svg> */}
            </div>
          </div>
          <nav className="_menu_container">
            {/* main menu settings */}
            <b>Main Menu</b>
            <ul className="_menu_list">
              {activeDashboard?.mainMenu?.map((item, index) => (
                <div key={index}>
                  <li
                    key={index}
                    className={`_menu_item ${
                      activeMenu === item.link ? "_active_item" : ""
                    }`}
                    onClick={(e) => {
                      // Prevent redirect if the item has children
                      if (!item.children) {
                        handleMenuClick(item.link);
                      } else {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <div>
                      <div
                        onClick={() => handleParentDropdown(item.name)}
                        className="d-flex align-items-center gap-3"
                        style={{ cursor: "pointer" }}
                      >
                        <img src={item.icon} alt={""} />
                        <p>{item.name}</p>
                        {item.children && (
                          <img
                            src={item.leftIcon}
                            alt="dropdown"
                            className={`${
                              openParent === item.name ? "open" : ""
                            }`}
                          />
                        )}
                      </div>

                      {/* Parent Dropdown Content */}
                      {openParent === item.name && item.children && (
                        <ul>
                          {item.children.map((child, childIndex) => (
                            <li
                              key={childIndex}
                              style={{
                                listStyle: "none",
                                paddingTop: "20px",
                                fontSize: "14px",
                              }}
                            >
                              {/* Child Dropdown Toggle */}
                              {child.children ? (
                                <>
                                  <div
                                    onClick={() =>
                                      handleChildDropdown(child.name)
                                    }
                                    className="d-flex align-items-center gap-3"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={child.icon} width={20} alt={""} />
                                    <p>{child.name}</p>{" "}
                                    {child.children && (
                                      <img
                                        src={item.leftIcon}
                                        alt="dropdown"
                                        className={`${
                                          openChild === child.name ? "open" : ""
                                        }`}
                                      />
                                    )}
                                  </div>

                                  {/* Nested Child Dropdown Content */}
                                  {openChild === child.name && (
                                    <ul>
                                      {child.children.map(
                                        (nestedChild, nestedIndex) => (
                                          <li
                                            key={nestedIndex}
                                            style={{
                                              listStyle: "none",
                                              paddingTop: "20px",
                                              fontSize: "13.5px",
                                            }}
                                          >
                                            <div className="d-flex align-items-center gap-3">
                                              <img
                                                src={nestedChild.icon}
                                                alt={""}
                                                width={15}
                                              />
                                              <p
                                                onClick={() =>
                                                  handleMenuClick(
                                                    nestedChild.link
                                                  )
                                                }
                                              >
                                                {nestedChild.name}
                                              </p>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  )}
                                </>
                              ) : (
                                <div className="d-flex align-items-center gap-3">
                                  <img src={child.icon} width={18} alt={""} />
                                  <p
                                    onClick={(e) => {
                                      // Prevent redirect if the child has its own children
                                      handleMenuClick(child.link);
                                    }}
                                  >
                                    {" "}
                                    {child.name}
                                  </p>
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </li>
                </div>
              ))}
            </ul>
            {/* settings ui sidebar */}
            <b>Settings</b>
            <ul className="_menu_list">
              {activeDashboard?.settings?.map((item, index) => (
                <li
                  key={index}
                  className={`_menu_item ${
                    activeMenu === item.link ? "_active_item" : ""
                  }`}
                  onClick={() => handleMenuClick(item.link)}
                >
                  <img
                    src={item.icon ? item.icon : ""}
                    alt={item.name ? item.name : ""}
                  />
                  <p>{item.name}</p>
                </li>
              ))}
            </ul>
          </nav>
        </motion.aside>

        <main className="_main_content_inner_screen">
          <header className="_top_header">
            <div className="_top_header_inner">
              <div className="user-profile-container">
                <div>
                  {/* <button className="toggle-btn" onClick={toggleSidebar}>
                    {isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
                  </button> */}
                </div>
                {/* celander */}
                <div className="celander_container">
                  <img src={celanderIcon} alt="celander" />
                  <p>{currentDate}</p>
                </div>
                {/* Notification icons */}
                <div className="position-relative">
                  <img
                    src={notificationIcon}
                    width="22.5px"
                    height="24px"
                    alt="..."
                  />
                  <div className="user_notif_cont"></div>
                </div>
                {/* user profile and drop down */}
                {roleName == "hr-manager" || roleName == "sub-hr" ? (
                  <div>
                    <button
                      onClick={handlePostJob}
                      className="p-2 px-4  rounded-pill border-0"
                      style={{
                        background: "#F38D00",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      Post Job
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="user-profile" onClick={toggleDropdown}>
                  <img
                    src={
                      userProfile ||
                      "https://www.gcsmc.org/assets/images/user.png"
                    }
                    alt="user img"
                    className="avatar"
                  />
                  <span className="name">
                    {userData.name} {userData.student_last_name}
                    <br />
                    <h6
                      style={{
                        fontSize: "8px",
                      }}
                    >
                      {roleName}
                    </h6>
                  </span>
                  <span className={`dropdown-icon ${isOpen ? "open" : ""}`}>
                    ▼
                  </span>
                </div>
                {isOpen && (
                  <div className="dropdown-content">
                    <ul>
                      <li onClick={handleProfile}>Profile</li>
                      <li onClick={handleSettings}>Settings</li>
                      <li>
                        <LogOut />
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </header>
        </main>
      </div>
    </>
  );
};

export default DashboardSideBar;
