import axios from "axios";
import React, { useState } from "react";
import arrowImg from "../../../utils/landingpage/images/navigate.svg";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import { useNavigate } from "react-router-dom";
import "../../../styles/admin/companydetails.css";
import { IconButton, Menu, MenuItem } from "@mui/material";
import threeDot from "../../../utils/landingpage/images/three-dots-svgrepo-com.svg";

function ViewCompanyDetails() {
  const ITEM_HEIGHT = 36;
  const navigate = useNavigate();
  const [anchorEls, setAnchorEls] = useState({});

  const handleClick = (event, panId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [panId]: event.currentTarget,
    }));
  };

  const handleClose = (panId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [panId]: null,
    }));
  };

  const goToBack = () => {
    navigate(-1);
  };

  const getCompanyDetails = async () => {
    try {
      const response = await axios.get();
    } catch (error) {}
  };
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="admin_plan_header">
            <h6 className="d-flex align-items-center justify-content-center">
              <img
                className="arrow_icon"
                onClick={goToBack}
                src={arrowImg}
                alt=".."
              />
              &nbsp; Company Details
            </h6>
            <div className="d-flex gap-3">
              <div className="campuse_student_request_search_bar">
                <img src={searchLogo} alt="search" />
                <input type="text" placeholder="Search" />
              </div>
              <button className="add_plan_btn">Add Sub-User</button>
            </div>
          </div>

          {/* ----------------------------- */}

          <div className="company_details">
            {/* logo */}
            <div className="company_logo_container">
              <div className="company_logo">
                <img src="https://via.placeholder.com/100" alt="." />
              </div>
              <div className="company_title">
                {/* title */}
                <p className="company_title_heading">Microsoft Pvt. Ltd.</p>
                <p className="company_title_sub_heading">Gurugram, Haryana</p>
              </div>
            </div>
            {/* company details */}
            <div className="company_others_details">
              <div className="company_others_details_con">
                {" "}
                <p className="company_title_sub_heading">Name</p>
                <p className="company_title_heading">Bill Gates</p>
              </div>
              <div className="company_others_details_con">
                {" "}
                <p className="company_title_sub_heading">Business Email</p>
                <p className="company_title_heading">yourmail@gmail.com</p>
              </div>
              <div className="company_others_details_con">
                {" "}
                <p className="company_title_sub_heading">Phone Number</p>
                <p className="company_title_heading">7874568544</p>
              </div>
              <div className="company_others_details_con">
                {" "}
                <p className="company_title_sub_heading">City</p>
                <p className="company_title_heading">Gurugram</p>
              </div>
            </div>

            {/* btn */}
            <div className="three_icon_btn">
              {" "}
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={anchorEls[1] ? "long-menu" : undefined}
                aria-expanded={anchorEls[1] ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => handleClick(event, 1)}
              >
                <img src={threeDot} height={18} width={18} alt=".." />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEls[1]} // Now specific to the user
                open={Boolean(anchorEls[1])}
                onClose={() => handleClose(1)}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 3.5,
                    width: "12ch",
                  },
                }}
              >
                <MenuItem>Deactivate</MenuItem>
              </Menu>
            </div>
          </div>

          {/* ------------------------------ */}

          <h6>Sub-User Management</h6>

          {/* ------------------------------ */}
        </div>
      </div>
    </>
  );
}

export default ViewCompanyDetails;
