import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/auth/auth.css";
import logoimg from "../../utils/landingpage/images/logo_img.svg";
import starnaukri from "../../utils/auth/images/start-nuakry.svg";
import googleicon from "../../utils/auth/images/googleicon.svg";
import borderLine from "../../utils/auth/images/boderLine.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../redux/actions/loginAction";

// Validation Schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(5, "Password should be at least 5 characters long"),
});

const initialValues = {
  email: "",
  password: "",
};

const baseURL = process.env.REACT_APP_BASE_URL;

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetPsdActive, setResetPsdActive] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");

  const dispatch = useDispatch();
  const { loading, error, user } = useSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get("userId");
    const token = params.get("token");

    console.log("User ID:", userId, "Token:", token);

    if (userId !== null && token !== null) {
      setResetPsdActive(true);
    }

    console.log(userId);
    console.log(token);

    if (userId && token) {
      setUserId(userId);
      setToken(token);
      setResetPsdActive(true);
    }
  }, [location]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(loginUser(values, navigate, ''));
    },
  });

  function handleForgetPassword(e) {
    e.preventDefault();
    setIsSendingReset(true);
    const formData = {
      email: registeredEmail,
    };

    axios
      .post(`${baseURL}/sendresetpasswordemail/`, formData)
      .then((res) => {
        setResetSuccess(true);
        setRegisteredEmail("");
        toast.success(res.data.msg);
      })
      .catch((error) => {
        if (error.response && error.response.data.non_field_errors) {
          // Display the error message from the API response
          toast.error(error.response.data.non_field_errors[0]);
          setIsSendingReset(false);
        } else {
          // Display a generic error message if something else went wrong
          toast.error("Something went wrong. Please try again.");
        }
        console.log(error);
      })
      .finally(() => {
        setIsSendingReset(false);
      });
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/resetpassword/${userId}/${token}/`,
        {
          password: newPassword,
          password2: confirmPassword,
        }
      );

      console.log("Userid", userId, "token", token);
      console.log(response.status);

      if (response.status == 200) {
        toast.success("Password reset successfully");
        setResetPsdActive(false);
        navigate("/login"); // Redirect to login page
      } else {
        toast.error(response.data.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("An error occurred while resetting password");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="register_user_container">
        <div className="h-100 w-100 bg-info-subtle"></div>
        <div className="register_main_parent">
          <div className="register_user_main">
            <div className="register_background_first">
              <div className="register_background_first_inner">
                <Link to="/lateral-hiring">
                  <img src={logoimg} alt="..." />
                </Link>
                <div className="register_background_heading">
                  <h1>Welcome Back to Start Naukri!</h1>
                  <p>
                    To keep connected with us please enter your personal info
                  </p>
                </div>
              </div>
            </div>
            <div className="register_content_second">
              <div className="register_content_second_container">
                <div className="login-mobile_logo">
                  <img src={starnaukri} alt="start-naukri" />
                </div>
                <h2 className="login_heading">Login</h2>
                <div className="register_header_line"></div>
                <div className="register_account_options">
                  <p>Please enter your login details</p>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="register_form_container"
                >
                  <div className="register_form_section_candidate">
                    <div className="candidate_input_group_container">
                      <div className="login_candidate_input_group login_candidate_input_group_new">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="register_error_msg">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="login_candidate_input_group login_candidate_input_group_new">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="register_error_msg">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="forgot_password_container d-flex justify-content-between">
                      <div>
                        <p
                          className="forgot_Password"
                          onClick={() => setShowPopup(true)}
                        >
                          forgot Password?
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="register_send_btn_container pt-4">
                    <button
                      type="submit"
                      className="login_submit_btn"
                      disabled={loading || formik.isSubmitting}
                    >
                      {loading ? <div className="spinner"></div> : "Login"}
                    </button>
                    <div className="Login_partitan_line">
                      <div className="Login_partitan_line_one"></div>
                      <span>or</span>
                      <div className="Login_partitan_line_tow"></div>
                    </div>
                    <button className="google_register_send_btn">
                      <img src={googleicon} alt="google-auth" />
                      <div>Login with Google</div>
                    </button>
                    <div className="mt-5 text-center">
                      You don’t have an account?{" "}
                      <Link
                        to={"/register"}
                        style={{ textDecoration: "none", color: "#F38D00" }}
                      >
                        Signup
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer position="top-right" /> */}
      </div>

      {showPopup && (
        <div className="forgot-password-overlay">
          <div className="forgot-password-popup">
            <div style={{ width: "65%" }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "auto" }}
              >
                <div className="text-center">
                  {resetSuccess == false ? (
                    <h2 className="forgot-password-title">Forgot Password</h2>
                  ) : (
                    <h2 className="forgot-password-title">
                      Please Check Your Registered Email!
                    </h2>
                  )}
                  <img
                    src={borderLine}
                    alt="..."
                    style={{ marginTop: "-10px" }}
                  />
                </div>
              </div>

              {resetSuccess == false ? (
                <p className="forgot-password-description">
                  Please enter your register email address
                  <br />
                  and reset your password
                </p>
              ) : (
                <p className="forgot-password-description rest-password-sent">
                  A password reset link has been sent to your registered email
                  address. Please follow the <br /> instructions in the email to
                  reset your password.
                </p>
              )}

              {resetSuccess == false ? (
                <form onSubmit={handleForgetPassword}>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={registeredEmail}
                    onChange={(e) => setRegisteredEmail(e.target.value)}
                    className="forgot-password-input"
                    required
                  />
                  <div className="forgot-password-buttons">
                    <button
                      type="button"
                      onClick={() => setShowPopup(false)}
                      className="forgot-password-cancel"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="forgot-password-send"
                      disabled={isSendingReset}
                    >
                      {isSendingReset ? (
                        <div className="spinner"></div>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {resetPsdActive && (
        <div className="forgot-password-overlay">
          <div className="forgot-password-popup">
            <div style={{ width: "65%" }}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "auto" }}
              >
                <div className="text-center">
                  <h2 className="forgot-password-title">Reset your password</h2>
                  <img
                    src={borderLine}
                    alt="..."
                    style={{ marginTop: "-10px" }}
                  />
                </div>
              </div>
              <p className="forgot-password-description">
                Create a new password to continue.
              </p>

              <form onSubmit={handleResetPassword}>
                <div className="forgot-password-input-group">
                  <label
                    htmlFor="newPassword"
                    className="forgot-password-label"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="forgot-password-input"
                    required
                  />
                </div>
                <div className="forgot-password-input-group">
                  <label
                    htmlFor="confirmPassword"
                    className="forgot-password-label"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="password2"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="forgot-password-input"
                    required
                  />
                </div>
                <div className="forgot-password-buttons">
                  <button type="submit" className="forgot-password-send w-100">
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
