import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import "../../../styles/dashboards/clgdashboard/subcription.css"; // Import your CSS file
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import { Link, useNavigate } from "react-router-dom";
const transactions = [
  {
    id: "TX12345",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12346",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12347",
    date: "31-08-24",
    plan: "Basic Plan",
    amount: 36.99,
    status: "Pending",
    paymentMethod: "UPI Card",
  },
  {
    id: "TX12348",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12349",
    date: "31-08-24",
    plan: "Basic Plan",
    amount: 36.99,
    status: "Pending",
    paymentMethod: "UPI Card",
  },
  {
    id: "TX12350",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12351",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12352",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12353",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12354",
    date: "31-08-24",
    plan: "Premium Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
  {
    id: "TX12355",
    date: "31-08-24",
    plan: "Standard Plan",
    amount: 36.99,
    status: "Completed",
    paymentMethod: "Credit Card",
  },
];

const CampuseTransectionHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 4; // items per page

  const totalPages = Math.ceil(transactions.length / itemsPerPage);
  const currentItems = transactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleUpgradplan = () => {
    navigate("/dashboard/sidebar-page/:roleName/clg-upgrade-plan");
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="Transaction-page">
          <div className="transaction-page-header">
            <h5>Transaction History </h5>
            <select className="transaction-filter-dropdown">
              <option value="">Filter</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
              <option value="all">All</option>
            </select>
            <div className="transaction-search-input">
              <img src={searchIconDash} alt="Search Icon" />
              <input
                type="text"
                placeholder="Search"
                className="transaction-search-input-field"
              />
            </div>
            <button onClick={handleUpgradplan} className="upgrade-plan-button">
              Upgrade Plan
            </button>
          </div>
          <div className="transaction-table">
            <table className="transaction-body">
              <thead className="transaction-table-header">
                <tr>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Plan Purchased</th>
                  <th>Amount Paid</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.date}</td>
                      <td>{transaction.id}</td>
                      <td>{transaction.plan}</td>
                      <td>${transaction.amount.toFixed(2)}</td>
                      <td>{transaction.paymentMethod}</td>
                      <td>{transaction.status}</td>
                      <td>
                        <Link>view</Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="no-data">
                      No transactions found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <Stack spacing={2} alignItems="center">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: () => <img src={leftArrowIcon} alt="prev" />,
                      next: () => <img src={rightIconArrow} alt="next" />,
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
          <div className="transaction-footer">
            <div className="transaction-fotter1">
              Total Transaction <p>100 </p>
            </div>
            <div className="transaction-fotter2">
              Total Amount Paid <p>100 </p>
            </div>
            <div className="transaction-fotter3">
              Average Transaction Amount<p>100 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampuseTransectionHistory;
