import React from "react";
import Chart from 'react-apexcharts';
import "../../../styles/admin/adminhome.css";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import companyIcon from "../../../utils/dashboards/companies.svg";
import candidatesIcon from "../../../utils/dashboards/Candidates.svg";
import inInterviewIcon from "../../../utils/dashboards/inInterview.svg";
import rightArrowIcon from "../../../utils/landingpage/images/rightIconArrow.svg";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import collageIcon from "../../../utils/landingpage/images/companyIcon.svg";

function AdminHome() {

  const pendingCompanyRequest = [
    { company: "Microsoft Pvt. Ltd.", location: "Pune, Maharashtra", date: "26th July 2024" },
    { company: "Mphasis Pvt. Ltd.", location: "Vadodara , Gujarat", date: "25th July 2024" },
    { company: "Accenture Pvt. Ltd.", location: "Indore, Madhya Pra..", date: "18th July 2024" },
    { company: "Systango Pvt. Ltd.", location: "Indore, Madhya Pra..", date: "17th July 2024" }
  ];
  const pendingCollegeRequest = [
    { college: "Bennett University", location: "Pune, Maharashtra", date: "26th July 2024" },
    { college: "Ganpat University", location: "Vadodara , Gujarat", date: "25th July 2024" },
    { college: "IIM Mumbai", location: "Indore, Madhya Pra..", date: "18th July 2024" },
    { college: "Acropolis", location: "Indore, Madhya Pra..", date: "17th July 2024" }
  ];
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="admin-stat-container">
            <div className="admin-stat-card">
              <div className="stat-icon">
                <img src={applicationIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Number Of Colleges</h3>
                <p>500</p>
              </div>
            </div>

            <div className="admin-stat-card">
              <div className="stat-icon">
                <img src={companyIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Number Of Companies</h3>
                <p>500</p>
              </div>
            </div>

            <div className="admin-stat-card">
              <div className="stat-icon">
                <img src={candidatesIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Number Of Candidates</h3>
                <p>500</p>
              </div>
            </div>

            <div className="admin-stat-card">
              <div className="stat-icon">
                <img src={inInterviewIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Interviewed</h3>
                <p>500</p>
              </div>
            </div>

            <div className="admin-stat-card">
              <div className="stat-icon">
                <img src={inInterviewIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Placed</h3>
                <p>500</p>
              </div>
            </div>
          </div>
          <div className="admin-pending-request-container">
            <div className="admin-pending-company-request-container">
              <div className="admin-pcr-head d-flex justify-content-between align-items-center">
                <div>Pending Company Request</div>
                <div><a href="">See All <img src={rightArrowIcon} alt="Right Arrow Icon" /></a></div>
              </div>
              <div className="admin-pcr-body">
                {pendingCompanyRequest.map((job, index) => (
                  <div key={index} className="admin-pcr-row">
                    
                    <div className="admin-pcr-col1">
                      <div className="amdin-pcr-thumbnail">
                        <img src={microsoftIMG} alt="" />
                      </div>
                      <div>
                        <h3 className="_recentadded_jobTitle">
                          {job.company}
                        </h3>
                        <p className="_recentadded_companyName">
                          {job.location}
                        </p>
                      </div>
                    </div>
                    <div className="admin-pcr-col2">
                      <h3 className="_recentadded_jobTitle">
                        Date Applied
                      </h3>
                      <p className="_recentadded_companyName">
                        {job.date}
                      </p>
                    </div>
                    <div className="admin-pcr-col3">
                      <a href="">View <img src={rightArrowIcon} alt="Right Arrow Icon" /></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="admin-pending-college-request-container">
              <div className="admin-pcr-head d-flex justify-content-between align-items-center">
                <div>Pending College Request</div>
                <div><a href="">See All <img src={rightArrowIcon} alt="Right Arrow Icon" /></a></div>
              </div>
              <div className="admin-pcr-body">
                {pendingCollegeRequest.map((job, index) => (
                  <div key={index} className="admin-pcr-row">
                    
                    <div className="admin-pcr-col1">
                      <div className="amdin-pcr-thumbnail">
                        <img src={collageIcon} alt="" />
                      </div>
                      <div>
                        <h3 className="_recentadded_jobTitle">
                          {job.college}
                        </h3>
                        <p className="_recentadded_companyName">
                          {job.location}
                        </p>
                      </div>
                    </div>
                    <div className="admin-pcr-col2">
                      <h3 className="_recentadded_jobTitle">
                        Date Applied
                      </h3>
                      <p className="_recentadded_companyName">
                        {job.date}
                      </p>
                    </div>
                    <div className="admin-pcr-col3">
                      <a href="">View <img src={rightArrowIcon} alt="Right Arrow Icon" /></a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="admin-pending-request-container">
            <div className="admin-candidates-container">
              <div className="admin-pcr-head d-flex justify-content-between align-items-center">
                <div>All Candidates</div>
              </div>
              <DonutChart/>
            </div>
            <div className="admin-revenue-container">
              <div className="admin-pcr-head d-flex justify-content-between align-items-center">
                <div>Revenue From Companies (Last 6 Months)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHome;

function DonutChart() {
  const options = {
    plotOptions: {
      pie: {
        customScale: 1,
        dataLabels: {
            offset: 0,
            minAngleToShowLabel: 10
        }, 
        donut: {
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 500,
              offsetY: 16,
              formatter: function (val) {
                return val ? val.toString() : '0'; // Add null/undefined check
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              fontSize: '22px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                // Ensure seriesTotals is defined and is an array
                return w.globals.seriesTotals
                  ? w.globals.seriesTotals.reduce((a, b) => a + b, 0)?.toString()
                  : '0';
              },
            }
          }
        },      
      }
    },
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val ? val.toString() : ''; // Add null/undefined check
      },
    },
    fill: {
      colors: ['#F38D00', '#0082ED']
    },
    colors: ['#F38D00', '#0082ED'],
    legend: {
      horizontalAlign: 'left',
      position: 'bottom',
      fontSize: '16px',
      fontweight: 600
    }
  
  }; // Chart options
  const series = [300, 200]; // Data series
  const labels = ['Campus Candidate 300', 'Lateral Candidate 200']; // Chart labels

  return (
    <div className="donut">
      <Chart
        options={{ ...options, labels }}
        series={series}
        type="donut"
        width="380"
      />
    </div>
  );
}