import axios from "axios";
import {
  SetIsLoading,
  setGetHrProfileData,
  setIsEditModelOpen,
  setIsSubmitted,
  setProfileCompletion,
} from "../../reducers/hrProfileReducer";
import { toast } from "react-toastify";

export const fetchHrProfileData = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userSessionData = sessionStorage.getItem("userDetails");
  if (accessToken && userSessionData) {
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.id;
    if (!userId) return;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const url = `${process.env.REACT_APP_BASE_URL}/api/company_profile/${userId}/`;
    try {
      const response = await axios.get(url, config);

      dispatch(setGetHrProfileData(response.data));

      // console.log(response.data);

      if (
        response.data.gst_number !== null &&
        response.data.mission_vision !== null
      ) {
        dispatch(setIsSubmitted(true));
        dispatch(setIsEditModelOpen(true));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User session data or access token is missing.");
  }
};

export const fetchProfileCompletion = () => async (dispatch) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const userSessionData = sessionStorage.getItem("userDetails");
  if (accessToken && userSessionData) {
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.id;
    if (!userId) return;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/company_profile_completion/`;
    try {
      const response = await axios.get(url, config);
      const profilePercentage = response.data.profile_completion;
      const profileCompletion =
        parseFloat(profilePercentage.toString().replace("%", "")) || 0;
      const remainingCompletion = 100 - profileCompletion;
      dispatch(setProfileCompletion(profileCompletion));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.warn("User session data or access token is missing.");
  }
};

export const updateHrProfileData =
  (profileCreationData) => async (dispatch) => {
    dispatch(SetIsLoading(true));
    const token = sessionStorage.getItem("accessToken");
    const userData = JSON.parse(sessionStorage.getItem("userDetails"));
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/company_profile/${userData.id}/`,
        profileCreationData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        toast.success("Profile saved successfully!");
        dispatch(setGetHrProfileData(response.data));
        dispatch(SetIsLoading(false));
        dispatch(setIsEditModelOpen(true));
      }

      fetchHrProfileData();

    } catch (error) {
      console.error("Error saving profile:", error);
      toast.error("Failed to save profile.");
    } finally {
      dispatch(SetIsLoading(false));
    }
  };
