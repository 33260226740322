import React from "react";

function Home() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">Home</div>
    </div>
  );
}

export default Home;
