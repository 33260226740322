import React, { useState } from "react";
import Slider from "react-slick";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "../../common_component/testimonial/testimonial";

export default function CampusManagerStudent() {
  const [showAll, setShowAll] = useState(false); // State to toggle between showing all profiles or just a few
  const [showCreateGroup, setShowCreateGroup] = useState(false); // State to toggle between components

  // Settings for react-slick
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Adjust based on needs
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    appendDots: (dots) => (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <button
          className="view-all-button"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "View All"}
        </button>
      </div>
    ),
  };

  // Example student profiles
  const profiles = [
    {
      id: 1,
      name: "John Doe",
      designation: "Student",
      image: "https://i.postimg.cc/HLNbC9cp/Ellipse-2858.png",
    },
    {
      id: 2,
      name: "Jane Smith",
      designation: "Alumni",
      image: "https://i.postimg.cc/63Lj6Pdr/Ellipse-2859.png",
    },
    {
      id: 3,
      name: "Alice Johnson",
      designation: "Student",
      image: "https://i.postimg.cc/NjzZZZ4L/Ellipse-2860.png",
    },
    {
      id: 4,
      name: "Bob Brown",
      designation: "Alumni",
      image: "https://i.postimg.cc/63Lj6Pdr/Ellipse-2859.png",
    },
    {
      id: 5,
      name: "Charlie Davis",
      designation: "Student",
      image: "https://i.postimg.cc/HLNbC9cp/Ellipse-2858.png",
    },
    {
      id: 6,
      name: "Diana Prince",
      designation: "Alumni",
      image: "https://i.postimg.cc/HLNbC9cp/Ellipse-2858.png",
    },
    {
      id: 7,
      name: "Bruce Wayne",
      designation: "Student",
      image: "https://i.postimg.cc/NjzZZZ4L/Ellipse-2860.png",
    },
    {
      id: 9,
      name: "rahul",
      designation: "Alumni",
      image: "https://via.placeholder.com/100",
    },
    {
      id: 10,
      name: "Ram",
      designation: "Alumni",
      image: "https://i.postimg.cc/63Lj6Pdr/Ellipse-2859.png",
    },
    {
      id: 11,
      name: "meena",
      designation: "Alumni",
      image: "https://via.placeholder.com/100",
    },
    {
      id: 12,
      name: "Clark",
      designation: "Alumni",
      image: "https://i.postimg.cc/63Lj6Pdr/Ellipse-2859.png",
    },
    {
      id: 13,
      name: "Kent",
      designation: "Alumni",
      image: "https://i.postimg.cc/NjzZZZ4L/Ellipse-2860.png",
    },
    {
      id: 14,
      name: "Clark Kent",
      designation: "Alumni",
      image: "https://i.postimg.cc/63Lj6Pdr/Ellipse-2859.png",
    },
  ];

  // Show a subset of profiles or all based on the state
  const displayedProfiles = showAll ? profiles : profiles.slice(0, 8);

  const handleCreateGroupClick = () => {
    setShowCreateGroup(true);
  };

  const handleBackClick = () => {
    setShowCreateGroup(false);
  };

  if (showCreateGroup) {
    return <CreateGroup onBack={handleBackClick} />;
  }

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="csd">
            <div className="csd-left">
              <h5>Create Group of Student and Alumni</h5>

              <div className="csd-buttons-container">
                <button>Organization</button>
                <button>Networking</button>
              </div>
              <p>Organize candidates for specific roles</p>
            </div>

            <div className="csd-right">
              <button
                className="csd-create-group"
                onClick={handleCreateGroupClick}
              >
                Create Group
              </button>
            </div>
          </div>

          {/* Carousel Integration */}
          <div className="carousel-container">
            <h5 style={{ padding: "1rem" }}>Current Students</h5>

            <Slider {...settings}>
              {displayedProfiles.map((profile) => (
                <div
                  key={profile.id}
                  id="pofile-card-new-id-uniqe"
                  style={{ display: "grid", alignItems: "center" }}
                  className="profile-card"
                >
                  <img
                    src={profile.image}
                    alt={profile.name}
                    className="profile-image"
                  />
                  <div className="profile-info">
                    <p className="profile-name">{profile.name}</p>
                    <p className="profile-designation">{profile.designation}</p>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="view-all-container">
              <button
                className="view-all-button"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Show Less" : "View All"}
              </button>
            </div>
          </div>

          {/* New Group Images Section */}
          <div className="group-images-section">
            <div className="alumni-network">
              <h5>Alumni Groups</h5>
              <p>Join alumni network to stay connected</p>
            </div>

            <div className="group-images-container">
              <div className="group-image-item">
                <h6>Marketing</h6>
                <a href="/group1" className="group-image-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="group-image-date">
                  {" "}
                  <p>Tech Reunion</p> 01/08/2024
                </p>
              </div>
              <div className="group-image-item">
                <h6>Marketing</h6>
                <a href="/group1" className="group-image-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="group-image-date">
                  {" "}
                  <p>Tech Reunion</p> 01/08/2024
                </p>
              </div>
              <div className="group-image-item">
                <h6>Marketing</h6>
                <a href="/group1" className="group-image-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="group-image-date">
                  {" "}
                  <p>Tech Reunion</p> 01/08/2024
                </p>
              </div>
              {/* ... (more group images) */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CreateGroup({ onBack }) {
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [manager, setManager] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to handle form submission, such as API call
    console.log("Group Created:", {
      groupName,
      description,
      selectedMembers,
      manager,
    });
  };

  const handleCancel = () => {
    // Logic to handle form cancellation, such as navigating away or clearing form fields
    console.log("Form Cancelled");
    setGroupName("");
    setDescription("");
    setSelectedMembers([]);
    setManager("");
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="create-group-page">
        <div className="group-header">
          <button onClick={onBack} className="back-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 12H5m7-7l-7 7 7 7" />
            </svg>
          </button>
          <h5>Create a New Group</h5>
        </div>

        <div className="create-group-container">
          <form onSubmit={handleSubmit} className="create-group-form">
            <div className="form-sections">
              <div className="form-left">
                <div className="form-group">
                  <input
                    type="text"
                    id="group-name"
                    value={groupName}
                    placeholder="Group Name"
                    onChange={(e) => setGroupName(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <select
                    id="members"
                    value={selectedMembers}
                    onChange={(e) => setSelectedMembers(e.target.value)}
                    required
                  >
                    <option value="Member1">Select Group Member</option>
                    <option value="Member2">Ram</option>
                    <option value="Member3">Shyam</option>
                    <option value="Member4">Rahul</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>

              <div className="form-right">
                <div className="form-group">
                  <input
                    id="description"
                    value={description}
                    placeholder="Group Description"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="manager"
                    value={manager}
                    placeholder="Designate Group Manager"
                    onChange={(e) => setManager(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-actions">
              <button type="submit" className="submit-button">
                Create Group
              </button>
              <button
                type="button"
                className="csd-cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>

        <div className="gallery-container">
          <h5>Alumni Network Showcase</h5>
          <div className="gallery-items">
            <div className="gallery-items">
              <div className="gallery-item">
                <a href="/group1" className="gallery-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="gallery-date">Senior Software</p>
              </div>
              <div className="gallery-item">
                <a href="/group1" className="gallery-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="gallery-date">Data Analyst</p>
              </div>
              <div className="gallery-item">
                <a href="/group1" className="gallery-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="gallery-date">Developer at TCS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
