import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AssessmentQuestion() {
  const { categoryId, categoryName } = useParams();
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    question_text: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    correct_option: "",
    hr: userData.id,
    category: categoryName,
  });
  const navigate = useNavigate();

  const token = sessionStorage.getItem("accessToken");
  const modalRef = useRef(null);

  const getQuestionByCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/assessment-questions/`,
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { id: categoryId },
        }
      );

      const matchedCategory = response.data.categories.find(
        (cat) => cat.category.toLowerCase() === categoryName.toLowerCase()
      );

      if (matchedCategory) {
        setQuestions(matchedCategory.questions);
      } else {
        setQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuestionByCategory();
  }, [categoryId, categoryName]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalOpen]);

  const handleAddQuestion = async () => {
    // Validation
    if (
      !newQuestion.question_text ||
      !newQuestion.option_1 ||
      !newQuestion.option_2 ||
      !newQuestion.option_3 ||
      !newQuestion.option_4 ||
      !newQuestion.correct_option
    ) {
      toast.error("All fields are required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/assessment-questions/`,
        newQuestion,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setQuestions([...questions, response.data]);
      setNewQuestion({
        question_text: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        correct_option: "",
        hr: userData.id,
        category: categoryId,
      });
      setModalOpen(false);
      toast.success("Question added successfully!");
    } catch (error) {
      console.error("Error adding question:", error);
      toast.error("Error adding question. Please try again.");
    }
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {/* <ToastContainer /> */}
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <div className="assessment_question_container">
          <div className="assessment_question_title_con">
            <h5 className="mt-2">
              {" "}
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              &nbsp; {}Questions
            </h5>
            <button
              onClick={() => setModalOpen(true)}
              className="add-question-btn"
            >
              Add Question
            </button>
          </div>
          {questions.length > 0 ? (
            questions.map((question, index) => (
              <div
                className="assessment_question_sub_container"
                key={question.id}
              >
                <h6 className="assessment_question">
                  {index + 1}. {question.question_text}
                </h6>
                <ul className="assessment_answer_list">
                  <li className="assessment_answer">(A) {question.option_1}</li>
                  <li className="assessment_answer">(B) {question.option_2}</li>
                  <li className="assessment_answer">(C) {question.option_3}</li>
                  <li className="assessment_answer">(D) {question.option_4}</li>
                  <li className="assessment_correct_answer">
                    Correct Answer:{" "}
                    {question.correct_option === question.option_1
                      ? "(A)"
                      : question.correct_option === question.option_2
                      ? "(B)"
                      : question.correct_option === question.option_3
                      ? "(C)"
                      : "(D)"}
                  </li>
                </ul>
              </div>
            ))
          ) : (
            <div className="no_question_post_yet">
              No questions available for this category.
            </div>
          )}
        </div>
        {/* Modal for adding a new question */}
        {modalOpen && (
          <div className="assessment_modal">
            <div className="assessment_modal_content" ref={modalRef}>
              <input
                type="text"
                placeholder="Question Text"
                value={newQuestion.question_text}
                onChange={(e) =>
                  setNewQuestion({
                    ...newQuestion,
                    question_text: e.target.value,
                  })
                }
              />
              <div className="assessment_question_input">
                <input
                  type="text"
                  placeholder="Option 1"
                  value={newQuestion.option_1}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, option_1: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Option 2"
                  value={newQuestion.option_2}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, option_2: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Option 3"
                  value={newQuestion.option_3}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, option_3: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Option 4"
                  value={newQuestion.option_4}
                  onChange={(e) =>
                    setNewQuestion({ ...newQuestion, option_4: e.target.value })
                  }
                />
              </div>

              <input
                type="text"
                placeholder="Correct Answer"
                value={newQuestion.correct_option}
                onChange={(e) =>
                  setNewQuestion({
                    ...newQuestion,
                    correct_option: e.target.value,
                  })
                }
              />
              <button
                className="submit_add_qustion_btn"
                onClick={handleAddQuestion}
              >
                Add Question
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
