import { useState, useEffect } from "react";
import { Pagination } from "@mui/material";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import searchIconDash from "../../../../utils/dashboards/searchIconDash.svg";
import leftArrow from "../../../../utils/dashboards/leftarrow.svg";
import "../../../../styles/dashboards/hrdashboard/circulateJob.css";
import "../../../../styles/dashboards/hrdashboard/postjobs.css";

export default function CirculateCollegeList() {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const roleName = JSON.parse(sessionStorage.getItem("userRoleName"));
  const [currentPage, setCurrentPage] = useState(1);
  const token = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [circulateJobData, setCirculateJobData] = useState({});
  const [sharedColleges, setSharedColleges] = useState([]);
  const clgPerPage = 4;
  const base_Url = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobData = async () => {
      setLoading(true);
      try {
        const job = JSON.parse(localStorage.getItem("seecirculatedjobdetails"));
        setCirculateJobData(job);

        if (!job) {
          Swal.fire("Error!", "Job data not found", "error");
          return;
        }

        const response = await axios.get(
          `${base_Url}/api/job/${job.job_id}/shared-colleges/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("shared-colleges", response.data);
        setSharedColleges(response.data.shared_colleges); // Set shared colleges
      } catch (error) {
        Swal.fire("Error!", "Unable to fetch job data", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [base_Url, token]);

  // Pagination Logic
  const indexOfLastClg = currentPage * clgPerPage;
  const indexOfFirstClg = indexOfLastClg - clgPerPage;
  const currentColleges = sharedColleges.slice(indexOfFirstClg, indexOfLastClg); // Slice the college data for pagination

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <div className="d-flex flex-column p-4 gap-4">
          <div className="d-flex align-items-center">
            <img
              onClick={handleNavigate}
              src={leftArrow}
              style={{
                paddingRight: "1rem",
                height: "18px",
                cursor: "pointer",
              }}
              alt="Back"
            />
            <p>Selected Colleges for Current Job</p>
          </div>
          <CirculateJobDetails circulateJobData={circulateJobData} />
          <div className="circulate_job_list_container">
            <div className="d-flex justify-content-between align-items-center ">
              <h6>Select Colleges</h6>
            </div>
            <div className="circulate_job_container">
              <div className="circulate_job_list_container">
                {currentColleges.map((college) => (
                  <div className="circulate_job_card" key={college.id}>
                    <div className="circulate_job_card_content">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="circulate_job_image_container">
                          <img
                            src={
                              college.college_logo ||
                              "https://via.placeholder.com/150"
                            }
                            alt={`${"Clg"} logo`}
                            className="circulate_job_image"
                          />
                        </div>
                        <div className="circulate_job_details">
                          <h4 className="circulate_job_college_name">
                            <span>
                              {college.college_api.college_name?.slice(0, 35) ||
                                "Unnamed College"}
                              {college.college_api.college_name?.length > 35
                                ? "..."
                                : ""}
                            </span>
                          </h4>
                          <p className="circulate_job_location">
                            {college?.college_api?.district_name}{" "}
                            {college?.college_api?.state_name}
                          </p>
                        </div>
                      </div>
                      <div className="circulate_job_info_link">
                        <Link
                          to={`/dashboard/sidebar-page/${roleName}/college-details/${college.id}`}
                        >
                          More Info
                        </Link>
                        <br />
                        <Link
                          to={`/dashboard/sidebar-page/${roleName}/applied-student-of-college/${college.id}/${circulateJobData.job_id}/${circulateJobData.job_title}`}
                        >
                          See Applications
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
                {/* Pagination */}
                <div className="d-flex justify-content-center align-items-center">
                  <Pagination
                    count={Math.ceil(sharedColleges.length / clgPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    className="mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const CirculateJobDetails = ({ circulateJobData }) => {
  return (
    <div className="circulate_job_detail">
      <ul>
        <li>{circulateJobData?.job_title || "No Job Title"}</li>
        <li>
          <span className={`status ${circulateJobData?.status?.toLowerCase()}`}>
            {circulateJobData?.job_status}
          </span>
        </li>
        <li>
          {new Date(circulateJobData?.date_posted).toLocaleDateString() ||
            "N/A"}
        </li>
        <li>
          {new Date(circulateJobData?.due_date).toLocaleDateString() || "N/A"}
        </li>
        <li>
          <p className="circulate_job_type">
            {circulateJobData?.job_type || "N/A"}
          </p>
        </li>
        <li>
          {circulateJobData?.min_salary} to {circulateJobData?.max_salary} lpa
        </li>
        <li>{circulateJobData?.vacancy}</li>
      </ul>
    </div>
  );
};
