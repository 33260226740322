import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import '../.../../../../../styles/dashboards/clgstudent/studentprofile.css'
import PersonalInformation from './student_Personal_Info';
import StudentQualification from './student_qualification_info';
import StudentCertificationInfo from './student_certification_info';
import { fetchStudentProfileCompletion } from '../../../../redux/actions/student_profilePercentage';
import { useSelector, useDispatch } from 'react-redux';
import StudentSkillInfo from './student_skill_info';
import StudentExperiance from './student_experiance';
import Instagram from "../../../../utils/landingpage/images/blackinstgram.svg"
import Twitter from "../../../../utils/landingpage/images/blacktwiter.svg";
import LinkedIn from "../../../../utils/landingpage/images/blacklinkedin.svg";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import { setGetStudentProfileData } from '../../../../redux/reducers/student_profile_reducer';
import { fetchStudentProfileData } from '../../../../redux/actions/student_profile_action';
import { setIsAlreadySocialDataAvailable } from '../../../../redux/reducers/student_profile_reducer';
import { setShowSocialMediaRedux } from '../../../../redux/reducers/student_profile_reducer';

const StudentProfile = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [isSocialMediaOpenModel, setIsSocialMediaOpenModel] = useState(false)
  const [updatedData, setUpdatedData] = useState([]);
  const [editingValue, setEditingValue] = useState([]);

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  })

  const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
  const editSocialMediaDataReduxs = useSelector((state) => state.studentProfile.editSocialMediaDataRedux);
  const showSocialMediaReduxs = useSelector((state) => state.studentProfile.showSocialMediaRedux);
  const isAlreadySocialDataAvailables = useSelector((state) => state.studentProfile.isAlreadySocialDataAvailable);

  const dispatch = useDispatch()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };


  useEffect(() => {
    dispatch(fetchStudentProfileCompletion());
  }, [dispatch])

  const UserPercenatageValue = useSelector((state) => state.studentProfilePercentage.studentProfilePercentageValue)

  const remainingCompletion = 100 - (UserPercenatageValue || 0);

  const pieChartData = {
    datasets: [
      {
        data: [UserPercenatageValue || 0, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  const handleEditSocialMedia = (link) => {
    setIsSocialMediaOpenModel(true);
    // setIsAlreadySocialData(true);
    dispatch(setIsAlreadySocialDataAvailable(true))
    setEditingValue(link)
    // setShowSocialMedia(false);
    dispatch(setShowSocialMediaRedux(false))
  }

  const handleAddSocialMedia = async (e) => {
    e.preventDefault();

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    if (!formData.instagram && !formData.twitter && !formData.linkedin) {
      toast.error("Please add at least one social media link");
      return;
    }

    const payload = {
      social_media_links: [
        { platform: "instagram", url: formData.instagram || '' },
        { platform: "twitter", url: formData.twitter || '' },
        { platform: "linkedin", url: formData.linkedin || '' },
      ].filter((link) => link.url)
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Added Successfully");
        setUpdatedData(response.data)
        dispatch(setGetStudentProfileData(response.data))
        // setIsAlreadySocialData(true);
        dispatch(setIsAlreadySocialDataAvailable(true))
        setIsSocialMediaOpenModel(false);
        // setShowSocialMedia(true);
        dispatch(setShowSocialMediaRedux(true))
      }
    } catch (error) {
      console.error("Error in API:", error);
    }
  };

  const handleEditSocialMediaCancel = () => {
    dispatch(setIsAlreadySocialDataAvailable(true));
    setIsSocialMediaOpenModel(false);
    dispatch(setShowSocialMediaRedux(true))
  }


  const handleEditSocialMediaSubmit = async () => {

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData.id;

    const socialMediaLinks = [
      {
        platform: "instagram",
        url: formData.instagram || NewupdatedData.social_media_links?.find(link => link.platform === "instagram")?.url || "",
      },
      {
        platform: "twitter",
        url: formData.twitter || NewupdatedData.social_media_links?.find(link => link.platform === "twitter")?.url || "",
      },
      {
        platform: "linkedin",
        url: formData.linkedin || NewupdatedData.social_media_links?.find(link => link.platform === "linkedin")?.url || "",
      }
    ].filter(link => link.url);

    const payload = {
      social_media_links: socialMediaLinks
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Social media links updated successfully");
        // fetchData();
        dispatch(fetchStudentProfileData())
        setIsSocialMediaOpenModel(false);
        // setIsAlreadySocialData(true);
        dispatch(setIsAlreadySocialDataAvailable(true))
        // showSocialMedia(true);
        dispatch(setShowSocialMediaRedux(false))
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleDeleteSocialMedia = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/socialmedialinks/${id}/`,
        config
      );

      if (response.status) {
        toast.success("Deleted Successfully");
        // fetchData();
        dispatch(fetchStudentProfileData())
        // setIsAlreadySocialData(true);
        // setIsSocialMediaOpenModel(false)
      }
    } catch (error) {
      console.error("Error in API:", error);
      toast.error(error.response?.data?.message || "Failed to delete social media link");
    }
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">

        <ul className="student_campus_profile_tabs">
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'personal' ? 'active' : ''}`}
              onClick={() => setActiveTab('personal')}
            >
              Personal Information
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'qualifications' ? 'active' : ''}`}
              onClick={() => setActiveTab('qualifications')}
            >
              Qualifications
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'certifications' ? 'active' : ''}`}
              onClick={() => setActiveTab('certifications')}
            >
              Certifications
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'skills' ? 'active' : ''}`}
              onClick={() => setActiveTab('skills')}
            >
              Skills
            </button>
          </li>
          <li className="student_campus_profile_tab_item">
            <button
              className={`student_campus_profile_tab_link ${activeTab === 'experience' ? 'active' : ''}`}
              onClick={() => setActiveTab('experience')}
            >
              Internship/Work Experience
            </button>
          </li>
        </ul>

        <div className='d-flex justify-content-between align-items-start'>
          <div className="student_campus_profile_card">
            <div className="student_campus_profile_card_body">
              {activeTab === 'personal' && (<>
                <PersonalInformation />
              </>)}

              {activeTab === 'qualifications' && (
                <StudentQualification />
              )}

              {activeTab === 'certifications' && (
                <StudentCertificationInfo />
              )}

              {activeTab === 'skills' && (
                <StudentSkillInfo />
              )}

              {activeTab === 'experience' && (
                <StudentExperiance />
              )}
            </div>
          </div>

          <div style={{ width: "28%" }}>
            <div className='student_campus_profile_social'>
              <p>Complete Your Profile</p>
              <div className="mr-4 epf-chart-wrapper">
                <div className="epf-chart-container student_campus_canvas_container">
                  <Pie data={pieChartData} options={pieChartOptions} />
                  <div className='student_campus_canvas_percentage_value'>{`${UserPercenatageValue}%` || "0%"}</div>
                </div>
              </div>
            </div>
            <br />
            <div className="student_campus_profile_social new_student_campus_profile_media">
              <div className="">
                <div className="social_Sites_Innerdiv">
                  <div className="sites_topView pb-4">
                    <p>{isSocialMediaOpenModel ? `Edit Social Media Links` : `Social Media Links`}</p>
                  </div>
                  <div className="sites_downview">
                    <div className="sites_down_innerview">

                      {isSocialMediaOpenModel === true && isAlreadySocialDataAvailables === true && showSocialMediaReduxs === false && (<>

                        <div className="sites_icon campus_student_sites_icon">
                          <div className="images">
                            <img src={Instagram} alt="Icon" />
                          </div>
                          <div style={{ width: "100%" }}>
                            <span>Instagram</span>
                            <br />
                            <input
                              type="text"
                              name="instagram"
                              value={formData.instagram || editSocialMediaDataReduxs?.instagram}
                              onChange={handleInputChange}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                          </div>
                        </div>
                        <div className="sites_icon campus_student_sites_icon mt-2">
                          <div className="images">
                            <img src={Twitter} alt="Twitter" />
                          </div>
                          <div style={{ width: "100%" }}>
                            <span>Twitter</span>
                            <br />
                            <input
                              type="text"
                              name="twitter"
                              value={formData.twitter || editSocialMediaDataReduxs?.twitter}
                              onChange={handleInputChange}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                          </div>
                        </div>
                        <div className="sites_icon campus_student_sites_icon mt-2">
                          <div className="images">
                            <img src={LinkedIn} alt="LinkedIn" />
                          </div>
                          <div style={{ width: "100%" }}>
                            <span>LinkedIn</span>
                            <br />
                            <input
                              type="text"
                              name="linkedin"
                              value={formData.linkedin || editSocialMediaDataReduxs?.linkedin}
                              onChange={handleInputChange}
                              className="epf-input-field student_epf_input_fields_new"
                            />
                          </div>
                        </div>

                        <div className="pt-4 d-flex gap-4" style={{paddingLeft: "2.5rem"}}>
                          <button onClick={handleEditSocialMediaSubmit} className="student_epf_submit_bttn mt-0">
                            Save
                          </button>
                          <button
                            onClick={handleEditSocialMediaCancel}
                            className="student_epf_submit_bttn mt-0"
                            style={{
                              background: "transparent",
                              border: "1px solid rgba(243, 141, 0, 1)",
                              color: "rgba(243, 141, 0, 1)",
                            }}
                          >
                            Cancel
                          </button>
                        </div>

                      </>)}

                      {(isSocialMediaOpenModel === false && isAlreadySocialDataAvailables === false) && (
                        <>
                          <div className="sites_icon campus_student_sites_icon">
                            <div className="images">
                              <img src={Instagram} alt="icon" />
                            </div>
                            <div style={{ width: "100%" }}>
                              <span>Instagram</span>
                              <br />
                              <input
                                type="text"
                                name="instagram"
                                value={formData.instagram}
                                onChange={handleInputChange}
                                className="epf-input-field student_epf_input_fields_new"
                              />
                            </div>
                          </div>
                          <div className="sites_icon campus_student_sites_icon mt-2">
                            <div className="images">
                              <img src={Twitter} alt="icon" />
                            </div>
                            <div style={{ width: "100%" }}>
                              <span>Twitter</span>
                              <br />
                              <input
                                type="text"
                                name="twitter"
                                value={formData.twitter}
                                onChange={handleInputChange}
                                className="epf-input-field student_epf_input_fields_new"
                              />
                            </div>
                          </div>
                          <div className="sites_icon campus_student_sites_icon mt-2">
                            <div className="images">
                              <img src={LinkedIn} alt="icon" />
                            </div>
                            <div style={{ width: "100%" }}>
                              <span>LinkedIn</span>
                              <br />
                              <input
                                type="text"
                                name="linkedin"
                                value={formData.linkedin}
                                onChange={handleInputChange}
                                className="epf-input-field student_epf_input_fields_new"
                              />
                            </div>
                          </div>
                          <div className="pt-4" style={{ paddingLeft: "33px" }}>
                            <button onClick={handleAddSocialMedia} className=" student_epf_submit_bttn mt-0">
                              Save
                            </button>
                          </div>
                        </>
                      )}

                      {(showSocialMediaReduxs === true && isSocialMediaOpenModel === false && isAlreadySocialDataAvailables === true) && (
                        <>
                          {NewupdatedData?.social_media_links
                            ?.filter((link) => link.url)
                            .map((link) => (
                              <div key={link.id} className="sites_icon student_sites_icon">
                                <div className="d-flex align-items-start">
                                  <div className="images">
                                    <img
                                      src={
                                        link.platform === "instagram"
                                          ? Instagram
                                          : link.platform === "twitter"
                                            ? Twitter
                                            : link.platform === "linkedin"
                                              ? LinkedIn
                                              : ""
                                      }
                                      alt='icon'
                                    />
                                  </div>
                                  <div>
                                    <span>{link.platform.charAt(0).toUpperCase() + link.platform.slice(1)}</span>
                                    <br />
                                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                                      {link.url}
                                    </a>
                                  </div>
                                </div>
                                <div style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "10px", paddingLeft: "3rem" }}>
                                  <img onClick={() => handleEditSocialMedia(link)} src={editIcon} alt="Edit" style={{ width: "16px" }} />
                                  <div onClick={() => handleDeleteSocialMedia(link.id)}>
                                    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                                      <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                                      <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                                      <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                                      <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <h3 className="student_campus_profile_social_title">
                Social Media Links
                <i className="bi bi-box-arrow-up-right student_campus_profile_social_icon"></i>
              </h3>
              <div className="student_campus_profile_social_list">
                {[
                  { platform: 'Instagram', url: 'instagram.com/username', icon: 'bi-instagram' },
                  { platform: 'Twitter', url: 'twitter.com/username', icon: 'bi-twitter' },
                  { platform: 'LinkedIn', url: 'linkedin.com/in/username', icon: 'bi-linkedin' }
                ].map((social) => (
                  <div key={social.platform} className="student_campus_profile_social_item">
                    <div className="student_campus_profile_social_link">
                      <i className={`bi ${social.icon} student_campus_profile_social_platform_icon`}></i>
                      <span className="student_campus_profile_social_url">{social.url}</span>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>

      </div>

    </div >
  );
};

export default StudentProfile;