import React from "react";

function UserManagement() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">UserManagement</div>
    </div>
  );
}

export default UserManagement;
