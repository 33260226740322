import React from "react";

function AdminAssessment() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        AdminAssessment
      </div>
    </div>
  );
}

export default AdminAssessment;
