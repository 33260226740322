import React, { useState, useEffect } from "react";
import "../../../../styles/dashboards/hrdashboard/hrprofile.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  fetchHrProfileData,
  updateHrProfileData,
} from "../../../../redux/actions/hr_profile_action";
import { toast } from "react-toastify";
import editIcon from "../../../../utils/dashboards/edit-icon.svg";
import editrevertIcon from "../../../../utils/dashboards/edit-revert.svg";
import {
  SetIsLoading,
  setIsEditModelOpen,
  setIsSubmitted,
} from "../../../../redux/reducers/hrProfileReducer";
function HeadQuarterAddress() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.hrProfile);
  const reduxProfileData = useSelector(
    (state) => state.hrProfile.getHrProfileData
  );

  const [profileCreationData, setProfileCreationData] = useState(() => ({
    company_name: reduxProfileData?.company_name || "",
  }));

  // State for validation errors
  const [errors, setErrors] = useState({
    street: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    headquarter_contact: "",
    company_website: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileCreationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleValidation = () => {
    let formErrors = {};
    if (!profileCreationData.street) formErrors.street = "Street is required";
    if (!profileCreationData.city) formErrors.city = "City is required";
    if (!profileCreationData.state) formErrors.state = "State is required";
    if (!profileCreationData.country)
      formErrors.country = "Country is required";
    if (!profileCreationData.zip_code)
      formErrors.zip_code = "Zip Code is required";
    if (!profileCreationData.headquarter_contact)
      formErrors.headquarter_contact = "Headquarter Contact is required";
    if (!profileCreationData.company_website)
      formErrors.company_website = "Website URL is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = () => {
    const isValid = handleValidation();
    if (isValid) {
      dispatch(updateHrProfileData(profileCreationData, dispatch));
    }
  };

  useEffect(() => {
    dispatch(fetchHrProfileData());
  }, [dispatch]);

  useEffect(() => {
    if (profileData?.getHrProfileData) {
      const profile = profileData.getHrProfileData;
      setProfileCreationData((prevData) => ({
        ...prevData,
        street: profile?.street,
        city: profile?.city,
        state: profile?.state,
        country: profile?.country,
        zip_code: profile?.zip_code,
        headquarter_contact: profile?.headquarter_contact,
        company_email: profile.company_email,
        company_website: profile.company_website,
      }));
    }
  }, [profileData]);

  const handleEditClick = () => {
    dispatch(setIsEditModelOpen(!profileData.isEditModelOpen));
  };

  return (
    <div className="hr_Profile_creation_container">
      {profileData.isSubmitted && (
        <>
          {" "}
          <div>
            <span
              onClick={handleEditClick}
              style={{
                cursor: "pointer",
                float: "right",
                marginRight: "13px",
              }}
            >
              {" "}
              {profileData.isEditModelOpen ? "Edit Details     " : ""}
              <img
                src={profileData.isEditModelOpen ? editIcon : editrevertIcon}
                alt="Edit"
              />{" "}
            </span>
          </div>
        </>
      )}

      {profileData.isEditModelOpen ? (
        <>
          <div className="hr_profile_creation_details_container">
            <div className="hr_profile_creation_details_first_section">
              <div className="details_row">
                <label>Street</label>{" "}
                <p>{profileCreationData?.street || "Not Mentioned"}</p>
              </div>
              <div className="details_row">
                <label>City</label>{" "}
                <p>{profileCreationData?.city || "Not Mentioned"}</p>
              </div>
              <div className="details_row">
                <label>State</label>{" "}
                <p>{profileCreationData?.state || "Not Mentioned"}</p>
              </div>
              <div className="details_row">
                <label>Country</label>{" "}
                <p>{profileCreationData?.country || "Not Mentioned"}</p>
              </div>
            </div>
            <div className="details_gst">
              <label>Zip Code</label>{" "}
              <p>{profileCreationData?.zip_code || "Not Mentioned"}</p>
            </div>
            <div className="details_gst">
              <label>Headquarter Number</label>{" "}
              <p>
                {profileCreationData?.headquarter_contact || "Not Mentioned"}
              </p>
            </div>
            <div className="details_gst">
              <label>Email Address</label>{" "}
              <p>{profileCreationData?.company_email || "Not Mentioned"}</p>
            </div>
            <div className="details_gst">
              <label>Website URL</label>{" "}
              <p>{profileCreationData?.company_website || "Not Mentioned"}</p>
            </div>
          </div>
        </>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <>
            <div className="hr_profile_creation_details_container">
              <h6 className="additional_details_header">Address Details</h6>
              <div className="hr_profile_creation_details_first_section">
                <div className="details_row">
                  <label>Street</label>{" "}
                  <input
                    type="text"
                    value={profileCreationData?.street || ""}
                    name="street"
                    onChange={handleInputChange}
                  />
                  {errors.street && (
                    <span className="hrProfile_error">{errors.street}</span>
                  )}
                </div>
                <div className="details_row">
                  <label>City</label>{" "}
                  <input
                    type="text"
                    value={profileCreationData?.city || ""}
                    name="city"
                    onChange={handleInputChange}
                  />
                  {errors.city && (
                    <span className="hrProfile_error">{errors.city}</span>
                  )}
                </div>
                <div className="details_row">
                  <label>State</label>
                  <input
                    type="text"
                    value={profileCreationData?.state || ""}
                    name="state"
                    onChange={handleInputChange}
                  />
                  {errors.state && (
                    <span className="hrProfile_error">{errors.state}</span>
                  )}
                </div>
                <div className="details_row">
                  <label>Country</label>{" "}
                  <input
                    type="tel"
                    value={profileCreationData?.country}
                    name="country"
                    onChange={handleInputChange}
                  />
                  {errors.country && (
                    <span className="hrProfile_error">{errors.country}</span>
                  )}
                </div>
              </div>

              <div className="hr_profile_creation_details_first_section">
                <div className="details_row">
                  <label>Zip Code</label>{" "}
                  <input
                    type="text"
                    value={profileCreationData?.zip_code || ""}
                    name="zip_code"
                    onChange={handleInputChange}
                  />
                  {errors.zip_code && (
                    <span className="hrProfile_error">{errors.zip_code}</span>
                  )}
                </div>
                <div className="details_row">
                  <label>Headquarter Contact Number</label>{" "}
                  <input
                    type="text"
                    value={profileCreationData?.headquarter_contact || ""}
                    name="headquarter_contact"
                    onChange={handleInputChange}
                  />
                  {errors.headquarter_contact && (
                    <span className="hrProfile_error">
                      {errors.headquarter_contact}
                    </span>
                  )}
                </div>
                <div className="details_row">
                  <label>Email Address</label>{" "}
                  <input
                    type="text"
                    disabled
                    style={{ cursor: "not-allowed" }}
                    value={profileCreationData?.company_email || ""}
                    name="company_email"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="details_row">
                  <label>Website URL</label>{" "}
                  <input
                    type="tel"
                    value={profileCreationData?.company_website}
                    name="company_website"
                    onChange={handleInputChange}
                  />
                  {errors.company_website && (
                    <span className="hrProfile_error">
                      {errors.company_website}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="details_row">
              <button type="submit">
                {profileData?.isLoading ? (
                  <div className="loader"></div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </>
        </form>
      )}
    </div>
  );
}

export default HeadQuarterAddress;
