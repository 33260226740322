export default function StudentMessages() {
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <h26>StudentMessages</h26>
        </div>
      </div>
    </>
  );
}
