import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

function MySchedule() {
  const navigate = useNavigate();

  // State for tracking the hovered event
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0 });

  // Static events data
  const events = [
    {
      title: "Team Meeting",
      start: new Date(2024, 11, 1, 10, 0, 0),
      end: new Date(2024, 11, 1, 11, 0, 0),
      allDay: false,
    },
    {
      title: "Admin ",
      start: new Date(2024, 11, 2, 14, 0, 0),
      end: new Date(2024, 11, 2, 16, 0, 0),
      allDay: false,
    },
    {
      title: "Holiday Party",
      start: new Date(2024, 11, 25),
      end: new Date(2024, 11, 25),
      allDay: true,
    },
  ];

  // Handle event hover and card positioning
  const handleEventHover = (event, e) => {
    setHoveredEvent(event);
    const boundingRect = e.target.getBoundingClientRect();
    setCardPosition({
      top: boundingRect.top + window.scrollY + 20,
      left: boundingRect.left + window.scrollX + 20,
    });
  };

  // Clear hovered event when mouse leaves
  const handleMouseLeave = () => {
    setHoveredEvent(null);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <h6>
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <path
              d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
              fill="#222222"
            />
            <path
              d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
              fill="#222222"
            />
          </svg>{" "}
          &nbsp; My Schedule
        </h6>
        <div className="">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{
              height: 500,
              margin: "20px",
              background: "#fff",
              padding: "1rem",
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
              fontFamily: "Poppins, sans-serif",
            }}
            components={{
              event: ({ event }) => (
                <span
                  onMouseEnter={(e) => handleEventHover(event, e)}
                  onMouseLeave={handleMouseLeave}
                >
                  {event.title}
                </span>
              ),
            }}
          />
        </div>
        {hoveredEvent && (
          <div
            style={{
              position: "absolute",
              top: `${cardPosition.top}px`,
              left: `${cardPosition.left}px`,
              background: "#f8f9fa",
              border: "1px solid #ddd",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
              zIndex: 1000,
            }}
          >
            <p>
              <strong>Title:</strong> {hoveredEvent.title}
            </p>
            <p>
              <b>Start:</b>{" "}
              {moment(hoveredEvent.start).format("MMMM Do YYYY, h:mm a")}
            </p>
            <p>
              <strong>End:</strong>{" "}
              {moment(hoveredEvent.end).format("MMMM Do YYYY, h:mm a")}
            </p>
            <p>{hoveredEvent.allDay ? "All Day Event" : "Timed Event"}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MySchedule;
