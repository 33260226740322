import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../../styles/settings/setting.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Setting = () => {

  const [notificationToggle, setNotificationToggle] = useState(false);
  const [inputFields, setInputFields] = useState({
    old_password: "",
    password: "",
    password2: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [enterOTP, setEnterOTP] = useState(false)
  const [otp, setOtp] = useState('')

  const handleToggleChange = () => {
    setNotificationToggle(!notificationToggle);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!inputFields.old_password) {
      errors.old_password = "Old password is required";
    }
    if (!inputFields.password) {
      errors.password = "New password is required";
    } else if (inputFields.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }
    if (inputFields.password2 !== inputFields.password) {
      errors.password2 = "Passwords must match";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();

    const accessToken = sessionStorage.getItem('accessToken');

    setPasswordLoading(true)

    if (Object.keys(validationErrors).length === 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/changepassword/`, inputFields, config)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message || "Password changed successfully!",
          });
          setError({});
          setPasswordLoading(false)
        })
        .catch((error) => {
          if (error.response?.data?.non_field_errors) {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error.response.data.non_field_errors[0] || "An error occurred.",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: error.response?.data?.message || "Failed to change password.",
            });
          }
          setPasswordLoading(false)
        });
    } else {
      setError(validationErrors);
      setPasswordLoading(false)
    }
  };

  const handleChangeOTP = (event) => {
    setOtp(event.target.value)
  }

  const handleSubmitOTP = async () => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');

      if (!accessToken) {
        console.error('No access token found');
        return;
      }

      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/change-email-verify-otp/`,
        { otp },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
        }
      );

      Swal.fire({
        title: "OTP Verified!",
        text: "Your email change has been verified successfully.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setEnterOTP(false);
      setOtp('')
    } catch (error) {
      let errorMessage = "Something went wrong!";
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }

      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setOtp('')
    } finally {
      setLoading(false);
    }
  };


  const handleEmailChange = async () => {
    setLoading(true)

    try {
      const accessToken = sessionStorage.getItem('accessToken');

      if (!accessToken) {
        console.error('No access token found');
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/update-email/`,
        { email },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      let scuccesMessage = "OTP has been sent to your register email."

      if (response && response.data && response.data.msg) {
        scuccesMessage = response.data.msg;
      }

      setEnterOTP(true)

      Swal.fire({
        title: "OTP Send!",
        text: scuccesMessage,
        icon: "success",
        timer: 3500,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });

      setLoading(false)

    } catch (error) {
      let errorMessage = "Something went wrong!";

      if (error.response && error.response.data && error.response.data.email) {
        errorMessage = error.response.data.email[0]; // Accessing the error message from the response
      }

      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });

      setLoading(false)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="setting_sec">
      <div className="setting_act-container">
        <div className="setting_act_set">
          <h5>Account Setting</h5>
        </div>

        <div className="setting_main_div">
          <div className="setting_Bas_info">
            <div className="setting_Bas_Div">
              <h6>Basic Information</h6>
              <span>Change your email, mobile number or password.</span>
            </div>
            <div className="setting_sqbox">

              {enterOTP === false ? (
                <div className="setting_sqbox-1">
                  <div className="setting_info">
                    <h6>Change Email</h6>
                    <span>
                      We will send you a verification email on this email ID.
                    </span>
                  </div>
                  <div className="setting_Input">
                    <input
                      type="email"
                      className="setting_textarea"
                      placeholder="Enter Email"
                      value={email}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="setting_btn">
                    <div>
                      {loading ? <div className="spinner"></div> : <div onClick={handleEmailChange} className="setting_custom-btn setting_btn-1 setting_email_register" >Send</div>}
                    </div>
                    <div>
                      <button onClick={() => setEmail('')} className="setting_custom-btn setting_btn-2">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>) :
                (<div className="setting_sqbox-1">
                  <div className="setting_info">
                    <h6>Enter OTP</h6>
                  </div>
                  <div className="setting_Input">
                    <input
                      type="number"
                      className="setting_textarea"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleChangeOTP}
                    />
                  </div>
                  <div className="setting_btn">
                    <div>
                      {loading ? <div className="spinner"></div> : <div onClick={handleSubmitOTP} className="setting_custom-btn setting_btn-1 setting_email_register" >Send</div>}
                    </div>
                    <div>
                      <button onClick={() => setEnterOTP(false)} className="setting_custom-btn setting_btn-2">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>)
              }

              {/* <div className="setting_sqbox-1">
                <div className="setting_info">
                  <span>Mobile Number</span>
                </div>
                <div className="setting_Input">
                  <input
                    type="tel"
                    className="setting_textarea"
                    placeholder="Enter Mobile Number"
                    value={inputFeild}
                      autoComplete="off"
                    onChange={handleChange}
                  />
                </div>
                <div className="setting_btn">
                  <div>
                    <Link>
                      {" "}
                      <button className="setting_custom-btn setting_btn-1 setting_otp_btn">
                        Get OTP
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link>
                      {" "}
                      <button className="setting_custom-btn setting_btn-2">
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* Squre box3 */}
              <div className="setting_sqbox-1">
                <div className="setting_info">
                  <h6>Password</h6>
                  <span>
                    {`Your password should be at least 5 characters long and contain alphanumeric characters (a-z and 0-9)`}
                  </span>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="setting_Input">
                    <input
                      type="password"
                      name="old_password"
                      className="setting_textarea"
                      placeholder="Enter your current password"
                      autoComplete="off"
                      value={inputFields.old_password}
                      onChange={handlePasswordChange}
                    />
                    {error.old_password && <div className="error">{error.old_password}</div>}

                    <div className="setting_new_pass">
                      <input
                        type="password"
                        name="password"
                        className="setting_pass"
                        placeholder="Enter a new password"
                        value={inputFields.password}
                        onChange={handlePasswordChange}
                      />
                      {error.password && <div className="error">{error.password}</div>}

                      <input
                        type="password"
                        name="password2"
                        className="setting_pass"
                        placeholder="Confirm password"
                        value={inputFields.password2}
                        onChange={handlePasswordChange}
                      />
                      {error.password2 && <div className="error">{error.password2}</div>}
                    </div>
                  </div>

                  <div className="setting_btn">
                    <div>
                      <button
                        type="submit"
                        className="setting_custom-btn setting_btn-1 setting_otp_btn"
                      >
                        {passwordLoading ? <div className="spinner"></div> : 'Submit'}
                      </button>
                    </div>
                    <div>
                      <button className="setting_custom-btn setting_btn-2">Cancel</button>
                    </div>
                  </div>

                  {message && <p>{message}</p>}
                </form>
              </div>

              {/* Squre box4 */}
              <div className="setting_sqbox-1 setting_Notification">
                <div className="setting_info">
                  <h6>Notification</h6>
                  <span>
                    This is your personal information that you can update anytime
                  </span>
                </div>
                <div className="setting_toggle">
                  <input
                    type="checkbox"
                    id="mode-toggle"
                    className="setting_toggle__input"
                    checked={notificationToggle}
                    onChange={handleToggleChange}
                  />
                  <label
                    htmlFor="mode-toggle"
                    className="setting_toggle__label"
                  ></label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> 
    </section>
  );
};

export default Setting;