import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../styles/dashboards/hrdashboard/viewappllications.css";
import Pagination from "@mui/material/Pagination";
import "../../../../styles/dashboards/hrdashboard/campuseinterview.css";
import searchIconDash from "../../../../utils/dashboards/searchIconDash.svg";
import axios from "axios";
import filterIcon from "../../../../utils/dashboards/filterIcon.svg";
import filterIcondrop from "../../../../utils/dashboards/filterarrowdropdown.svg";
import "../../../../styles/dashboards/hrdashboard/viewappllications.css";
import { toast } from "react-toastify";

export default function CandidateOfColleges() {
  const navigate = useNavigate();
  const roleName = JSON.parse(sessionStorage.getItem("userRoleName"));
  const { jobName, collegeId, jobId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [status, setStatus] = useState("interviewed"); // default status
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const token = sessionStorage.getItem("accessToken");
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const circulateJobData = JSON.parse(
    localStorage.getItem("seecirculatedjobdetails")
  );

  const candidateSelection = [
    { value: "in_review", label: "In-review " },
    { value: "interviewed", label: "Interviewed" },
    { value: "Shortlisted", label: "Shortlisted" },
    { value: "discarded", label: "Declined" },
    { value: "offered", label: "Offered" },
  ];

  const getCandidates = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/hr/jobs/colleges/all_status_applications/?college_id=${collegeId}&job_id=${jobId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCandidates(response.data.students);
    } catch (error) {
      console.error("Error fetching candidates:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCandidates();
  }, [collegeId, jobId, token]);

  const handleCheckboxChange = (event, candidateId) => {
    if (event.target.checked) {
      setSelectedCandidates([...selectedCandidates, candidateId]);
    } else {
      setSelectedCandidates(
        selectedCandidates.filter((id) => id !== candidateId)
      );
    }
  };

  const handleStatusChange = async () => {
    try {
      setStatusLoading(true);
      if (selectedCandidates.length === 0) {
        toast.error("Please select at least one candidate.");
        return;
      }
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        {
          application_ids: selectedCandidates,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Status updated successfully.");
      getCandidates();
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    } finally {
      setStatusLoading(false);
    }
  };

  const handleSeeCandidate = () => {
    navigate(
      `/dashboard/sidebar-page/${roleName}/collage-applicants/${collegeId}/${circulateJobData?.job_id}/${circulateJobData?.no_of_interview_rounds}`
    );
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="view_application_main_container">
          <h6>
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; {jobName} Applied Candidate By College
          </h6>
          <div className="d-flex gap-4 align-items-center">
            <h6>Total : {candidates.length}</h6>
          </div>
        </div>
        <div className="campuse_std_job_applications-container">
          <div className="d-flex gap-1  ">
            {selectedCandidates.length !== 0 && (
              <>
                <div>
                  <select
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                    className="campus_interview_schedule_change_status_drop_down"
                  >
                    {candidateSelection.map((option) => (
                      <option key={option.value} value={option.value}>
                        <span>
                          <img src={filterIcon} alt="Filter Icon" />
                        </span>
                        {option.label}
                        <span>
                          <img src={filterIcondrop} alt="Filter Icon Drop" />
                        </span>
                      </option>
                    ))}
                  </select>
                </div>

                <>
                  {" "}
                  <button
                    className="campuse_std_job_applications-button"
                    onClick={handleStatusChange}
                  >
                    {statusLoading ? (
                      <div className="loader"></div>
                    ) : (
                      "Update Status"
                    )}
                  </button>
                </>
              </>
            )}
          </div>

          <button
            onClick={handleSeeCandidate}
            className="campuse_std_job_applications-button"
          >
            See Selected Students &nbsp; &nbsp;
            <span className="campuse_std_job_applications-icon">
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.20575 10C7.91864 10 7.63152 9.89422 7.40485 9.66755C6.96661 9.22932 6.96661 8.50397 7.40485 8.06573L10.4725 4.99811L7.40485 1.93049C6.96661 1.49225 6.96661 0.766906 7.40485 0.328674C7.84308 -0.109558 8.56843 -0.109558 9.00666 0.328674L12.8752 4.1972C13.3134 4.63544 13.3134 5.36079 12.8752 5.79902L9.00666 9.66755C8.7951 9.89422 8.49287 10 8.20575 10Z"
                  fill="#FFFFFF"
                />
                <path
                  d="M10.8466 6.1322H0.745926C0.338726 6.1322 0.00104523 5.61841 0.00104523 4.99884C0.00104523 4.37927 0.338726 3.86548 0.745926 3.86548H10.8466C11.2538 3.86548 11.5915 4.37927 11.5915 4.99884C11.5915 5.61841 11.2538 6.1322 10.8466 6.1322Z"
                  fill="#FFFFFF"
                />
              </svg>
            </span>
          </button>
        </div>
        {/* ------------------- */}
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : candidates.length === 0 ? ( // Check if no candidates are present
          <div className="no-candidates-container">
            <p>No candidates found.</p>
          </div>
        ) : (
          <div className="view_applicationlisting_table">
            <table>
              <thead className="view_applicationlisting_table_head">
                <tr>
                  <th></th>
                  <th>Full Name</th>
                  <th>Status</th>
                  <th>Contact</th>
                  <th>Applied At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {candidates.map(
                  (
                    c,
                    index // Map over candidate data
                  ) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, c.id)}
                        />
                      </td>
                      <td>{c.student_details.name || "N/A"}</td>
                      <td>
                        <span>{c.status || "N/A"}</span>
                      </td>
                      <td>{c.student_details.all_mobno || "N/A"}</td>
                      <td>{new Date(c.applied_at).toLocaleDateString()}</td>
                      <td>
                        {" "}
                        <button
                          className="view_application_btn"
                          onClick={() => {
                            navigate(
                              `/dashboard/sidebar-page/hr-manager/campus/view-candidate-application/${c.student_details.id}/${c.id}`
                            );
                          }}
                        >
                          See Application
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className="d-flex justify-content-center pagination_custom"></div>
      </div>
    </div>
  );
}
