import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../styles/landing_page/lateralhiring.css";
import dianneProfile from "../../../utils/landingpage/images/Dianne-profile.svg";
import jacobProfile from "../../../utils/landingpage/images/jacob-profile.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftarrowwhiteicon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightarroworange.svg";

import "bootstrap/dist/css/bootstrap.min.css";

const testimonials = [
  {
    name: "Jacob Jones",
    title: "Web Designer",
    text: "I highly recommend Start Naukri to anyone looking for a job. The platform is user-friendly, and the job listings are always up to date. I found my dream job thanks to Start Naukri for giving me my dream job.",
    image: jacobProfile,
  },
  {
    name: "Dianne Russell",
    title: "UI/UX Designer",
    text: "Start Naukri has been an incredible resource in my job search. The variety of job listings and the ease of application made the process much less stressful. I'm grateful for the opportunity it provided me.",
    image: dianneProfile,
  },
  {
    name: "Robert Fox",
    title: "Software Engineer",
    text: "The job matching algorithm on Start Naukri is impressive. It consistently suggested roles that aligned with my skills and career goals. I landed a great position thanks to this platform's effectiveness.",
    image: jacobProfile,
  },
  {
    name: "Dianne Russell",
    title: "UI/UX Designer",
    text: "Start Naukri has been an incredible resource in my job search. The variety of job listings and the ease of application made the process much less stressful. I'm grateful for the opportunity it provided me.",
    image: dianneProfile,
  },
];

const CustomArrow = ({ direction, onClick }) => (
  <button
    onClick={onClick}
    className={`custom-arrow ${
      direction === "prev" ? "prev-arrow" : "next-arrow"
    }`}
  >
    <img
      src={direction === "prev" ? leftArrowIcon : rightIconArrow}
      alt={direction === "prev" ? "Previous" : "Next"}
      style={{ height: "31px" }}
    />
  </button>
);

const ThinkAbout = () => {
  const sliderRef = useRef(null);
  const [Alltestimonials, setAllTestimonials] = useState([]);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/landing_page/`
      );
      setAllTestimonials(response.data[0].testimonials);
    } catch (error) {
      console.log("Error fetching testimonials:", error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false, // Disable default arrows
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <section className="think-about-main">
      <div className="think-about-container">
        <div className="think-about-content">
          <div className="think-about-content-wrapper">
            <div className="think-about-content-heading">
              <span>See what our users</span>
              <span>think about us.</span>
            </div>
            <div className="think-about-navigation">
              <CustomArrow direction="prev" onClick={goToPrev} />
              <CustomArrow direction="next" onClick={goToNext} />
            </div>
          </div>
        </div>
        <div className="think-about-slider-section">
          <div className="think-about-slider-container">
            <Slider ref={sliderRef} {...settings}>
              {Alltestimonials &&
                Alltestimonials?.map((value, index) => (
                  <div className="think-about-box-parent" key={index}>
                    <div className="think-about-box">
                      <img
                        src={value.testimonial_photo}
                        alt=".."
                        className="think-about-img think-about-img-new"
                        style={{ marginLeft: "80px" }}
                      />
                      <h3 className="think-about-name">{value.name}</h3>
                      <p className="think-about-title">{value.position}</p>
                      <p className="think-about-text">
                        {value.description?.length > 130
                          ? `${value.description?.slice(0, 130)}..`
                          : value.description}
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThinkAbout;