import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/dashboards/hrdashboard/subhr.css";
import { toast } from "react-toastify";

export default function HRManagerSubUsers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const base_Url = process.env.REACT_APP_BASE_URL;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    name: Yup.string().required("First name is required"),
    subuser_lastname: Yup.string().required("Last name is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    all_mobno: Yup.string()
      .matches(/^\d{10}$/, "Contact number must be exactly 10 digits")
      .required("Contact number is required")
      .max(10),
    subuser_branchname: Yup.string().required("Branch name is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      subuser_lastname: "",
      password: "",
      password2: "",
      all_mobno: "",
      subuser_branchname: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formattedValues = {
          ...values,
          all_mobno: `+91${values.all_mobno}`,
        };

        console.log("formattedValues", formattedValues);

        const response = await fetch(`${base_Url}/api/register/subuser/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formattedValues),
        });

        if (response.ok) {
          toast.success("Subuser created successfully!");
          formik.resetForm();
          navigate("/dashboard/sidebar-page/hr-manager/sub-user-list");
        } else if (response.status === 400) {
          const errorData = await response.json();
          if (errorData.email) {
            toast.error(errorData.email[0]);
          } else {
            toast.error("Validation failed. Please check your input.");
          }
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        toast.error("Network error. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="subuser_input_container_header">
            <h6>
              {" "}
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              &nbsp; Sub User Account Details
            </h6>
            <div>
              <Link to={"/dashboard/sidebar-page/hr-manager/sub-user-list"}>
                <button className="subuserlistbutton"> Sub User List</button>
              </Link>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="subuser_input_container">
              <div className="subuser_both_input">
                <div className="subuser_input_left">
                  <input
                    type="text"
                    name="name"
                    placeholder="First Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <span className="error-message">{formik.errors.name}</span>
                  )}

                  {/* Branch Name Input */}
                  <input
                    type="text"
                    name="subuser_branchname"
                    placeholder="Branch Name"
                    value={formik.values.subuser_branchname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.subuser_branchname &&
                    formik.errors.subuser_branchname && (
                      <span className="error-message">
                        {formik.errors.subuser_branchname}
                      </span>
                    )}
                  <input
                    type="text"
                    name="all_mobno"
                    placeholder="Contact Number"
                    value={formik.values.all_mobno}
                    maxLength={10}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        formik.setFieldValue("all_mobno", value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />

                  {formik.touched.all_mobno && formik.errors.all_mobno && (
                    <span className="error-message">
                      {formik.errors.all_mobno}
                    </span>
                  )}
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <span className="error-message">
                      {formik.errors.password}
                    </span>
                  )}
                </div>
                <div className="subuser_input_right">
                  <input
                    type="text"
                    name="subuser_lastname"
                    placeholder="Last Name"
                    value={formik.values.subuser_lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.subuser_lastname &&
                    formik.errors.subuser_lastname && (
                      <span className="error-message">
                        {formik.errors.subuser_lastname}
                      </span>
                    )}
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className="error-message">{formik.errors.email}</span>
                  )}
                  <input
                    type="password"
                    name="password2"
                    placeholder="Confirm Password"
                    value={formik.values.password2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password2 && formik.errors.password2 && (
                    <span className="error-message">
                      {formik.errors.password2}
                    </span>
                  )}
                </div>
              </div>
              <div className="subuser_buttons">
                <button type="submit" disabled={loading}>
                  {loading ? "Creating..." : "Create"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
