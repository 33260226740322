import { useEffect, useState } from "react";
import axios from "axios";
import "../../../../styles/dashboards/hrdashboard/circulateJob.css";
import "../../../../styles/dashboards/hrdashboard/postjobs.css";
import { useNavigate } from "react-router-dom";

export default function CirculatedList() {
  const base_Url = process.env.REACT_APP_BASE_URL;
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [circulatedJobs, setCirculatedJobs] = useState([]);

  useEffect(() => {
    axios
      .get(`${base_Url}/api/job-circulated-details/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCirculatedJobs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching circulated jobs:", error);
      });
  }, [base_Url, token]);
  const handleDetails = (job) => {
    localStorage.setItem("seecirculatedjobdetails", JSON.stringify(job));
    navigate(`/dashboard/sidebar-page/hr-manager/circulated-collegslist`);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="d-flex flex-column p-4 gap-4">
            <div className="d-flex align-items-center">
              <h5>
                {" "}
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <path
                    d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                    fill="#222222"
                  />
                  <path
                    d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                    fill="#222222"
                  />
                </svg>{" "}
                &nbsp; Circulated Jobs List
              </h5>
            </div>
            <div className="circulate_job_detail_list">
              {circulatedJobs.length > 0 ? (
                circulatedJobs.map((job) => (
                  <div key={job.job_id}>
                    <div className="circulate_job_detail invite_details">
                      <ul>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleDetails(job);
                          }}
                        >
                          {job.job_title?.length > 15
                            ? job.job_title.substring(0, 15) + "..."
                            : job.job_title || "N/A"}
                        </li>

                        <li>
                          <span
                            className={`status ${job.job_status.toLowerCase()}`}
                          >
                            {job.job_status || "N/A"}
                          </span>
                        </li>
                        <li>
                          {new Date(job.date_posted).toLocaleDateString() ||
                            "N/A"}
                        </li>
                        <li>
                          {new Date(job.due_date).toLocaleDateString() || "N/A"}
                        </li>
                        <li>
                          <p className="circulate_job_type">
                            {job.job_type || "N/A"}
                          </p>
                        </li>
                        <li>
                          {job.min_salary} to {job.max_salary} lpa
                        </li>
                        <li>{job.vacancy}</li>
                      </ul>
                    </div>
                    <div className="circulate_job_clg_invite_details">
                      <p>Shared Colleges {job.total_colleges_circulated}</p>
                      <p>Accepted Colleges {job.total_colleges_accepted}</p>
                      <p>Applied Students {job.total_applicants}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no_job_post_yet">
                  No circulated jobs available.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
