import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../styles/dashboards/dashboard.css";
import "../../../styles/allJobs/allJobs.css";
import "../../../styles/myApplication/myapplication.css";
import "../../../styles/dashboards/clgdashboard/campusHome.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import microsoftIMG from "../../../utils/landingpage/images/microsoft-logo.svg";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import Microsoft from "../../../utils/landingpage/images/microsoft-logo.svg";
import inReview from "../../../utils/dashboards/inReviewIcon.svg";
import rejectedIcon from "../../../utils/dashboards/rejectedIcon.svg";
import shortListed from "../../../utils/dashboards/shortlistedIcon.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import PlacementTrendGraph from "./placementTrendGraph";
import LogOut from "../../reusablecomponents/Logout";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";

function CampusManagerHome() {
  const [isOpen, setIsOpen] = useState(false);
  const [experience, setExperience] = useState(0);
  const [isOpenNew, setIsOpenNew] = useState(null);
  const [activeFilter, setActiveFilter] = useState("Shortlisted");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");

  const navigate = useNavigate();

  function handleSignOut() {
    sessionStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 1500);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const recentJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
    { company: "Systango Pvt. Ltd.", logo: "Y" },
  ];

  const popularJobs = [
    { company: "Microsoft Pvt. Ltd.", logo: "M" },
    { company: "Mphasis Pvt. Ltd.", logo: "M" },
    { company: "Accenture Pvt. Ltd.", logo: ">" },
  ];

  const data = {
    labels: [
      "In-Review Applications",
      "Rejected Applications",
      "Shortlisted Applications",
    ],
    datasets: [
      {
        data: [62, 24, 16],
        backgroundColor: ["#f6c23e", "#e74a3b", "#1cc88a"],
        hoverBackgroundColor: ["#f6b23e", "#e73b3b", "#1cc8a7"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#fff",
        anchor: "end",
        align: "start",
        offset: -10,
        borderWidth: 2,
        borderColor: "#fff",
        borderRadius: 25,
        backgroundColor: (context) => context.dataset.backgroundColor,
        font: {
          weight: "bold",
          size: 14,
        },
        formatter: (value) => `${value}%`,
      },
    },
    cutout: "50%",
  };

  const [applications, setApplications] = useState({
    Shortlisted: [
      {
        id: 1,
        company: "Microsoft Pvt. Ltd",
        location: "Pune, Maharashtra",
        date: "26th July 2024",
        status: "Shortlisted",
        logo: "M",
      },
      {
        id: 2,
        company: "Mphasis Pvt. Ltd",
        location: "Vadodara, Gujarat",
        date: "25th July 2024",
        status: "Shortlisted",
        logo: "M",
      },
      {
        id: 3,
        company: "Accenture Pvt. Ltd",
        location: "Indore, Madhya Pradesh",
        date: "18th July 2024",
        status: "Shortlisted",
        logo: ">",
      },
      {
        id: 4,
        company: "Systango Pvt. Ltd",
        location: "Indore, Madhya Pradesh",
        date: "20th July 2024",
        status: "Shortlisted",
        logo: "Y",
      },
    ],
    "In-Review": [
      {
        id: 1,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
      {
        id: 2,
        company: "Amazon Web Services",
        location: "Mumbai, Maharashtra",
        date: "23rd July 2024",
        status: "In-Review",
        logo: "A",
      },
      {
        id: 3,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
      {
        id: 4,
        company: "Amazon Web Services",
        location: "Mumbai, Maharashtra",
        date: "23rd July 2024",
        status: "In-Review",
        logo: "A",
      },
      {
        id: 7,
        company: "Google Inc",
        location: "Bangalore, Karnataka",
        date: "22nd July 2024",
        status: "In-Review",
        logo: "G",
      },
    ],
    Rejected: [
      {
        id: 1,
        company: "Facebook",
        location: "Hyderabad, Telangana",
        date: "19th July 2024",
        status: "Rejected",
        logo: "F",
      },
      {
        id: 2,
        company: "Twitter",
        location: "Delhi, NCR",
        date: "21st July 2024",
        status: "Rejected",
        logo: "T",
      },
      {
        id: 3,
        company: "Facebook",
        location: "Hyderabad, Telangana",
        date: "19th July 2024",
        status: "Rejected",
        logo: "F",
      },
      {
        id: 4,
        company: "Twitter",
        location: "Delhi, NCR",
        date: "21st July 2024",
        status: "Rejected",
        logo: "T",
      },
    ],
  });

  // Calculate total pages based on activeFilter
  const totalPages = Math.ceil(
    applications[activeFilter].length / itemsPerPage
  );

  // Get current items for pagination
  const currentItems = applications[activeFilter].slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropDown = (id) => {
    setIsOpen(isOpen === id ? null : id);
  };

  const [departmentData] = useState([
    { department: 'IT Department', value: 90 },
    { department: 'Sales Department', value: 60 },
    { department: 'HR Department', value: 65 },
    { department: 'Finance Department', value: 85 },
    { department: 'Business Department', value: 45 },
    { department: 'Marketing Department', value: 70 }
  ]);

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="collage_stat_container stat-container">
            <div className="stat-card">
              <div className="stat-icon">
                <img src={applicationIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Students</h3>
                <p>100</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <img src={inReview} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Job Profiles</h3>
                <p>100</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <img src={rejectedIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Offers</h3>
                <p>100</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <img src={shortListed} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Placed</h3>
                <p>100</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <img src={shortListed} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Interviewed</h3>
                <p>100</p>
              </div>
            </div>
          </div>
          <div className="_main_content">
            <div className="_main_first_content">
              <div className="campus_manager_chart_section">
                <div className="campus_manager_chart_header">
                  <b className="campus_manager_chart_title">Department Wise Placement</b>
                </div>
                <div className="campus_manager_stats_container container-fluid px-0">
                  <div className="campus_manager_chart_container">
                    <div className="campus_manager_scale_markers row mx-0">
                      {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((num) => (
                        <div key={num} className="campus_manager_scale_number col text-center">
                          {num}
                        </div>
                      ))}
                    </div>

                    <div className="campus_manager_department_wrapper">
                      {departmentData.map((item, index) => (
                        <div key={index} className="campus_manager_department_row row align-items-center mb-4 mx-0">
                          <div className="campus_manager_department_label col-3">
                            {item.department}
                          </div>
                          <div className="campus_manager_progress_container col px-0">
                            <div className="campus_manager_progress">
                              <div
                                className="campus_manager_progress_bar"
                                role="progressbar"
                                style={{ width: `${item.value}%` }}
                                aria-valuenow={item.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <div className="_applications_history collag_campus_dashboard_div mt-4">
                  <section className="_campus_dashboard_application_history">
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                        <b>Upcoming Placements</b>
                      </div>

                      <div className="pt-4 pb-4">
                        <div className="d-flex align-items-center gap-4 ps-4">
                          <div className="collage_campus_upcoming_date_callande">
                            <b>10</b>
                            <p>August</p>
                          </div>
                          <div className="job-item" style={{ boxShadow: "none", margin: "0px", padding: "0" }}>
                            <div
                              className="job-left"
                            >
                              <img
                                src={dummy_logo}
                                alt={'logo'}
                                className="company-logo"
                              />
                              <div className="job-info">
                                <h6>Ganpat University</h6>
                                <p>10 Days Remaining</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-4 ps-4">
                          <div className="collage_campus_upcoming_date_callande">
                            <b>10</b>
                            <p>August</p>
                          </div>
                          <div className="job-item" style={{ boxShadow: "none", margin: "0px", padding: "0" }}>
                            <div
                              className="job-left"
                            >
                              <img
                                src={dummy_logo}
                                alt={'logo'}
                                className="company-logo"
                              />
                              <div className="job-info">
                                <h6>Ganpat University</h6>
                                <p>10 Days Remaining</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-4 ps-4">
                          <div className="collage_campus_upcoming_date_callande">
                            <b>10</b>
                            <p>August</p>
                          </div>
                          <div className="job-item" style={{ boxShadow: "none", margin: "0px", padding: "0" }}>
                            <div
                              className="job-left"
                            >
                              <img
                                src={dummy_logo}
                                alt={'logo'}
                                className="company-logo"
                              />
                              <div className="job-info">
                                <h6>Ganpat University</h6>
                                <p>10 Days Remaining</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </section>
                </div>
                <div className="_applications_history collag_campus_dashboard_div mt-4">
                  <section className="_campus_dashboard_application_history">
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center myApplication_top_header">
                        <b>CTC Status</b>
                      </div>

                      <div className="pt-4 pb-4">
                        <div className="d-flex align-items-center gap-4 ps-4 pe-4 pb-2">
                          <div className="job-item" style={{ boxShadow: "none", margin: "0px", padding: "0" }}>
                            <div
                              className="job-left"
                            >
                              <img
                                src={dummy_logo}
                                alt={'logo'}
                                className="company-logo"
                              />
                              <div className="job-info">
                                <h6>Ganpat University</h6>
                              </div>
                            </div>
                          </div>
                          <div className="collage_manager_campus_packages">
                            <b>3 Lacks</b>
                            <p>Per Annum</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-4 ps-4 pe-4 pb-2">
                          <div className="job-item" style={{ boxShadow: "none", margin: "0px", padding: "0" }}>
                            <div
                              className="job-left"
                            >
                              <img
                                src={dummy_logo}
                                alt={'logo'}
                                className="company-logo"
                              />
                              <div className="job-info">
                                <h6>Ganpat University</h6>
                              </div>
                            </div>
                          </div>
                          <div className="collage_manager_campus_packages">
                            <b>3.5 Lacks</b>
                            <p>Per Annum</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-4 ps-4 pe-4 pb-2">
                          <div className="job-item" style={{ boxShadow: "none", margin: "0px", padding: "0" }}>
                            <div
                              className="job-left"
                            >
                              <img
                                src={dummy_logo}
                                alt={'logo'}
                                className="company-logo"
                              />
                              <div className="job-info">
                                <h6>Ganpat University</h6>
                              </div>
                            </div>
                          </div>
                          <div className="collage_manager_campus_packages">
                            <b>3.5 Lacks</b>
                            <p>Per Annum</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div className="d-grid gap-4 _recent_jobs_head">
              <div className="_recent_jobs">
                <div className="_recentadded_container" style={{ height: "90vh" }}>
                  <div className="_popularjobs_section">
                    <div className="_popularjobs_header">
                      <b>Placement Trend</b>
                      {/* <a href="#" className="_popularjobs_seeAllLink">See All</a> */}
                    </div>
                    <hr />
                    <PlacementTrendGraph />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CampusManagerHome;
