// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../reducers/loginReducer";
import letralHiringReducer from "../reducers/letralHiringReducer";
import studentProfileReducer from "../reducers/student_profile_reducer";
import studentProfilePercentageReducer from "../reducers/student_profile_percentage"
import hrProfileReducer from "../reducers/hrProfileReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    letralHiring: letralHiringReducer,
    studentProfile: studentProfileReducer,
    studentProfilePercentage: studentProfilePercentageReducer,
    hrProfile: hrProfileReducer,
  },
});

export default store;
