import React, { useState } from "react";
import arrowImg from "../../../../utils/landingpage/images/navigate.svg";
import { useNavigate } from "react-router-dom";
import "../../../../styles/admin/contentmanagement.css";
import { Rating } from "@mui/material";
import imgUpload from "../../../../utils/dashboards/img-upload.svg";
import videoupload from "../../../../utils/dashboards/videoupload.svg";
import TestimonialCard from "./TestimonialCard";

function ClientTestimonial() {
  const navigate = useNavigate();
  const [imagePreview, setImagePreview] = useState(
    "https://via.placeholder.com/100"
  );
  const [videoPreview, setVideoPreview] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoPreview(videoUrl); // Set video preview
    }
  };

  const goToBack = () => {
    navigate(-1);
  };
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {" "}
        <div className="clientTestimonial_header">
          <h6>
            <img
              onClick={goToBack}
              className="arrow_icon"
              src={arrowImg}
              alt=".."
            />
            &nbsp; Add/Edit Client Testimonial <br />
            &nbsp;{" "}
            <span className="sub_heading">
              Fill out the testimonial details
            </span>
          </h6>
        </div>
        {/* ------------------------------------ */}
        <div className="clientTestimonial_main_container p-4">
          <form className="clientTestimonial_form">
            <div className="clientTestimonial_upload_section">
              <div className="image_upload_ratings_container">
                <div className="image_upload">
                  <div>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ width: "100px", borderRadius: "50%" }}
                    />
                  </div>

                  <div>
                    <label className="content_upload_img_label_heading">
                      Upload Profile Photo
                    </label>
                    <label
                      htmlFor="content_upload_img"
                      className="content_upload_img_label"
                    >
                      <img src={imgUpload} alt=".." /> <p> Upload Image</p>{" "}
                    </label>
                    <input
                      id="content_upload_img"
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload} // Handle file selection
                    />
                  </div>
                </div>
                <div className="ratings">
                  <div style={{ paddingTop: "0.5rem" }}>
                    <p className="add_rating">Add Rating</p>
                    <Rating
                      name="half-rating"
                      defaultValue={2.5}
                      precision={0.5}
                      sx={{
                        fontSize: "36px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <h6>or</h6>
              <div className="video_upload_container">
                <label className="content_upload_img_label_heading">
                  Upload Video &nbsp;
                </label>

                <label
                  className="content_upload_img_label_heading_btn"
                  htmlFor="video_upload_label"
                >
                  Change
                </label>
                <label
                  htmlFor="video_upload_label"
                  className="video_upload_label"
                >
                  <div className="video_upload_box">
                    {videoPreview ? (
                      <video
                        src={videoPreview}
                        controls
                        className="video_preview"
                        alt="Video Preview"
                      />
                    ) : (
                      <img
                        src={videoupload} // Placeholder for upload icon
                        className="img_upload_icon"
                        alt="Upload Icon"
                      />
                    )}
                  </div>
                </label>
                <input
                  id="video_upload_label"
                  type="file"
                  accept="video/*"
                  style={{ display: "none" }} // Hide the default input
                  onChange={handleVideoUpload} // Handle video file change
                />
              </div>
            </div>
            <div className="clientTestimonial_upload_section_input_fields">
              <div className="clientTestimonial_input">
                <input type="text" placeholder="Enter Client Name" />
              </div>
              <div className="clientTestimonial_input">
                <input type="text" placeholder="Enter Company Name" />
              </div>
              <div className="clientTestimonial_textarea">
                <textarea
                  type="text"
                  cols={20}
                  rows={6}
                  placeholder="Enter testimonial description"
                />
              </div>
            </div>
            <div className="clientTestimonial_btn">
              <button className="clientTestimonial_submit_btn">Submit</button>
              <button className="clientTestimonial_cancel_btn">Cancel</button>
            </div>
          </form>
        </div>
        <div className="clientTestimonial_header mt-4">
          <h6>
            &nbsp; Add/Edit Client Testimonial <br />
          </h6>

          {/* show testimonials card */}
          <TestimonialCard />
          {/* end testimonials card end */}
        </div>
      </div>
    </div>
  );
}

export default ClientTestimonial;
