import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RoutesHead from "./routes";
import OnlineStatusProvider from "./components/reusablecomponents/CheckOnline";

function App() {
  return (
    <div>
      <OnlineStatusProvider>
        <RoutesHead />
      </OnlineStatusProvider>
    </div>
  );
}

export default App;
