import React, { useState } from "react";
import * as Yup from "yup";
import coursenameedit from "../../../utils/dashboards/coursenameedit.svg";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";

function CampusManagerCourse() {
  const [courses, setCourses] = useState([]); // Initialize courses as an empty array
  const [editCourse, setEditCourse] = useState({
    id: null,
    name: "",
    description: "",
    duration: "",
    contact: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Course name is required"),
    description: Yup.string().required("Course description is required"),
    duration: Yup.string().required("Course duration is required"),
    contact: Yup.string()
      .required("Contact information is required")
      .matches(
        /^[0-9]{10}$/,
        "Contact information must be a valid 10-digit phone number"
      ),
  });

  // Handle the click on the edit button
  const handleEditClick = (courseId) => {
    const course = courses.find((course) => course.id === courseId);
    setEditCourse({ ...course });
  };

  // Handle changes in the form fields
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCourse((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle saving (either creating or editing a course)
  const handleSave = () => {
    if (editCourse.id) {
      // If editCourse has an id, update the existing course
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course.id === editCourse.id ? { ...course, ...editCourse } : course
        )
      );
    }
    // Reset the form after saving
    setEditCourse({
      id: null,
      name: "",
      description: "",
      duration: "",
      contact: "",
    });
  };

  // Handle the "Add Course" button click (creates a new course without saving)
  const handleAddCourse = () => {
    const newCourse = {
      id: Date.now(), // Unique ID based on timestamp
      name: editCourse.name,
      description: editCourse.description,
      duration: editCourse.duration,
      contact: editCourse.contact,
    };
    // Add the new course to the courses array
    setCourses((prevCourses) => [...prevCourses, newCourse]);

    // Clear the form after adding
    setEditCourse({
      id: null,
      name: "",
      description: "",
      duration: "",
      contact: "",
    });
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="course-offered-text">
          <h5>Courses Offered</h5>
        </div>

        {/* Form for adding/editing courses */}
        <div className="course-offered-page">
          <div className="course-offered-container">
            <div className="form-left">
              <div className="course-offered-form-group">
                <input
                  type="text"
                  name="name"
                  value={editCourse.name}
                  onChange={handleEditChange}
                  placeholder="Course Name"
                  required
                />
              </div>
              <div className="course-offered-form-group">
                <input
                  type="text"
                  name="description"
                  value={editCourse.description}
                  onChange={handleEditChange}
                  placeholder="Description about the course (e.g., degree or diploma)"
                  required
                />
              </div>
            </div>
            <div className="form-right">
              <div className="course-offered-form-group">
                <input
                  type="text"
                  name="duration"
                  value={editCourse.duration}
                  onChange={handleEditChange}
                  placeholder="Duration about the course"
                  required
                />
              </div>
              <div className="course-offered-form-group">
                <input
                  type="text"
                  name="contact"
                  value={editCourse.contact}
                  onChange={handleEditChange}
                  placeholder="Contact Information"
                  required
                />
              </div>
            </div>
          </div>
          <div className="course-offered-buttons">
            {/* Show Save button only if editing */}
            {editCourse.id ? (
              <button
                className="course-offered-savebutton"
                onClick={handleSave}
              >
                Save
              </button>
            ) : (
              <button
                className="course-offered-add-course"
                onClick={handleAddCourse}
              >
                Add Course
              </button>
            )}
          </div>
        </div>

        {/* Displaying the offered courses */}
        <div className="offered-courses">
          <h5>Offered Courses</h5>
          <p>Add the courses you offer</p>
          <div className="courses-grid">
            {courses.length > 0 ? (
              courses.map((course) => (
                <div key={course.id} className="course-card">
                  <div className="courses-offered-name">
                    <h5>
                      {course.name}{" "}
                      <img
                        src={coursenameedit}
                        alt="edit"
                        onClick={() => handleEditClick(course.id)} // Pass `course.id` for editing
                      />
                    </h5>
                    <p>Duration: {course.duration}</p>
                    <p>Contact: {course.contact}</p>
                    <p>Description:{course.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No courses available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusManagerCourse;
