import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/dashboards/hrdashboard/subhr.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { IconButton, Menu, MenuItem, Pagination } from "@mui/material";
import threedot from "../../../utils/landingpage/images/three-dots-svgrepo-com.svg";

const dummyJobData = [
  {
    id: "1",
    role: "Developer",
    status: "Open",
    datePosted: "2024-12-01",
    dueDate: "2024-12-20",
    jobType: "Full-time",
    degreeType: "Bachelor's",
    salaryRange: "₹50,000 - ₹70,000",
    applicants: 10,
    vacancy: 2,
  },
  {
    id: "2",
    role: "Designer",
    status: "Closed",
    datePosted: "2024-11-15",
    dueDate: "2024-12-10",
    jobType: "Part-time",
    degreeType: "Diploma",
    salaryRange: "₹30,000 - ₹40,000",
    applicants: 5,
    vacancy: 1,
  },
  {
    id: "3",
    role: "Project Manager",
    status: "Open",
    datePosted: "2024-10-20",
    dueDate: "2024-12-30",
    jobType: "Contract",
    degreeType: "Master's",
    salaryRange: "₹80,000 - ₹1,00,000",
    applicants: 8,
    vacancy: 1,
  },
  {
    id: "4",
    role: "Data Scientist",
    status: "Open",
    datePosted: "2024-12-05",
    dueDate: "2025-01-10",
    jobType: "Full-time",
    degreeType: "Ph.D.",
    salaryRange: "₹1,20,000 - ₹1,50,000",
    applicants: 20,
    vacancy: 3,
  },
  {
    id: "5",
    role: "QA Engineer",
    status: "Closed",
    datePosted: "2024-09-01",
    dueDate: "2024-11-01",
    jobType: "Full-time",
    degreeType: "Bachelor's",
    salaryRange: "₹40,000 - ₹60,000",
    applicants: 12,
    vacancy: 2,
  },
  {
    id: "6",
    role: "System Administrator",
    status: "Open",
    datePosted: "2024-11-10",
    dueDate: "2024-12-25",
    jobType: "Full-time",
    degreeType: "Associate's",
    salaryRange: "₹35,000 - ₹55,000",
    applicants: 7,
    vacancy: 1,
  },
  {
    id: "7",
    role: "Business Analyst",
    status: "Open",
    datePosted: "2024-10-30",
    dueDate: "2024-12-15",
    jobType: "Full-time",
    degreeType: "Master's",
    salaryRange: "₹60,000 - ₹85,000",
    applicants: 9,
    vacancy: 2,
  },
  {
    id: "8",
    role: "HR Manager",
    status: "Open",
    datePosted: "2024-12-01",
    dueDate: "2024-12-31",
    jobType: "Full-time",
    degreeType: "Bachelor's",
    salaryRange: "₹50,000 - ₹75,000",
    applicants: 15,
    vacancy: 1,
  },
];

function ViewSubUser() {
  const ITEM_HEIGHT = 48;
  const [anchorEls, setAnchorEls] = useState({});
  const { subUserId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const base_Url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [jobData, setJobData] = useState(dummyJobData);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 4;
  const startIndex = (currentPage - 1) * jobsPerPage;
  const endIndex = startIndex + jobsPerPage;
  const currentJobs = jobData.slice(startIndex, endIndex);

  const handleClick = (event, userId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [userId]: event.currentTarget,
    }));
  };

  useEffect(() => {
    getSubUserDetails();
  }, [subUserId]);

  const getSubUserDetails = async () => {
    try {
      const response = await fetch(
        `${base_Url}/api/details_subuser/${subUserId}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching sub-user details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (userId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [userId]: null,
    }));
  };

  const formik = useFormik({
    initialValues: {
      name: userData?.name || "",
      subuser_lastname: userData?.subuser_lastname || "",
      subuser_branchname: userData?.subuser_branchname || "",
      email: userData?.email || "",
      all_mobno: userData?.all_mobno || "",
      password: "", // Add password and confirm password if needed
      password2: "",
    },
    enableReinitialize: true, // This ensures formik re-initializes when userData changes
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await axios.put(
          `${base_Url}/api/update_subuser/${subUserId}/`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 200) toast.success("Sub-user updated successfully!");

        setIsEditing(false);
        getSubUserDetails();
      } catch (error) {
        console.error("Error updating sub-user:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  if (loading) {
    return (
      <div className="_main_content_inner_screen">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  if (!userData) {
    return <div>No user details available.</div>;
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="sub_hr_container">
          <h6>
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; Sub User Details
          </h6>

          {isEditing ? (
            <div>
              <div className="float-end p-3">
                <button
                  type="button"
                  onClick={() => setIsEditing(!isEditing)}
                  className="edit_btn"
                >
                  {isEditing ? "Cancel Edit" : "Edit"}
                </button>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="subuser_input_container">
                  <div className="subuser_both_input">
                    <div className="subuser_input_left">
                      <input
                        type="text"
                        name="name"
                        placeholder="First Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <span className="error-message">
                          {formik.errors.name}
                        </span>
                      )}

                      <input
                        type="text"
                        name="subuser_branchname"
                        placeholder="Branch Name"
                        value={formik.values.subuser_branchname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.subuser_branchname &&
                        formik.errors.subuser_branchname && (
                          <span className="error-message">
                            {formik.errors.subuser_branchname}
                          </span>
                        )}
                      <input
                        type="text"
                        name="all_mobno"
                        placeholder="Contact Number"
                        value={formik.values.all_mobno}
                        maxLength={10}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.all_mobno && formik.errors.all_mobno && (
                        <span className="error-message">
                          {formik.errors.all_mobno}
                        </span>
                      )}
                    </div>
                    <div className="subuser_input_right">
                      <input
                        type="text"
                        name="subuser_lastname"
                        placeholder="Last Name"
                        value={formik.values.subuser_lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.subuser_lastname &&
                        formik.errors.subuser_lastname && (
                          <span className="error-message">
                            {formik.errors.subuser_lastname}
                          </span>
                        )}
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <span className="error-message">
                          {formik.errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="subuser_buttons">
                    <button type="submit" disabled={loading}>
                      {loading ? "Updating..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="subuser_input_container">
              <div className="float-end">
                <button
                  type="button"
                  onClick={() => setIsEditing(!isEditing)}
                  className="edit_btn"
                >
                  {isEditing ? "Cancel Edit" : "Edit"}
                </button>
              </div>
              <div className="subuser_both_input_card">
                <div>
                  <div>
                    <label>Full Name</label>
                    <input
                      type="text"
                      value={`${userData.name} ${userData.subuser_lastname}`}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                  <div>
                    <label>Email Address</label>
                    <input
                      type="email"
                      value={userData.email}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label>Contact Number</label>
                    <input
                      type="text"
                      value={userData.all_mobno}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                  <div>
                    <label>Branch Name</label>
                    <input
                      type="text"
                      value={userData.subuser_branchname}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="sub_hr_userlist_job_listing_table">
          <div>
            {jobData.length === 0 ? (
              <p>No job post by sub-hrs</p>
            ) : (
              <table>
                <thead className="sub_hr_userlist_job_listing_table_head">
                  <tr>
                    <th>Roles</th>
                    <th>Status</th>
                    <th>Date Posted</th>
                    <th>Due Date</th>
                    <th>Job Type</th>
                    <th>Degree Type</th>
                    <th>Salary Range</th>
                    <th>Applicants</th>
                    <th>Vacancy</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentJobs.map((job) => (
                    <tr key={job.id}>
                      <td>{job.role}</td>
                      <td>{job.status}</td>
                      <td>{job.datePosted}</td>
                      <td>{job.dueDate}</td>
                      <td>{job.jobType}</td>
                      <td>{job.degreeType}</td>
                      <td>{job.salaryRange}</td>
                      <td>{job.applicants}</td>
                      <td>{job.vacancy}</td>
                      <td>
                        {" "}
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={
                              anchorEls[job.id] ? "long-menu" : undefined
                            }
                            aria-expanded={
                              anchorEls[job.id] ? "true" : undefined
                            }
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, job.id)}
                          >
                            <img
                              src={threedot}
                              height={18}
                              width={18}
                              alt=".."
                            />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEls[job.id]} // Now specific to the user
                            open={Boolean(anchorEls[job.id])}
                            onClose={() => handleClose(job.id)}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "20ch",
                              },
                            }}
                          >
                            <MenuItem>View Details</MenuItem>
                            {/* <MenuItem>Delete</MenuItem> */}
                          </Menu>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div style={{ background: "#fff" }}>
            {jobData.length > jobsPerPage && (
              <div className="d-flex justify-content-center pagination_custom p-2">
                <Pagination
                  count={Math.ceil(jobData.length / jobsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSubUser;
