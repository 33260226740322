import "../../../styles/landing_page/about.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Services from "../../common_component/services/services";
import Testimonial from "../../common_component/testimonial/testimonial";
import chair from "../../../utils/landingpage/images/about_bg.png";
import beg from "../../../utils/landingpage/images/beg.svg";
import check from "../../../utils/landingpage/images/check.svg";
import user from "../../../utils/landingpage/images/user.svg";
import file from "../../../utils/landingpage/images/file.svg";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation()

  return (
    <>
      <section className="about_main">
        <div className="about_header text-center">
          <h4>About Us</h4>
          <h2>About the Platform</h2>
          <h6>know about the company and the team behind it.</h6>
          <button className="btn">Book a Demo/Register Now </button>
        </div>
      </section>
      {/* First section start */}
      {/* second section start */}

      <section class="about_section">
        <section>
          <div class="about_container row">
            <div class="about_image_container col-4 row-12">
              <img
                src={chair}
                alt="Office-Image"
                class="about_image img-fluid"
              />
            </div>
            <div class="about_text_section col-12 col-lg-8 ">
              <div class="about_showcase ">
                <div class="about_second_section_content">
                  <h6>We’ve helped thousand's of companies</h6>
                  <h3>
                    We are at the inception of an exciting and transformative
                    journey.
                  </h3>
                  <p>
                    {location.pathname.includes('lateral') ? (
                      `Our platform connects talented professionals with leading employers, simplifying job searches and hiring. With personalized recommendations and easy applications, finding the right fit is seamless.`
                    ) : (`
                   StartNaukri.com is a latest technology enables 360 degree hiring solutions for Corporates which caters pan India across 1000 plus cities along with 40000 colleges/Campuses, which provides solutions for Apprenticeship Hiring, Internship Hiring, Fresher Hiring, Campus Hiring Right from IIT/IIMs to Cat A, B, C, D Colleges which enables and connect freshers to all the corporates pan India for pan India Hiring at your fingertips on your laptop with complete 100% online hiring process at highly competitive and discounted pricing compared to naukri.com and Linkedin and other job portal’s
                    `)}
                  </p>
                </div>
              </div>
              <div class="about_second_section_box row">
                <div className="col-sm-4 col-12 ">
                  <div class="box">
                    <h3>
                      {/* 20K<span>+</span> */}
                      Coming Soon
                    </h3>
                    <h4>Students Placed</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div class="box">
                    <h3>
                      {/* 5K<span>+</span> */}
                      Coming Soon
                    </h3>
                    <h4>Companies Registered</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div class="box">
                    <h3>
                      {/* 2K<span>+</span> */}
                      Coming Soon
                    </h3>
                    <h4>Campuses</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="about_section_line"></div>
          </div>
        </section>

        <section class="how_it_works">
          <div class="how_it_work_container">
            <h2>How it works</h2>
            <p className="text-center w-100  sm-w-50 md-w-50 lg-w-50 xl-w-50">
              Process to get a job through startnaukri.com.
            </p>
            <div class="row  p-0 ">
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 col-lg-4 d-flex  justify-content-center align-items-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={user} alt="" />
                  </div>
                  <h4>Create Account</h4>
                  <p>Set up a free account for exclusive job access.</p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 col-lg-4 d-flex  justify-content-center align-items-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={file} alt="" />
                  </div>
                  <h4>Upload Resume</h4>
                  <p>
                    Upload your resume for quick, one-click applications.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 col-lg-4 d-flex  justify-content-center align-items-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={beg} alt="" />
                  </div>
                  <h4>Find Jobs</h4>
                  <p>
                    Find jobs tailored to your skills and preferences.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6  col-xl-3 col-lg-4 d-flex justify-content-center ">
                <div class="how_box">
                  <div class="icon">
                    <img src={check} alt="" />
                  </div>
                  <h4>Apply Job</h4>
                  <p>Apply instantly with one click and get updates.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* second section end */}

      <Services />

      <Testimonial />
    </>
  );
}
