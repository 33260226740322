import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import dummy_logo from '../../../../utils/landingpage/images/dummy_logo.png'
import { setGetStudentProfileData } from '../../../../redux/reducers/student_profile_reducer';
import { fetchStudentProfileData } from '../../../../redux/actions/student_profile_action';
import { fetchStudentProfileCompletion } from '../../../../redux/actions/student_profilePercentage';

const StudentExperiance = () => {

  const [formData, setFormData] = useState({
    currentCompanyName: "",
    currentlyWorking: "",
    currentjoiningYear: "",
    currentjoiningMonth: "",
    currentjobTitle: "",
    currentSalary: "",
    EmploymentType: "",
    previousCompanyName: "",
    previousjoiningYear: "",
    previousjoiningMonth: "",
    previousjobTitle: "",
    previousTillYear: "",
    previousTillMonth: "",
    employments: [],
  });
  const [errors, setErrors] = useState({});

  const [isSubmittedEmployment, setisSubmittedEmployment] = useState(false);
  const [isEditModelOpenEmployment, setIsEditModelOpenEmployment] =
    useState(false);
  const [isCurrentEmployment, setIsCurrentEmployment] = useState("Yes");
  const [employmentType, setEmploymentType] = useState("Full Time");
  const [showDropdown, setShowDropdown] = useState(false);
  const [editingValue, setEditingValue] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
  const dispatch = useDispatch()

  const validateEmployment = () => {
    const newErrors = {};

    if (isCurrentEmployment === "Yes") {
      if (!formData.currentCompanyName) {
        newErrors.currentCompanyName = "Current company name is required";
      }
      if (!formData.noticePeriod) {
        newErrors.noticePeriod = "Notice period is required";
      }
      if (!formData.currentjoiningYear) {
        newErrors.currentjoiningYear = "Joining year is required";
      }
      if (!formData.currentjoiningMonth) {
        newErrors.currentjoiningMonth = "Joining month is required";
      }
      if (!formData.currentjobTitle) {
        newErrors.currentjobTitle = "Job title is required";
      }
      if (!formData.currentSalary) {
        newErrors.currentSalary = "Current salary is required";
      }
    }

    if (isCurrentEmployment === "No") {
      if (!formData.previousCompanyName) {
        newErrors.previousCompanyName = "Previous company name is required";
      }
      if (!formData.previousjoiningYear) {
        newErrors.previousjoiningYear = "Joining year is required";
      }
      if (!formData.previousjoiningMonth) {
        newErrors.previousjoiningMonth = "Joining month is required";
      }
      if (!formData.previousjobTitle) {
        newErrors.previousjobTitle = "Job title is required";
      }
      if (!formData.previousTillYear) {
        newErrors.previousTillYear = "End year is required";
      }
      if (!formData.previousTillMonth) {
        newErrors.previousTillMonth = "End month is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddNewEmployment = () => {
    setisSubmittedEmployment(false);
    setIsEditModelOpenEmployment(false);
  };

  const handleDateInputChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.slice(0, 4); // Ensuring only the year is entered

    setFormData({
      ...formData,
      [name]: formattedValue, // Directly setting the year
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleEditEmploymentDetails = (value, event) => {
    event.preventDefault();

    let editedEmployment = {};

    if (formData.currentCompanyName) {
      editedEmployment.current_company_name = formData.currentCompanyName;
    }

    if (formData.previousCompanyName) {
      editedEmployment.current_company_name = formData.previousCompanyName;
    }

    if (formData.currentjobTitle) {
      editedEmployment.current_job_title = formData.currentjobTitle;
    }

    if (formData.previousjobTitle) {
      editedEmployment.current_job_title = formData.previousjobTitle;
    }

    if (formData.currentjoiningYear) {
      editedEmployment.joining_year = formData.currentjoiningYear;
    }

    if (formData.currentjoiningMonth) {
      editedEmployment.joining_month = formData.currentjoiningMonth;
    }

    if (formData.currentSalary) {
      editedEmployment.current_salary = formData.currentSalary;
    }

    if (formData.previousTillYear) {
      editedEmployment.worked_till_year = formData.previousTillYear;
    }

    if (formData.previousjoiningYear) {
      editedEmployment.joining_year = formData.previousjoiningYear;
    }

    if (formData.previousjoiningMonth) {
      editedEmployment.joining_month = formData.previousjoiningMonth;
    }

    if (formData.previousTillMonth) {
      editedEmployment.worked_till_month = formData.previousTillMonth;
    }

    if (formData.noticePeriod) {
      editedEmployment.employment_notice_period = formData.noticePeriod;
    }

    if (formData.department) {
      editedEmployment.department = formData.department;
    }

    if (formData.location) {
      editedEmployment.location = formData.location;
    }

    if (
      isCurrentEmployment === "Yes" &&
      editingValue.is_current_employment !== true
    ) {
      editedEmployment.is_current_employment = true;
    } else if (
      isCurrentEmployment === "No" &&
      editingValue.is_current_employment !== false
    ) {
      editedEmployment.is_current_employment = false;
    }

    if (employmentType !== editingValue.employment_type) {
      editedEmployment.employment_type = employmentType;
    }

    if (Object.keys(editedEmployment).length === 0) {
      toast.info("No changes detected.");
      return;
    }

    const accessToken = sessionStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/employment/${value}/`;

    axios
      .put(url, editedEmployment, config)
      .then((response) => {

        setFormData({
          currentCompanyName: "",
          previousCompanyName: "",
          previousjobTitle: "",
          previousjoiningMonth: "",
          previousjoiningYear: "",
          currentjobTitle: "",
          currentjoiningYear: "",
          currentjoiningMonth: "",
          currentSalary: "",
          noticePeriod: "",
          previousTillYear: "",
          previousTillMonth: "",
          currentlyWorking: "",
        });

        toast.success("Employment record updated successfully");
        // fetchProfileCompletion();
        dispatch(fetchStudentProfileData())
        dispatch(fetchStudentProfileCompletion())
        setisSubmittedEmployment(true);
        setIsEditModelOpenEmployment(false);
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message || "Error updating employment details"
        );
      });
  };

  const handleSubmitEmployment = async (e) => {
    e.preventDefault();

    if (validateEmployment()) {
      const accessToken = sessionStorage.getItem("accessToken");
      const userSessionData = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userSessionData);
      const userId = parsedData.id;

      const newEmployment = {
        is_current_employment: isCurrentEmployment === "Yes",
        employment_type: employmentType,
        current_company_name:
          isCurrentEmployment === "Yes"
            ? formData.currentCompanyName
            : formData.previousCompanyName,
        current_job_title:
          isCurrentEmployment === "Yes"
            ? formData.currentjobTitle
            : formData.previousjobTitle,
        joining_year:
          isCurrentEmployment === "Yes"
            ? formData.currentjoiningYear
            : formData.previousjoiningYear,
        joining_month:
          isCurrentEmployment === "Yes"
            ? formData.currentjoiningMonth
            : formData.previousjoiningMonth,
        current_salary:
          isCurrentEmployment === "Yes" ? formData.currentSalary : null,
        employment_notice_period:
          isCurrentEmployment === "Yes" ? formData.noticePeriod : null,
        worked_till_year:
          isCurrentEmployment === "No" ? formData.previousTillYear : null,
        worked_till_month:
          isCurrentEmployment === "No" ? formData.previousTillMonth : null,
        location: formData.location,
        department: formData.department,
      };

      const previousEmployments = NewupdatedData?.employments || [];
      const mergedEmployment = [...previousEmployments, newEmployment];

      const payload = {
        employments: mergedEmployment,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
          payload,
          config
        );

        toast.success("Added Successfully");
        dispatch(setGetStudentProfileData(response.data))
        setisSubmittedEmployment(true);
        setIsEditModelOpenEmployment(false);
        // fetchProfileCompletion();
        dispatch(fetchStudentProfileData())
        dispatch(fetchStudentProfileCompletion())

        setFormData({
          currentCompanyName: "",
          currentjobTitle: "",
          currentjoiningYear: "",
          currentjoiningMonth: "",
          currentSalary: "",
          noticePeriod: "",
          previousTillYear: "",
          previousTillMonth: "",
          currentlyWorking: "",
        });
      } catch (error) {
        console.error("Error in API:", error);
        toast.error(error.response?.data?.message || "Something went wrong");
      }
    } else {
      console.log("Form has errors");
    }
  };

  const handleEditEmploymentCancel = () => {
    setisSubmittedEmployment(true);
    setIsEditModelOpenEmployment(false);
  };

  const handleEditEmployment = (value) => {
    setEditingValue(value);
    setisSubmittedEmployment(false);
    setIsCurrentEmployment(value.is_current_employment ? "Yes" : "No");
    setEmploymentType(value.employment_type);
    setIsEditModelOpenEmployment(true);
    setFormData({
      currentCompanyName: "",
      currentjobTitle: "",
      currentjoiningYear: "",
      currentjoiningMonth: "",
      currentSalary: "",
      noticePeriod: "",
      previousTillYear: "",
      previousTillMonth: "",
      currentlyWorking: "",
    });
  };

  const handleDeleteEmployment = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this qualification?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/employment/${id}/`;
        const accessToken = sessionStorage.getItem('accessToken');

        axios.delete(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
          .then(response => {
            toast.success("Certification deleted successfully");
            // fetchData()
            dispatch(fetchStudentProfileData())
            dispatch(fetchStudentProfileCompletion())
          })
          .catch(error => {
            toast.error("Something Went Wrong");
          });
      }
    });
  }

  const getYearsRange = (startYear) => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= startYear; i--) {
      years.push(i);
    }
    return years;
  };

  const years = getYearsRange(1980);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const noticePeriods = [
    "0-7 days",
    "7-15 days",
    "15-30 days",
    "30-45 days",
    "More than 45 days",
  ];

  useEffect(() => {
  }, [editingValue]);

  return (
    <div className="">
      <div
        className=""
        style={
          isSubmittedEmployment
            ? { position: "relative" }
            : { display: "block" }
        }
      >
        <div className="qualification-form-container">
          <div className="epf_form_name_heading">
            <h2 className="epf-form-heading">
              {isSubmittedEmployment
                ? "Employment Details"
                : "Edit Employment Details"}
            </h2>
            <hr />
          </div>

          {isEditModelOpenEmployment ? (
            <div className="employment-details-container">
              <form
                onSubmit={(event) =>
                  handleEditEmploymentDetails(editingValue.id, event)
                }
              >
                {/* Is Current Employment */}
                <div className="form-section">
                  <b>Is this your current Company?</b>
                  <div className="radio-group">
                    <button
                      type="button"
                      className={`toggle-btn ${isCurrentEmployment === "Yes" ||
                        editingValue?.is_current_employment
                        ? "active"
                        : ""
                        }`}
                      onClick={() => setIsCurrentEmployment("Yes")}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={`toggle-btn ${isCurrentEmployment === "No" ||
                        editingValue?.is_current_employment === false
                        ? "active"
                        : ""
                        }`}
                      onClick={() => setIsCurrentEmployment("No")}
                    >
                      No
                    </button>
                  </div>
                </div>

                {/* Employment Type */}
                <div className="form-section">
                  <b>Employment Type</b>
                  <div className="radio-group">
                    <button
                      type="button"
                      className={`toggle-btn ${employmentType === "Full Time" ||
                        editingValue?.employment_type === "Full Time"
                        ? "active"
                        : ""
                        }`}
                      onClick={() => setEmploymentType("Full Time")}
                    >
                      Full Time
                    </button>
                    <button
                      type="button"
                      className={`toggle-btn ${employmentType === "Internship" ||
                        editingValue?.employment_type === "Internship"
                        ? "active"
                        : ""
                        }`}
                      onClick={() => setEmploymentType("Internship")}
                    >
                      Internship
                    </button>
                  </div>
                </div>

                {/* Current Employment Fields */}
                {(editingValue?.is_current_employment === true ||
                  isCurrentEmployment === "Yes") && (
                    <div className="epf-input-row">
                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="currentCompanyName"
                          name="currentCompanyName"
                          placeholder="Current Company Name"
                          value={
                            formData.currentCompanyName ||
                            editingValue?.current_company_name
                          }
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.currentCompanyName ? "input-error" : ""
                            }`}
                        />
                      </div>

                      <div className="epf-input-wrapper epf-input-gap">
                        <select
                          id="noticePeriod"
                          name="noticePeriod"
                          value={
                            formData.noticePeriod ||
                            editingValue?.employment_notice_period
                          }
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.noticePeriod ? "input-error" : ""
                            }`}
                        >
                          <option value="">Notice Period</option>
                          {noticePeriods.map((period, index) => (
                            <option key={index} value={period}>
                              {period}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="currentjoiningYear"
                            name="currentjoiningYear"
                            value={
                              formData.currentjoiningYear ||
                              editingValue?.joining_year
                            }
                            onChange={handleDateInputChange}
                            className={`epf-input-field ${errors.currentjoiningYear ? "input-error" : ""
                              }`}
                          >
                            <option value="">Joining Year</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="currentjoiningMonth"
                            name="currentjoiningMonth"
                            value={
                              formData.currentjoiningMonth ||
                              editingValue?.joining_month
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.currentjoiningMonth ? "input-error" : ""
                              }`}
                          >
                            <option value="">Joining Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="currentjobTitle"
                          name="currentjobTitle"
                          placeholder="Current Job Title"
                          value={
                            formData.currentjobTitle ||
                            editingValue?.current_job_title
                          }
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.currentjobTitle ? "input-error" : ""
                            }`}
                        />
                      </div>

                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="currentSalary"
                          name="currentSalary"
                          placeholder="Current Salary"
                          value={
                            formData.currentSalary ||
                            editingValue?.current_salary
                          }
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.currentSalary ? "input-error" : ""
                            }`}
                        />
                      </div>
                    </div>
                  )}

                {/* Previous Employment Fields */}
                {(editingValue?.is_current_employment === false ||
                  isCurrentEmployment === "No") && (
                    <div className="epf-input-row">
                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="previousCompanyName"
                          name="previousCompanyName"
                          placeholder="Previous Company Name"
                          value={
                            formData.previousCompanyName ||
                            editingValue?.current_company_name
                          }
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.previousCompanyName ? "input-error" : ""
                            }`}
                        />
                      </div>

                      <div className="epf-input-wrapper epf-input-gap">
                        <input
                          type="text"
                          id="previousjobTitle"
                          name="previousjobTitle"
                          placeholder="Current Job Title"
                          value={
                            formData.previousjobTitle ||
                            editingValue?.current_job_title
                          }
                          onChange={handleInputChange}
                          className={`epf-input-field ${errors.previousjobTitle ? "input-error" : ""
                            }`}
                        />
                      </div>

                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="previousjoiningYear"
                            name="previousjoiningYear"
                            value={
                              formData.previousjoiningYear ||
                              editingValue?.joining_year
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.previousjoiningYear ? "input-error" : ""
                              }`}
                          >
                            <option value="">Joining Year</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="previousjoiningMonth"
                            name="previousjoiningMonth"
                            value={
                              formData.previousjoiningMonth ||
                              editingValue?.joining_month
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.previousjoiningMonth ? "input-error" : ""
                              }`}
                          >
                            <option value="">Joining Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="previousTillYear"
                            name="previousTillYear"
                            value={
                              formData.previousTillYear ||
                              editingValue?.worked_till_year
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.previousTillYear ? "input-error" : ""
                              }`}
                          >
                            <option value="">Worked Till Year</option>
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="previousTillMonth"
                            name="previousTillMonth"
                            value={
                              formData.previousTillMonth ||
                              editingValue?.worked_till_month
                            }
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.previousTillMonth ? "input-error" : ""
                              }`}
                          >
                            <option value="">Worked Till Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="d-flex gap-4">
                  <button type="submit" className="epf-submit-btn mt-0">
                    Save
                  </button>
                  <button
                    onClick={handleEditEmploymentCancel}
                    className="epf-submit-btn mt-0"
                    style={{
                      background: "transparent",
                      border: "1px solid rgba(243, 141, 0, 1)",
                      color: "rgba(243, 141, 0, 1)",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          ) : null}

          {
            isEditModelOpenEmployment === false && (
              <div className="employment-details-container">
                <form onSubmit={handleSubmitEmployment}>
                  <div>
                    <div className="form-section">
                      <b>Employment Type</b>
                      <div className="radio-group">
                        <button
                          type="button"
                          className={`toggle-btn ${employmentType === "Full Time" ? "active" : ""
                            }`}
                          onClick={() => setEmploymentType("Full Time")}
                        >
                          Full Time
                        </button>
                        <button
                          type="button"
                          className={`toggle-btn ${employmentType === "Internship" ? "active" : ""
                            }`}
                          onClick={() => setEmploymentType("Internship")}
                        >
                          Internship
                        </button>
                      </div>
                    </div>

                    <div className="form-section">
                      <b>Is this your current Company?</b>
                      <div className="radio-group">
                        <button
                          type="button"
                          className={`toggle-btn ${isCurrentEmployment === "Yes" ? "active" : ""
                            }`}
                          onClick={() => setIsCurrentEmployment("Yes")}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className={`toggle-btn ${isCurrentEmployment === "No" ? "active" : ""
                            }`}
                          onClick={() => setIsCurrentEmployment("No")}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="epf-form-layout">
                    {isCurrentEmployment === "Yes" && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="currentCompanyName"
                            name="currentCompanyName"
                            placeholder="Current Company Name"
                            value={formData.currentCompanyName}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.currentCompanyName ? "input-error" : ""
                              }`}
                          />
                          {errors.currentCompanyName && (
                            <span className="error-text">
                              {errors.currentCompanyName}
                            </span>
                          )}
                        </div>

                        <div className="epf-input-wrapper epf-input-gap">
                          <select
                            id="noticePeriod"
                            name="noticePeriod"
                            value={formData.noticePeriod}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.noticePeriod && errors.noticePeriod
                              ? "input-error"
                              : ""
                              }`}
                          >
                            <option value="">Notice Period</option>
                            {noticePeriods.map((period, index) => (
                              <option key={index} value={period}>
                                {period}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="currentjoiningYear"
                              name="currentjoiningYear"
                              value={formData.currentjoiningYear}
                              onChange={handleDateInputChange}
                              className={`epf-input-field ${errors.currentjoiningYear &&
                                errors.currentjoiningYear
                                ? "input-error"
                                : ""
                                }`}
                            >
                              <option value="" label="Joining year" />
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.currentjoiningYear && (
                              <span className="error-text">
                                {errors.currentjoiningYear}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="currentjoiningMonth"
                              name="currentjoiningMonth"
                              value={formData.currentjoiningMonth}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.currentjoiningMonth &&
                                errors.currentjoiningMonth
                                ? "input-error"
                                : ""
                                }`}
                            >
                              <option value="">Joining Month</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    {isCurrentEmployment === "No" && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="previousCompanyName"
                            name="previousCompanyName"
                            placeholder="Previous Company Name"
                            value={formData.previousCompanyName}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.previousCompanyName &&
                              errors.previousCompanyName
                              ? "input-error"
                              : ""
                              }`}
                          />
                          {errors.previousCompanyName && (
                            <span className="error-text">
                              {errors.previousCompanyName}
                            </span>
                          )}
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="previousjoiningYear"
                              name="previousjoiningYear"
                              value={formData.previousjoiningYear}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousjoiningYear
                                ? "input-error"
                                : ""
                                }`}
                            >
                              <option value="" label="Joining year" />
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.previousCompanyName && (
                              <span className="error-text">
                                {errors.previousCompanyName}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="previousjoiningMonth"
                              name="previousjoiningMonth"
                              value={formData.previousjoiningMonth}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousjoiningMonth
                                ? "input-error"
                                : ""
                                }`}
                            >
                              <option value="">Joining Month</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    {isCurrentEmployment === "No" && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="previousjobTitle"
                            name="previousjobTitle"
                            placeholder="Previous Job Title"
                            value={formData.previousjobTitle}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.previousjobTitle ? "input-error" : ""
                              }`}
                          />
                          {errors.previousjobTitle && (
                            <span className="error-text">
                              {errors.previousjobTitle}
                            </span>
                          )}
                        </div>

                        <div className="d-flex justify-content-between align-items-center gap-2">
                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="previousTillYear"
                              name="previousTillYear"
                              value={formData.previousTillYear}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousTillYear ? "input-error" : ""
                                }`}
                            >
                              <option value="" label="Worked Till Year" />
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.previousTillYear && (
                              <span className="error-text">
                                {errors.previousTillYear}
                              </span>
                            )}
                          </div>

                          <div className="epf-input-wrapper epf-input-gap">
                            <select
                              id="previousTillMonth"
                              name="previousTillMonth"
                              value={formData.previousTillMonth}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.previousTillMonth
                                ? "input-error"
                                : ""
                                }`}
                            >
                              <option value="">Worked Till Month</option>
                              {months.map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                            {errors.previousTillMonth && (
                              <span className="error-text">
                                {errors.previousTillMonth}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {isCurrentEmployment === "Yes" && (
                      <div className="epf-input-row">
                        <div className="epf-input-wrapper epf-input-gap">
                          <input
                            type="text"
                            id="currentjobTitle"
                            name="currentjobTitle"
                            placeholder="Current Job Title"
                            value={formData.currentjobTitle}
                            onChange={handleInputChange}
                            className={`epf-input-field ${errors.currentjobTitle ? "input-error" : ""
                              }`}
                          />
                          {errors.currentjobTitle && (
                            <span className="error-text">
                              {errors.currentjobTitle}
                            </span>
                          )}
                        </div>

                        <div className="epf-input-wrapper epf-input-gap d-flex justify-content-between align-items-center gap-2 currency_main_container">

                          <div
                            className="epf-input-wrapper epf-input-gap pb-0"
                            style={{ width: "100%" }}
                          >
                            <input
                              type="text"
                              id="currentSalary"
                              name="currentSalary"
                              placeholder="Current Salary In Rupees"
                              value={formData.currentSalary}
                              onChange={handleInputChange}
                              className={`epf-input-field ${errors.currentSalary ? "input-error" : ""
                                }`}
                            />
                            {errors.currentSalary && (
                              <span className="error-text">
                                {errors.currentSalary}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <button type="submit" className="epf-submit-btn">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            )}
        </div>
      </div>

      {NewupdatedData?.employments?.length > 0 && (<div>
        <div className="pb-4 pt-4">
          <p>My Employments Details
          </p>
        </div>
        {NewupdatedData?.employments && NewupdatedData?.employments?.map((value, index) => (
          (<div key={index} className="company-info student_company_info">
            <div className="d-flex align-items-center gap-2">
              <img src={dummy_logo} alt={`logo`} className="company-logo" />
              <div>
                <h2>{value.current_company_name}</h2>
                <h7>
                  {value.current_job_title}
                  <span style={{ fontWeight: '400', fontSize: '14px' }}>{`(${value.employment_type})`}</span>
                </h7>
                <div className="d-flex gap-2 jdrc_class">
                  <div className="company-name">
                    {value.joining_month} {value.joining_year} - {value.is_current_employment === true ? 'Present' : `${value.worked_till_year} ${value.worked_till_month}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <div onClick={() => handleDeleteEmployment(value.id)} style={{ cursor: "pointer" }}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.9561 4.07812C13.9412 4.07812 13.9189 4.07812 13.8966 4.07812C9.95984 3.6837 6.03055 3.53486 2.13847 3.92928L0.620335 4.07812C0.307778 4.10788 0.0324298 3.88463 0.00266245 3.57207C-0.0271049 3.25951 0.19615 2.99161 0.501265 2.96184L2.0194 2.813C5.97846 2.41115 9.9896 2.56742 14.0082 2.96184C14.3133 2.99161 14.5366 3.26696 14.5068 3.57207C14.4845 3.8623 14.2389 4.07812 13.9561 4.07812Z" fill="#F38D00" />
                  <path d="M4.65464 3.3265C4.62487 3.3265 4.5951 3.3265 4.55789 3.31906C4.26022 3.26697 4.05185 2.97673 4.10394 2.67906L4.26766 1.70418C4.38673 0.989764 4.55045 0 6.2844 0H8.23416C9.97555 0 10.1393 1.02697 10.2509 1.71162L10.4146 2.67906C10.4667 2.98418 10.2583 3.27441 9.96066 3.31906C9.65555 3.37115 9.36532 3.16278 9.32067 2.86511L9.15695 1.89767C9.05276 1.25023 9.03043 1.12372 8.2416 1.12372H6.29184C5.50301 1.12372 5.48812 1.2279 5.3765 1.89023L5.20533 2.85766C5.16068 3.13301 4.92254 3.3265 4.65464 3.3265Z" fill="#F38D00" />
                  <path d="M9.64788 16H4.87022C2.27302 16 2.16884 14.5637 2.08698 13.4028L1.60326 5.90889C1.58093 5.60378 1.81907 5.33587 2.12419 5.31355C2.43674 5.29866 2.69721 5.52936 2.71953 5.83448L3.20325 13.3284C3.28511 14.4596 3.31488 14.8837 4.87022 14.8837H9.64788C11.2107 14.8837 11.2404 14.4596 11.3149 13.3284L11.7986 5.83448C11.8209 5.52936 12.0888 5.29866 12.3939 5.31355C12.699 5.33587 12.9372 5.59634 12.9148 5.90889L12.4311 13.4028C12.3493 14.5637 12.2451 16 9.64788 16Z" fill="#F38D00" />
                  <path d="M8.49403 11.9069H6.0159C5.71079 11.9069 5.45776 11.6539 5.45776 11.3488C5.45776 11.0437 5.71079 10.7906 6.0159 10.7906H8.49403C8.79915 10.7906 9.05217 11.0437 9.05217 11.3488C9.05217 11.6539 8.79915 11.9069 8.49403 11.9069Z" fill="#F38D00" />
                  <path d="M9.11914 8.93024H5.39823C5.09311 8.93024 4.84009 8.67722 4.84009 8.3721C4.84009 8.06699 5.09311 7.81396 5.39823 7.81396H9.11914C9.42426 7.81396 9.67728 8.06699 9.67728 8.3721C9.67728 8.67722 9.42426 8.93024 9.11914 8.93024Z" fill="#F38D00" />
                </svg>
              </div>

              <div onClick={() => handleEditEmployment(value)} style={{ cursor: "pointer" }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.1138 16H5.70051C1.70648 16 0 14.2935 0 10.2995V5.88618C0 1.89214 1.70648 0.185669 5.70051 0.185669H7.17161C7.47318 0.185669 7.72327 0.435756 7.72327 0.737331C7.72327 1.03891 7.47318 1.28899 7.17161 1.28899H5.70051C2.30963 1.28899 1.10332 2.49529 1.10332 5.88618V10.2995C1.10332 13.6904 2.30963 14.8967 5.70051 14.8967H10.1138C13.5047 14.8967 14.711 13.6904 14.711 10.2995V8.82838C14.711 8.5268 14.9611 8.27672 15.2627 8.27672C15.5642 8.27672 15.8143 8.5268 15.8143 8.82838V10.2995C15.8143 14.2935 14.1078 16 10.1138 16Z" fill="#222222" />
                  <path d="M5.33224 12.2782C4.88356 12.2782 4.47165 12.1163 4.17007 11.8221C3.80965 11.4617 3.65519 10.9395 3.7361 10.3878L4.05239 8.17379C4.11123 7.74718 4.39074 7.19551 4.69231 6.89394L10.4884 1.09781C11.9522 -0.365936 13.438 -0.365936 14.9017 1.09781C15.7035 1.89956 16.0639 2.71602 15.9904 3.53248C15.9242 4.19447 15.5711 4.84176 14.9017 5.50375L9.10561 11.2999C8.80404 11.6015 8.25237 11.881 7.82576 11.9398L5.61175 12.2561C5.51613 12.2782 5.42051 12.2782 5.33224 12.2782ZM11.2681 1.87749L5.472 7.67362C5.33224 7.81338 5.17042 8.13702 5.141 8.32826L4.82471 10.5423C4.79529 10.7556 4.83942 10.9321 4.94976 11.0424C5.06009 11.1528 5.23662 11.1969 5.44993 11.1675L7.66393 10.8512C7.85518 10.8218 8.18618 10.66 8.31857 10.5202L14.1147 4.72407C14.5928 4.24596 14.8429 3.81934 14.8797 3.42214C14.9238 2.94404 14.6737 2.43651 14.1147 1.87013C12.9378 0.693256 12.1287 1.02425 11.2681 1.87749Z" fill="#222222" />
                  <path d="M13.681 6.49694C13.6295 6.49694 13.578 6.48959 13.5339 6.47488C11.5994 5.93057 10.0621 4.39327 9.51776 2.45877C9.43685 2.16455 9.60602 1.86298 9.90024 1.77471C10.1945 1.6938 10.496 1.86298 10.5769 2.1572C11.0183 3.72392 12.2614 4.967 13.8281 5.40833C14.1223 5.48924 14.2915 5.79817 14.2106 6.09239C14.1444 6.34248 13.9237 6.49694 13.681 6.49694Z" fill="#222222" />
                </svg>
              </div>
            </div>
          </div>
          )))}
      </div>)}

    </div>
  )
}

export default StudentExperiance;