import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../../styles/admin/setting.css";

function Settings() {
  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      console.log("Email Form Data:", values);
    },
  });

  const passwordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current password is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      console.log("Password Form Data:", values);
    },
  });

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="admin_settings_container">
          <div className="admin_settings_headings">
            <h6>Login Details</h6>
          </div>
          <hr />

          <div className="admin_settings_main_sections_heading">
            <div className="admin_settings_basic_info_container">
              <h6>Basic Information</h6>
              <p>
                This is your personal information that you can update anytime.
              </p>
            </div>

            {/* Email Update Form */}
            <div className="admin_settings_basic_form_container">
              <div className="admin_settings_basic_form_heading">
                <h6>Update Email</h6>
                <p>Your current email is (yourmail@gmail.com)</p>
              </div>
              <form
                className="admin_settings_basic_form"
                onSubmit={emailFormik.handleSubmit}
              >
                <div className="admin_settings_basic_form_details">
                  <div className="input-details">
                    <input
                      name="email"
                      type="email"
                      placeholder="Enter your abc@gmail.com"
                      value={emailFormik.values.email}
                      onChange={emailFormik.handleChange}
                      onBlur={emailFormik.handleBlur}
                    />

                    {emailFormik.touched.email && emailFormik.errors.email ? (
                      <span className="error-admin">
                        {emailFormik.errors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="admin_settings_form_submit_btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>

            {/* Password Update Form */}
            <div className="admin_settings_basic_form_container">
              <div className="admin_settings_basic_form_heading">
                <h6>Password</h6>
                <p>
                  If you wish to change your password, you can change it here.
                </p>
              </div>
              <form
                className="admin_settings_basic_form"
                onSubmit={passwordFormik.handleSubmit}
              >
                <div className="admin_settings_basic_form_details">
                  <div className="input-details">
                    <input
                      name="currentPassword"
                      type="password"
                      placeholder="Enter your current password"
                      value={passwordFormik.values.currentPassword}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.touched.currentPassword &&
                    passwordFormik.errors.currentPassword ? (
                      <div className="error-admin">
                        {passwordFormik.errors.currentPassword}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-details">
                    <input
                      name="newPassword"
                      type="password"
                      placeholder="Enter new password"
                      value={passwordFormik.values.newPassword}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.touched.newPassword &&
                    passwordFormik.errors.newPassword ? (
                      <div className="error-admin">
                        {passwordFormik.errors.newPassword}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-details">
                    <input
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirm password"
                      value={passwordFormik.values.confirmPassword}
                      onChange={passwordFormik.handleChange}
                      onBlur={passwordFormik.handleBlur}
                    />
                    {passwordFormik.touched.confirmPassword &&
                    passwordFormik.errors.confirmPassword ? (
                      <div className="error-admin">
                        {passwordFormik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="admin_settings_form_submit_btn">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>

          <div className="admin_notifications">
            <div className="admin_notification_one">
              <h6>Notifications</h6>
              <p>
                This is your personal information that you can update anytime.
              </p>
            </div>
            <div className="admin_notification_one checkbox-wrapper">
              <input
                className="tgl tgl-light"
                id="admin_toggle"
                type="checkbox"
              />
              <label className="tgl-btn" htmlFor="admin_toggle"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
