import "../../../styles/dashboards/campusecandidaterequest.css";
import "../../../styles/admin/adminRequest.css";

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import filterIcon from "../../../utils/dashboards/filterIcon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import ThreeDot from "../../../utils/dashboards/three-dot-action-icon.svg";
import { Pagination } from "@mui/material";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";

export default function AdminRequest() {
  const location = useLocation();
  const pathname = location.pathname;
  const [pendingCompanies, setPendingCompanies] = useState([]);
  const [rejectedCompanies, setRejectedCompanies] = useState([]);
  const [pendingColleges, setPendingColleges] = useState([]);
  const [rejectedColleges, setRejectedColleges] = useState([]);
  const [activeTab, setActiveTab] = useState("pending");
  const [selectedType, setSelectedType] = useState("company");
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingApproveUserId, setLoadingApproveUserId] = useState(null);
  const [loadingRejectUserId, setLoadingRejectUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hanvdleOpenActionUserId, setHanvdleOpenActionUserId] = useState(null);
  const [isDetailId, setIsDetailId] = useState(null);
  const [finalCardData, setFinalCardData] = useState([]);
  const [filterCardData, setFilterCardData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemParPage = 10;

  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Pending");

  const token = sessionStorage.getItem("accessToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
    setFinalCardData(filteredRequests(value));
    setCurrentPage(1);
  };

  const fetchRequests = useCallback(async () => {
    setLoading(true);
    try {
      const [
        pendingCompaniesResponse,
        pendingCollegesResponse,
        rejectedCompaniesResponse,
        rejectedCollegesResponse,
      ] = await Promise.all([
        axios.get(`${baseUrl}/api/pending-companies/`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${baseUrl}/api/pending-colleges/`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${baseUrl}/api/rejected-companies-list/`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${baseUrl}/api/rejected-colleges-list/`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      setPendingCompanies(pendingCompaniesResponse.data);
      setRejectedCompanies(rejectedCompaniesResponse.data);
      setPendingColleges(pendingCollegesResponse.data);
      setRejectedColleges(rejectedCollegesResponse.data);
    } catch (error) {
      console.error("Error fetching requests:", error);
      toast.error("Failed to fetch requests.");
    } finally {
      setLoading(false);
    }
  }, [baseUrl, token]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  const getName = (user) => {
    if (selectedType === "company") {
      return user.company_name || "Unknown Company";
    } else if (selectedType === "college") {
      return user.college_name || "Unknown College";
    }
    return "Unknown";
  };

  const getLocation = (user) => {
    if (selectedType === "company") {
      return user.location || "Unknown Company";
    } else if (selectedType === "college") {
      return user?.college_details?.district_name || "Unknown College";
    }
    return "Unknown";
  };

  const handleAction = async (id, type, action) => {
    const actionType =
      action === "approve" ? `approve/${type}` : `reject-${type}`;
    const actionMessage = action === "approve" ? "Approved" : "Rejected";
    const actionEndpoint = `${baseUrl}/api/${actionType}/${id}/`;

    const setLoadingState =
      action === "approve" ? setLoadingApproveUserId : setLoadingRejectUserId;
    const toastMessage = `${actionMessage} ${type} request.`;

    setLoadingState(id);

    try {
      const response = await axios.get(actionEndpoint, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        fetchRequests();
        setShowModal(false);
        toast.success(toastMessage);
      }
    } catch (error) {
      console.error(`${actionMessage} request failed:`, error);
      toast.error(`Failed to ${actionType} request.`);
    } finally {
      setLoadingState(null);
    }
  };

  const handleApprove = (id, type) => handleAction(id, type, "approve");

  const handleReject = (id, type) => handleAction(id, type, "reject");

  const handleViewDetails = async (id) => {
    try {
      setIsDetailId(id);
      const response = await axios.get(
        `${baseUrl}/api/details_${selectedType}/${id}/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDetails(response.data);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching details:", error);
      toast.error("Failed to fetch details.");
    }
  };
  useEffect(() => {
    const requestType = getRequestType(pathname);
    if (requestType === "Collage") {
      setSelectedType("college");
    } else if (requestType === "Company") {
      setSelectedType("company");
    }
  }, [pathname]);

  useEffect(() => {
    const getData = (type, tab) => {
      const dataMap = {
        company: {
          rejected: rejectedCompanies,
          pending: pendingCompanies,
        },
        college: {
          rejected: rejectedColleges,
          pending: pendingColleges,
        },
      };

      return dataMap[type]?.[tab] || [];
    };

    const updatedData = getData(selectedType, activeTab);
    setFinalCardData(updatedData);
    setFilterCardData(updatedData);
  }, [
    selectedType,
    activeTab,
    pendingCompanies,
    pendingColleges,
    rejectedCompanies,
    rejectedColleges,
  ]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemParPage;
    const endIndex = startIndex + itemParPage;
    setCurrentItem(finalCardData.slice(startIndex, endIndex));
  }, [currentPage, finalCardData]);

  const filteredRequests = (searchQuery) => {
    return filterCardData.filter((user) =>
      getName(user).toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  };

  const renderNoRequestsMessage = () => (
    <div className="no-requests-message">No requests found.</div>
  );
  const getRequestType = (pathname) => {
    if (pathname === "/dashboard/sidebar-page/admin/collage-request") {
      return "Collage";
    } else if (pathname === "/dashboard/sidebar-page/admin/company-request") {
      return "Company";
    } else {
      return "";
    }
  };

  const toggleDropdown = (userId) => {
    setHanvdleOpenActionUserId((prevUserId) =>
      prevUserId === userId ? null : userId
    );
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const DetailsForm = ({ details, type }) => (
    <Form className="admin_dashboard_modal_form">
      <Form.Group
        className="mb-3 admin_dashboard_modal_field"
        controlId="formBasicName"
      >
        <Form.Control type="text" value={details.name} readOnly />
      </Form.Group>

      <Form.Group
        className="mb-3 admin_dashboard_modal_field"
        controlId="formBasicLastName"
      >
        <Form.Control
          type="text"
          value={
            type === "company"
              ? details.company_last_name
              : details.college_lastname
          }
          readOnly
        />
      </Form.Group>

      <Form.Group
        className="mb-3 admin_dashboard_modal_field"
        controlId="formBasicEntityName"
      >
        <Form.Control
          type="text"
          value={
            type === "company" ? details.company_name : details.collegename
          }
          readOnly
        />
      </Form.Group>

      <Form.Group
        className="mb-3 admin_dashboard_modal_field"
        controlId="formBasicEmail"
      >
        <Form.Control type="text" value={details.email} readOnly />
      </Form.Group>

      <Form.Group
        className="mb-3 admin_dashboard_modal_field"
        controlId="formBasicCity"
      >
        <Form.Control
          type="text"
          value={
            type === "company"
              ? details.company_city
              : `${details.college_api?.district_name} ${details.college_api?.state_name}`
          }
          readOnly
        />
      </Form.Group>

      <Form.Group
        className="mb-3 admin_dashboard_modal_field"
        controlId="formBasicMobile"
      >
        <Form.Control type="text" value={details.all_mobno} readOnly />
      </Form.Group>
    </Form>
  );

  const RequestCard = ({ user }) => {
    const isLoadingApprove = loadingApproveUserId === user.id;
    const isLoadingReject = loadingRejectUserId === user.id;
    const isDropdownOpen = hanvdleOpenActionUserId === user.id;
    return (
      <div className="dashboard_request_list">
        <div className="dashboard_request_list_card">
          <div>
            <h4>{getName(user)}</h4>
            <p>{getLocation(user)}</p>
          </div>
          <div className="dashboard_request_actions">
            <div className="dashboard_request_actions_button_modal">
              <button
                className="dashborad_request_approve_button"
                onClick={() => handleApprove(user.id, selectedType)}
                disabled={isLoadingApprove}
                style={{ width: activeTab === "rejected" ? "143px" : "108px" }}
              >
                {!showModal && isLoadingApprove ? (
                  <span className="loader"></span>
                ) : activeTab === "rejected" ? (
                  "Re-Approve"
                ) : (
                  "Approve"
                )}
              </button>
              <button
                className="dashboard_request_reject_button "
                onClick={() => handleReject(user.id, selectedType)}
                disabled={isLoadingReject || activeTab === "rejected"}
                style={{
                  cursor: activeTab === "rejected" ? "unset" : "pointer",
                }}
              >
                {!showModal && isLoadingReject ? (
                  <span className="loader"></span>
                ) : activeTab === "rejected" ? (
                  "Rejected"
                ) : (
                  "Reject"
                )}
              </button>
            </div>
            <div
              // ref={actiondropdown}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown(user.id);
              }}
              className="dropdown"
            >
              <img src={ThreeDot} alt="action Icon" />
              <div
                className={`dropdown_content_actions ${
                  isDropdownOpen ? "show_active" : "show_inactive"
                }`}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    handleViewDetails(user.id);
                  }}
                >
                  View
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSelectClick = () => {
    setIsActive(!isActive);
  };

  const handleOptionClick = (option, item) => {
    setActiveTab(option);
    setSelectedOption(item);
    setIsActive(false);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="campuse_student_request_container">
            <div className="campuse_student_request_header">
              <h3>All {getRequestType(location.pathname)} Applications</h3>

              <div className="search_input_container">
                <div className="campuse_student_request_search_bar">
                  <img src={searchLogo} alt="search" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                {/* <div className="campuse_student_request_dropdown">
                  <img src={filterIcon} alt="Filter Icon" />
                  <select
                    className="campuse_student_request_dropdown_section"
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    <option value="pending">Pending ▼</option>
                    <option value="rejected">Rejected ▼</option>
                  </select>
                </div> */}
                <div
                  className={`request_select_menu_dropdown ${
                    isActive ? "select_active" : ""
                  }`}
                >
                  <div
                    className="select_btn_dropdown"
                    onClick={handleSelectClick}
                  >
                    <img src={filterIcon} alt="Filter Icon" />
                    <span className="select_dropdown_text">
                      {selectedOption}
                    </span>
                    {isActive ? "▲" : "▼"}
                  </div>

                  {isActive && (
                    <ul className="select_dropdown_options_list">
                      <li
                        className="select_dropdown_option"
                        onClick={() => handleOptionClick("pending", "Pending")}
                      >
                        Pending
                      </li>
                      <li
                        className="select_dropdown_option"
                        onClick={() =>
                          handleOptionClick("rejected", "Rejected")
                        }
                      >
                        Rejected
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="campuse_student_request_body">
              <div className="campuse_student_request_sub_container">
                {loading ? (
                  <div className="loading-message">Loading...</div>
                ) : finalCardData.length === 0 ? (
                  renderNoRequestsMessage()
                ) : (
                  <div className="request-cards">
                    {currentItem.map((user) => (
                      <RequestCard key={user.id} user={user} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {finalCardData.length > 10 && (
          <div className="d-flex justify-content-center pagination_custom">
            <Pagination
              count={Math.ceil(finalCardData.length / itemParPage)}
              page={currentPage}
              color="primary"
              className="mt-3"
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="modal_admin_request"
      >
        <Modal.Body>
          {details ? (
            <>
              <div
                className="text-center"
                style={{ padding: "1rem", fontSize: "2rem" }}
              >
                {selectedType === "company"
                  ? "Company Details"
                  : "College Details"}
              </div>
              <DetailsForm details={details} type={selectedType} />
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="dashboard_request_actions_button_modal">
            <button
              className="dashborad_request_approve_button"
              onClick={() => handleApprove(isDetailId, selectedType)}
              disabled={loadingApproveUserId === isDetailId}
              //  style={{width: activeTab=="rejected"?"143px": "108px"  }}
            >
              {loadingApproveUserId === isDetailId ? (
                <span className="loader"></span>
              ) : activeTab === "rejected" ? (
                "Re-Approve"
              ) : (
                "Approve"
              )}
            </button>
            <button
              className="dashboard_request_reject_button"
              style={{ cursor: activeTab === "rejected" ? "unset" : "pointer" }}
              onClick={() => handleReject(isDetailId, selectedType)}
              disabled={
                loadingRejectUserId === isDetailId || activeTab === "rejected"
              }
            >
              {loadingRejectUserId === isDetailId ? (
                <span className="loader"></span>
              ) : activeTab === "rejected" ? (
                "Rejected"
              ) : (
                "Reject"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
