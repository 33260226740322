import React from "react";

function TransactionHistory() {
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        TransactionHistory
      </div>
    </div>
  );
}

export default TransactionHistory;
