import { createSlice } from "@reduxjs/toolkit";

// Slice Definition
const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    loginResponse: {},
    roleName: "",
  },
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    logOut: (state, action) => {
      state.user = action.payload;
    },
    logInReducerResponse: (state, actions) => {
      state.loginResponse = actions.payload;
    },
    setRole: (state, action) => {
      state.roleName = action.payload;
    },
  },
});

// Export Actions
export const { loading, logOut, logInReducerResponse, setRole } =
  authSlice.actions;

// Export Reducer
export default authSlice.reducer;
