import "../../../styles/landing_page/campus.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Testimonial from "../../common_component/testimonial/testimonial";
import Counter from "../../common_component/counter/counter";
import SerchIcon from "../../../utils/landingpage/images/searchIcon.svg";
import ComapanyIcon from "../../../utils/landingpage/images/companyIcon.svg";
import campusLocationIcon from "../../../utils/landingpage/images/campusLocationIcon.svg";
import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";

const Campuses = () => {

  const baseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    axios
      .get(`${baseURL}/api/college_profile/`)
      .then((res) => {
        setData(res.data);
        console.log('---collage res', res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [baseURL]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Filter data based on search query
  const filteredData = data?.filter((college) =>
    college.college_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current items
  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Function to handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <section className="about_main">
        <div className="">
          <div className="about_header text-center">
            {/* <h4>Campuses</h4> */}
            <h2>campuses hiring at your fingertips<br /> through 40k colleges nationwide</h2>
            <h6>100% digital campus engagement for apprenticeships, internships, fresher hiring, training webinars, and podcasts.</h6>
            <button className="btn btn-primary">Book a Demo/Register Here</button>
          </div>
        </div>
      </section>
      {/* First section end */}

      <section className="campuses_main">
        <div className="campuses_semi">
          <div className="campus_first_container pb-4">
            <div className="campus_first_cont_first">
              <h1>Our Campuses</h1>
            </div>
            <div className="campus_first_cont_sec">
              <b>
                Connecting talent with opportunity through a seamless,
                efficient, and innovative digital ecosystem
              </b>
            </div>
            <div className="campus_first_cont_first_input">
              <input
                type="text"
                placeholder="Find colleges and Universities"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query on change
              />
              <button>
                <img src={SerchIcon} alt="..." />
              </button>
            </div>
          </div>
          <div className="row pt-4 pb-4">
            {currentItems &&
              currentItems.map((req, index) => (
                <div key={index} className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="campus_second_container">
                    <div className="d-flex align-items-center gap-4 custom-width-80">
                      <div className="campus_second_company_logo">
                        <img src={req.college_logo} alt="..." />
                      </div>
                      <div className="campus_second_company_name">
                        <b>{req.college_name?.length > 40 ? `${req.college_name.slice(0, 40)}...` : req.college_name }</b>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
                        <p>College Type</p>
                        <b>{req.college_type?.slice(0, 11)}</b>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
                        <p>Total Students</p>
                        <b>{req.total_student?.slice(0, 10)}</b>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
                        <p>Established Year</p>
                        <b>{req.established_year?.slice(0, 10)}</b>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
                        <p>Placement Record</p>
                        <b>{req.placement_record?.slice(0, 10)}</b>
                      </div>
                    </div>
                    <div className="d-flex gap-sm-0 gap-3 flex-sm-row justify-content-sm-between align-items-sm-center flex-column">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={campusLocationIcon}
                          width="14px"
                          height="16px"
                          alt=".."
                        />
                        <b className="campus-location fw-semibold fs-8">
                          Location - {req.location?.length > 22
                            ? `${req.location.slice(0, 22)}...`
                            : req.location}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {/* Pagination Controls */}
          <div className="pagination-controls">
            <Stack spacing={2} alignItems="center">
              <Pagination
                className="custom-pagination"
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                siblingCount={1}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      previous: () => (
                        <img
                          className="campus-next-btn-pagination"
                          src={leftArrowIcon}
                          alt="prev"
                        />
                      ),
                      next: () => (
                        <img
                          className="campus-prev-btn-pagination"
                          src={rightIconArrow}
                          alt="next"
                        />
                      ),
                    }}
                    {...item}
                  />
                )}
              />
            </Stack>
          </div>
        </div>
      </section>

      <Counter />

      <Testimonial />

    </>
  );
};

export default Campuses;
// import { useState } from "react";
// import { useEffect } from "react";
// import axios from "axios";
// import Testimonial from "../../common_component/testimonial/testimonial";
// import Counter from "../../common_component/counter/counter";
// import SerchIcon from "../../../utils/landingpage/images/searchIcon.svg";
// import ComapanyIcon from "../../../utils/landingpage/images/companyIcon.svg";
// import campusLocationIcon from "../../../utils/landingpage/images/campusLocationIcon.svg";
// import rightArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
// import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
// import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import { PaginationItem } from "@mui/material";

// const Campuses = () => {

//   const baseURL = process.env.REACT_APP_BASE_URL;
//   const [data, setData] = useState()

//   useEffect(() => {
//     axios
//       .get(`${baseURL}/api/college_profile/`)
//       .then((res) => {
//         setData(res.data);
//         console.log('---collage res', res.data)
//       })
//       .catch((error) => {
//         console.log(error);
//       })
//   }, [])

//   // State for pagination
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 6;

//   // Calculate the total number of pages
//   const totalPages = Math.ceil(data && data.length / itemsPerPage);

//   // Get the current items
//   const currentItems = data?.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   // Function to handle page change
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [])

//   return (
//     <>

//       <section className="about_main">
//         <div className="">
//           <div className="about_header text-center">
//             <h4>Campuses</h4>
//             <h2>Our Placement Campuses</h2>
//             <h6>Let us know how we can help you.</h6>
//             <button className="btn btn-primary">Book a Demo</button>
//           </div>
//         </div>
//       </section>
//       {/* First section end */}
   
//       <section className="campuses_main">
//         <div className="campuses_semi">
//           <div className="campus_first_container pb-4">
//             <div className="campus_first_cont_first">
//               <h1>Our Campuses</h1>
//             </div>
//             <div className="campus_first_cont_sec">
//               <b>
//                 Connecting talent with opportunity through a seamless,
//                 efficient, and innovative digital ecosystem
//               </b>
//             </div>
//             <div className="campus_first_cont_first_input">
//               <input type="text" placeholder="Find colleges and Universities" />
//               <button>
//                 <img src={SerchIcon} alt="..." />
//               </button>
//             </div>
//           </div>
//           <div className="row pt-4 pb-4">
//             {currentItems &&
//               currentItems.map((req, index) => (
//                 <div key={index} className="col-lg-6 col-md-6 col-sm-12 mb-4">
//                   <div className="campus_second_container">
//                     <div className="d-flex align-items-center gap-4 custom-width-80">
//                       <div className="campus_second_company_logo">
//                         <img src={req.college_logo} alt="..." />
//                       </div>
//                       <div className="campus_second_company_name">
//                         <b>{req.college_name?.length > 40 ? `${req.college_name.slice(0, 40)}...` : req.college_name }</b>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
//                         <p>College Type</p>
//                         <b>{req.college_type?.slice(0, 11)}</b>
//                       </div>
//                       <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
//                         <p>Total Students</p>
//                         <b>{req.total_student?.slice(0, 10)}</b>
//                       </div>
//                       <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
//                         <p>Established Year</p>
//                         <b>{req.established_year?.slice(0, 10)}</b>
//                       </div>
//                       <div className="col-lg-3 col-sm-6 col-6 campus_second_collages_details">
//                         <p>Placement Record</p>
//                         <b>{req.placement_record?.slice(0, 10)}</b>
//                       </div>
//                     </div>
//                     <div className="d-flex gap-sm-0 gap-3 flex-sm-row justify-content-sm-between align-items-sm-center flex-column">
//                       <div className="d-flex align-items-center gap-2">
//                         <img
//                           src={campusLocationIcon}
//                           width="14px"
//                           height="16px"
//                           alt=".."
//                         />
//                         <b className="campus-location fw-semibold fs-8">
//                           Location - {req.location?.length > 22
//                             ? `${req.location.slice(0, 22)}...`
//                             : req.location}
//                         </b>
//                       </div>
//                       {/* <div className="campus_second_more_info">
//                         <a href="#">
//                           More Info{" "}
//                           <span className="pl-1">
//                             <img src={rightArrow} alt="" />
//                           </span>{" "}
//                         </a>
//                       </div> */}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//           </div>
//           {/* Pagination Controls */}
//           <div className="pagination-controls">
//             <Stack spacing={2} alignItems="center">
//               <Pagination
//                 className="custom-pagination" // Add the custom class here
//                 count={totalPages}
//                 page={currentPage}
//                 onChange={handlePageChange}
//                 color="primary"
//                 siblingCount={1}
//                 boundaryCount={1}
//                 renderItem={(item) => (
//                   <PaginationItem
//                     components={{
//                       previous: () => (
//                         <img
//                           className="campus-next-btn-pagination"
//                           src={leftArrowIcon}
//                           alt="prev"
//                         />
//                       ),
//                       next: () => (
//                         <img
//                           className="campus-prev-btn-pagination"
//                           src={rightIconArrow}
//                           alt="next"
//                         />
//                       ),
//                     }}
//                     {...item}
//                   />
//                 )}
//               />
//             </Stack>
//           </div>
//         </div>
//       </section>

//       <Counter />

//       <Testimonial />

//     </>
//   );
// };

// export default Campuses;