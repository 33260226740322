import React, { useState } from 'react';
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import '../../../styles/dashboards/clgstudent/studentinterview.css';
import { setMyInterviewDetails } from '../../../redux/reducers/student_profile_reducer';
import { useDispatch } from 'react-redux';

const InterviewDetails = ({ data }) => {
    const [roundActive, setRoundActive] = useState(`Round ${data?.interview_round}`);

    const handleClickRoundActive = (round) => {
        setRoundActive(round);
    }

    const dispatch = useDispatch()

    const formatDateTime = (dateTime) => {
        const dateObj = new Date(dateTime);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        const date = dateObj.toLocaleDateString("en-US", options);

        const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
        const time = dateObj.toLocaleTimeString("en-US", timeOptions);

        return { date, time };
    };

    const { date, time } = formatDateTime(data?.interview_date);

    const generateRounds = (noOfRounds) => {
        const rounds = Array.from({ length: noOfRounds }, (_, index) => `Round ${index + 1}`);
        return rounds;
    };

    const rounds = generateRounds(data?.job_details.no_of_interview_round);

    return (
        <div className="interviewDetails-container">
            <div className="interviewDetails-header">
                <button
                    className="interviewDetails-back-button"
                    onClick={() => dispatch(setMyInterviewDetails(false))}
                >
                    <img src={leftArrowIcon} alt="Back" />
                </button>
                <h5 className="interviewDetails-company">{data?.job_details?.company}</h5>
            </div>
            <div className="interviewDetails-rounds">
                <div className="interviewDetails-rounds-container rounded-t-lg bg-gray-800 text-white flex justify-between px-4 py-3">
                    {rounds && rounds?.map((val, idx) => (
                        <button
                            key={idx}
                            className={`interviewDetails-round-button ${roundActive === val ? "student-round-active" : "student_disabled_tabs"} px-4 py-2 hover:bg-gray-700 `}
                            onClick={() => handleClickRoundActive(val)}
                            disabled={val !== data?.interview_round}
                        >
                            {val}
                        </button>
                    ))}
                </div>
                <div className="student_campus_table_div">
                    {roundActive &&
                        (<table className="student_campus_styled-table ">
                            <thead>
                                <tr className="interviewDetails-table-header bg-gray-100">
                                    <th className="interviewDetails-table-cell py-2 px-4 text-left"> Job Title </th>
                                    <th className="interviewDetails-table-cell py-2 px-4 text-left"> Interview Date </th>
                                    <th className="interviewDetails-table-cell py-2 px-4 text-left"> Interview Time </th>
                                    <th className="interviewDetails-table-cell py-2 px-4 text-left"> Interview Mode </th>
                                    {data?.interview_location ? (<th className="interviewDetails-table-cell py-2 px-4 text-left"> Interview Location </th>) : null}
                                    {data?.zoom_meeting_link ? (<th className="interviewDetails-table-cell py-2 px-4 text-left"> Meeting Details</th>) : null}
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    className={`interviewDetails-table-row ${data?.round1 ? "bg-gray-100 hover:bg-gray-200" : "bg-white hover:bg-gray-200"}`}
                                >
                                    <td className="interviewDetails-table-cell py-2 px-4"> {data?.job_details?.title || 'Not Mentioned'} </td>
                                    <td className="interviewDetails-table-cell py-2 px-4"> {date || 'Not Mentioned'} </td>
                                    <td className="interviewDetails-table-cell py-2 px-4"> {time || 'Not Mentioned'} </td>
                                    <td className="interviewDetails-table-cell py-2 px-4"> {data?.zoom_meeting_link ? 'Virtual' : 'Physical' || 'Not Mentioned'} </td>
                                    {data?.interview_location ? (<td className="interviewDetails-table-cell py-2 px-4"> {data?.interview_location || 'Not Mentioned'} </td>) : null}
                                    {data?.zoom_meeting_link ? (<td className="interviewDetails-table-cell py-2 px-4">Click into the  button to join the Zoom Meeting <span><div className="student_interview_details_join_now">
                                        <a href={data?.zoom_meeting_link} target='..'><button> Join Now </button></a>
                                    </div></span> </td>) : null}
                                </tr>
                            </tbody>
                        </table>)
                    }
                </div>
            </div>
        </div>
    )
}

export default InterviewDetails;