import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PlacementTrendGraph = () => {
    // Define the series data
    const seriesData = {
        monthDataSeries1: {
            prices: [0, 100, 80, 250, 120, 200, 350, 300, 290, 320, 280, 400],
            dates: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
    };

    const [chartData] = useState({
        series: [{
            name: "STOCK ABC",
            data: seriesData.monthDataSeries1.prices
        }],
        options: {
            chart: {
                type: 'area',
                height: 350,
                width: '100%', // Make the chart take full width
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false // Disables the menu button
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight',
                width: 2 // Set the line thickness
            },
            colors: ['#FFA500'], // Set the line color to orange
            xaxis: {
                type: 'category', // Use 'category' for custom labels
                categories: ['Jan', '', 'Mar', '', 'May', '', 'Jul', '', 'Sep', '', 'Nov', 'Dec'], // Alternate months for x-axis
                tickPlacement: 'on', // Ensure ticks align with categories
                labels: {
                    style: {
                        fontSize: '12px' // Customize label size
                    }
                }
            },
            yaxis: {
                opposite: true,
                tickAmount: 8 // Ensures 8 intervals (0 to 800 at 100-step intervals)
            },
            legend: {
                horizontalAlign: 'left'
            },
            grid: {
                padding: {
                    left: 10,
                    right: 10
                }
            }
        }
    });

    return (
        <div style={{ width: '100%' }}>
            <div id="chart">
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="area"
                    height={350}
                    width="100%" // Full-width rendering
                />
            </div>
            <div className='campus_placement_graph'>
                <div className='campus_placement_graph__title'>
                    <b>54</b>
                    <p>Offers This <span>Week</span></p>
                </div>
                <hr />
                <div className='campus_placement_graph__title'>
                    <b>6</b>
                    <p>Companies This <span>Week</span></p>
                </div>
            </div>
        </div>
    );
};

export default PlacementTrendGraph;
