import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../../../styles/dashboards/hrdashboard/collegedetails.css";

const CollegeDetails = () => {
  const { collegeId } = useParams();
  const [collegeDetails, setCollegeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const base_Url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchCollegeDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${base_Url}/api/details_college/${collegeId}/`
        );
        console.log(response.data);

        setCollegeDetails(response.data);
        setError(null);
      } catch (err) {
        setError("Failed to fetch college details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCollegeDetails();
  }, [collegeId, base_Url]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="college_details_perant_con">
          <h6 className="page-title">
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; Details
          </h6>
          <div className="college-details-container">
            <div className="college-logo-container">
              <img
                src={collegeDetails?.logo || "https://via.placeholder.com/100"}
                alt={collegeDetails?.name}
                className="college-logo"
              />
            </div>
            <div className="college-info">
              <div className="info-item">
                <label>College Name</label>
                <p>
                  {collegeDetails?.collegename?.length > 40
                    ? `${collegeDetails?.collegename.substring(0, 40)}...`
                    : collegeDetails?.collegename}
                </p>
              </div>
              <div className="info-item">
                <label>University Name</label>
                <p>
                  {collegeDetails?.college_api.university_name?.length > 35
                    ? `${collegeDetails?.college_api.university_name.substring(
                        0,
                        35
                      )}...`
                    : collegeDetails?.college_api.university_name}
                </p>
              </div>
              <div className="info-item">
                <label>Location:</label>
                <p>
                  {collegeDetails?.college_api.district_name}{" "}
                  {collegeDetails.college_api.state_name}
                </p>
              </div>
              <div className="info-item">
                <label>College ID</label>
                <p>{collegeDetails.college_api.college_id}</p>
              </div>
              <div className="info-item">
                <label>University ID</label>
                <p>{collegeDetails.college_api.university_id}</p>
              </div>
            </div>
          </div>
          <div className="college-details-table">
            <table>
              <thead className="college-details-table_head">
                <tr>
                  <th>College Type</th>
                  <th>Placement Manager</th>
                  <th>Student Count</th>
                  <th>Degree Offered</th>
                  <th>Placement On</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{collegeDetails?.type || "Not Mention"}</td>
                  <td>
                    {collegeDetails.name} {collegeDetails.college_lastname}
                  </td>
                  <td>1200</td>
                  <td>MBA/M.Tech</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="college-about">
            <h2>About</h2>
            <p>{collegeDetails?.about}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeDetails;
